import LogMessageContainer from "components/SidePanel/Log/LogMessageContainer";
import React from "react";
import TagContainer from "components/SidePanel/Tags/TagContainer";
import {ServerCogIcon, ServerIcon, TagIcon} from "lucide-react";
import {Log} from "pages/Logs";

function LogPanelContent(props: {
    log: Log
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
}) {
    const serviceObj = {"service.name": new Map(Object.entries(props.log.resourceAttributes)).get("service.name")};
    return <div data-vaul-no-drag
                className="h-full p-4 bg-backgrounddark shadow flex-col justify-start items-center gap-8 inline-flex">
        <div className="flex-col justify-start items-start gap-4 flex">
            <LogMessageContainer
                logLevel={props.log.severity} logMessage={props.log.message}
                datetime={props.log.time}
                regexes={props.regexes}
                setRegexes={props.setRegexes}
                excludeRegexes={props.excludeRegexes}
                setExcludeRegexes={props.setExcludeRegexes}
            />
        </div>
        <div className="flex-col justify-start items-start gap-8 flex">
            <TagContainer
                title={"Service Tags"}
                attributes={serviceObj}
                icon={<ServerCogIcon className={"text-textdark"}/>}
                filter={props.filter}
                setFilter={props.setFilter}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}
            />
            <TagContainer
                title={"Infrastructure Tags"}
                attributes={props.log.resourceAttributes}
                icon={<ServerIcon className={"text-textdark"}/>}
                filter={props.filter}
                setFilter={props.setFilter}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}
            />
            <TagContainer
                title={"Custom Tags"}
                attributes={props.log.logAttributes}
                icon={<TagIcon className={"text-textdark"}/>}
                filter={props.filter}
                setFilter={props.setFilter}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}
            />
        </div>
    </div>
}

export default LogPanelContent;
