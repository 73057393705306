import React, {useEffect} from 'react'
import {GridStack} from "gridstack";
import {Button} from "../ui/button";
import {GroupWidget, Widget} from "./internalwidgets";
import {recursiveSave} from "./utils";
import {Group} from "./widgets/Group";
import {URLSearchParamsInit, useSearchParams} from "react-router-dom";
import axios from "../../utility/customAxios";
import {v4 as uuidv4} from 'uuid';
import {MdModeEdit, MdOutlineSave} from "react-icons/md";
import {useDebouncedCallback} from "use-debounce";
import {NavigateOptions} from "react-router/dist/lib/context";


interface DashboardProps {
    setDashboardTitle?: (title: string) => void
    dashboardTitle?: string
}

function updateDashboard(searchParams: URLSearchParams, setSearchParams: (nextInit?: (URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit)), navigateOpts?: NavigateOptions) => void, setInitialWidget: (value: (((prevState: (GroupWidget | undefined)) => (GroupWidget | undefined)) | GroupWidget | undefined)) => void) {
    if (!searchParams.has("dashboardId")) {
        setSearchParams((prev) => {
            let urlSearchParams = new URLSearchParams(window.location.search);
            urlSearchParams.set("dashboardId", uuidv4())
            return urlSearchParams
        })
    }
    if (searchParams.get("dashboardId") !== "new") {
        axios.get(`/api/v1/dashboard?dashboardId=${searchParams.get("dashboardId")}`).then((response) => {
            const data = JSON.parse(response.data.dashboardJson, reviver) as GroupWidget
            setInitialWidget(data)
        }).catch((e) => {
            setInitialWidget({
                widgetType: "Group",
                title: "New Dashboard",
                position: {
                    "x": 0,
                    "y": 0,
                    "w": 12,
                    "h": 12
                },
                children: []
            } as GroupWidget)
        })
    }
}

function Dashboard(props: DashboardProps) {
    const [grids, setGrids] = React.useState<Map<string, GridStack>>(new Map<string, GridStack>())
    const [widgets, setWidgets] = React.useState<Map<string, Widget>>(new Map<string, Widget>())
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialWidget, setInitialWidget] = React.useState<GroupWidget>()
    const [editable, setEditable] = React.useState<boolean>(false)
    const debouncedUpdateDasboard = useDebouncedCallback(updateDashboard, 10)

    // When the dashboard editing mode changes, update the gridstacks so that they can be edited / resized / moved
    useEffect(() => {
        grids.forEach((grid) => {
            grid.setStatic(!editable)
        })
    }, [editable])


    useEffect(() => {
        if (props.setDashboardTitle) {
            let title = (widgets.get("root") as GroupWidget)?.title
            if (title) {
                props.setDashboardTitle(title)
            }
        }
    }, [widgets])


    // Pull the configuration from the backend
    useEffect(() => {
        debouncedUpdateDasboard(searchParams, setSearchParams, setInitialWidget);
    }, [searchParams])

    if (!initialWidget) {
        return <div>Loading...</div>
    }

    let saveButton = <div
            className={"flex flex-col justify-center items-center text-center grow shrink"}>
            <Button
                className={"h-6 px-2 bg-primarytransparent rounded border-primary border text-textmedium"}
                onClick={() => {
                    let widget = recursiveSave("root", widgets, grids);
                    let dashboardJson = JSON.stringify(widget, replacer);
                    axios.post(`/api/v1/dashboard`, {
                        name: props.dashboardTitle,
                        id: searchParams.get("dashboardId"),
                        dashboardJson: dashboardJson,
                    }).then(() => {
                        setEditable(false)
                    }).catch((e) => {
                        console.error(e)
                    })
                }}>
                <div className={"flex gap-2"}>
                    <div className={"flex flex-col justify-center"}>
                        <MdOutlineSave className={"text-textmedium"}/>
                    </div>
                    <div>Save</div>
                </div>
            </Button>
        </div>
    ;


    let editButton =
        <div className={"flex flex-col justify-center items-center text-center grow shrink"}>
            <Button
                className={"h-6 px-2 bg-primarytransparent rounded border-primary border text-textmedium"}
                onClick={() => {
                    setEditable(!editable)
                }}>
                <div className={"flex gap-2"}>
                    <div className={"flex flex-col justify-center"}>
                        <MdModeEdit className={"text-textmedium"}/>
                    </div>
                    <div>Edit</div>
                </div>
            </Button>
        </div>;

    return <div className={"flex flex-col grow overflow-y-auto"}>
        <Group
            editable={editable}
            editButton={editButton}
            saveButton={saveButton}
            className={"w-full h-full"}
            id={"root"} widget={initialWidget}
            grids={grids} setGrids={setGrids} widgets={widgets} setWidgets={setWidgets}/>
    </div>
}

// @ts-ignore
function replacer(key, value) {
    if(value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    } else {
        return value;
    }
}

// @ts-ignore
function reviver(key, value) {
    if(typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}


export {Dashboard};
export type {GroupWidget};
