import {GroupWidget, Widget} from "./internalwidgets";
import {GridStack, GridStackOptions} from "gridstack";
import React from "react";

function recursiveSave(rootId: string, widgets: Map<string, Widget>, grids: Map<string, GridStack>): Widget {
    let groupWidget = widgets.get(rootId)
    if (groupWidget === undefined) {
        throw new Error("Widget not found")
    }

    if (groupWidget.widgetType !== 'Group') {
        throw new Error("Widget is not a group")
    }

    let castedGroupWidget = groupWidget as GroupWidget

    let newGroupWidget = {
        widgetType: 'Group',
        title: castedGroupWidget.title,
        // Will be overwritten by the parent
        position: castedGroupWidget.position,
        children: []
    } as GroupWidget

    let grid = grids.get("grid-" + rootId)
    if (grid === undefined) {
        throw new Error("Grid not found")
    }

    let saveState = grid.save(true, true, (x, y) => {
        console.log("Saved")
    }) as GridStackOptions;

    let children = saveState.children!

    for (let child of children) {
        if (child.id === undefined) {
            throw new Error("Child id is undefined")
        }
        let childWidget = widgets.get(child.id)
        if (childWidget === undefined) {
            continue
        }
        // Overwrite the position with the saved position
        childWidget.position = {
            x: child.x ?? 0,
            y: child.y ?? 0,
            w: child.w ?? 1,
            h: child.h ?? 1,
        }

        if (childWidget.widgetType === 'Group') {
            let childGroupWidget = recursiveSave(child.id, widgets, grids)
            childGroupWidget.position = {
                x: child.x ?? 0,
                y: child.y ?? 0,
                w: child.w ?? 1,
                h: child.h ?? 1,
            }
            newGroupWidget.children.push(childGroupWidget)
        } else {
            newGroupWidget.children.push(childWidget)
        }
    }
    return newGroupWidget
}


function removeWidget(
    widgetId: string,
    grids: Map<string, GridStack>,
    setGrids: React.Dispatch<React.SetStateAction<Map<string, GridStack>>>,
    widgets: Map<string, Widget>,
    setWidgets: React.Dispatch<React.SetStateAction<Map<string, Widget>>>,
) {
    setWidgets((prev) => {
        let newWidgets = new Map<string, Widget>(prev)
        newWidgets.delete(widgetId)
        return newWidgets
    })

    grids.forEach((value, key) => {
        value.removeWidget("#" + widgetId, true);
        value.removeWidget("." + widgetId, true);
        value.removeWidget(widgetId, true)
    })

    let gridId = "grid-" + widgetId
    if (grids.has(gridId)) {
        setGrids((prev) => {
            let newGrids = new Map<string, GridStack>(prev)
            prev.get(gridId)?.destroy(true)
            newGrids.delete(gridId)
            return newGrids
        })
    }

    document.getElementsByClassName(widgetId)[0]?.remove()
    let querySelector = document.querySelector("div[gs-id=" + widgetId + "]");
    querySelector?.remove()
    let idSelector = document.getElementById(widgetId);
    idSelector?.remove()
}

function addWidget(widget: Widget, setChildren: React.Dispatch<React.SetStateAction<Widget[]>>) {
    setChildren((prev) => {
        let newChildren = [...prev]
        newChildren.push(widget)
        return newChildren
    })
}

export {removeWidget, addWidget, recursiveSave}