import {ApiServerAlert} from "../AlertCreation";

const timeAggregations = ["P50", "P90", "P95", "P99"]
const nanoToMilliSeconds = 1_000_000
function checkIsTime(alert: ApiServerAlert) {
    if (alert && alert?.type == "Trace") {
        const aggregation = alert?.traceAlert.filters.aggregation
        return timeAggregations.includes(aggregation)
    }
    return false
}

function adjustThreshold(alert: ApiServerAlert) {
    if (checkIsTime(alert)) {
        return String(Number(alert?.traceAlert.alarmCondition.threshold) / nanoToMilliSeconds)
    } else {
        if (alert && alert.traceAlert) {
            return alert.traceAlert.alarmCondition.threshold
        }
    }
    return "0"
}


export {checkIsTime, adjustThreshold}