export function extractAPIPath(httpUrl: string): string {
    if (httpUrl === "" || httpUrl === undefined) {
        return "n/a"
    } else if  (httpUrl.trim() === "") {
        return "n/a"
    }
    try {
        const url = new URL(httpUrl);
        return url.pathname;
    } catch (error) {
        return httpUrl;
    }
}

export function fillMissingEntry(entry: string) {
    if (entry === "" || entry === undefined) {
        return "N/A"
    }
    return entry
}

export function getMessageForStatusCode(statusCode: string) {
    if (statusCode === "200") {
        return "Success"
    } else if (statusCode === "400") {
        return "Bad Request"
    } else if (statusCode === "401") {
        return "Unauthorized"
    } else if (statusCode === "403") {
        return "Forbidden"
    } else if (statusCode === "404") {
        return "Not Found"
    } else if (statusCode === "500") {
        return "Internal Server Error"
    } else if (statusCode === "502") {
        return "Bad Gateway"
    } else if (statusCode === "503") {
        return "Service Unavailable"
    } else if (statusCode === "504") {
        return "Gateway Timeout"
    } else if (statusCode === "429") {
        return "Too Many Requests"
    } else if (statusCode === "408") {
        return "Request Timeout"
    } else if (statusCode === "409") {
        return "Conflict"
    } else if (statusCode === "413") {
        return "Payload Too Large"
    } else if (statusCode === "415") {
        return "Unsupported Media Type"
    } else if (statusCode === "308") {
        return "Permanent Redirect"
    } else {
        return "StatusCode: " + statusCode
    }
}