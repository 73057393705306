import React from "react";
import {BaseView} from "./BaseView";
import {Dashboard} from "../components/Dashboarding/Dashboard";


const DashboardView = () => {
    const [dashboardTitle, setDashboardTitle] = React.useState<string>("Dashboard");

    return (
        <BaseView title={dashboardTitle} disableClusterSelector={true}>
            <div className={"flex grow shrink overflow-y-auto"}>
                <Dashboard dashboardTitle={dashboardTitle} setDashboardTitle={setDashboardTitle}/>
            </div>
        </BaseView>
    )
}

export default DashboardView;