import React, {ReactElement, useEffect, useState} from "react";
import {Maximize2Icon, SearchIcon, XIcon} from "lucide-react";
import {format} from 'date-fns'
import {cn, useDebounce, usePreserveQueryParamsNavigate} from "components/ui/lib/utils";
import axios from "utility/customAxios";
import {plainToClassFromExist, plainToInstance} from "class-transformer";
import {FilterPanel, FilterSelector} from "components/Filter/Filter";
import {InputPill, Pill} from "components/Filter/Pill";
import {Attribute} from "types/telemetry";
import {Badge} from "components/ui/badge"
import {shortEnglishHumanizer} from "components/ServicePanel/ServicePanel";
import {extractAPIPath, fillMissingEntry} from "components/SidePanel/Trace/utils";
import {Drawer} from "vaul";
import TracePanelContent from "../components/SidePanel/Trace/TraceDetails";
import {BaseView} from "./BaseView";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {AxiosPromise} from "axios";
import {ChartType, GetAllTraceMetrics, GetAllTraceMetricsResponse, MetoroChart, MetricToChartData} from "./MetricsTest";
import {capitalize} from "@mui/material";
import {LoadingSpinner} from "../components/ui/customSpinner";
import {useSearchParams} from "react-router-dom";
import {TimeRange} from "../types/time";
import {useDebouncedCallback} from "use-debounce";

export interface Trace {
    traceId: string,
    // The time that the trace was emitted in milliseconds since the epoch
    time: number,
    // The attributes of the trace
    resourceAttributes: ResourceAttributes
    // The attributes of the spans associated with this trace
    spanAttributes: SpanAttributes
    // Service name
    serviceName: string
    displayServiceName: string
    clientName: string
    displayClientName: string
    // Latency of the request in microseconds
    duration: number
    spanId: string
    parentSpanId: string
}

export interface SpanAttributes {
    "http.method": string;
    "http.status_code": string;
    "http.url": string;
    "net.peer.name": string;
    "net.peer.port": string;
    "otel.scope.name": string;
    "otel.scope.version": string;
    "server.service.name": string;
}

export interface ResourceAttributes {
    "container.id": string;
    "host.id": string;
    "host.name": string;
    "server.service.name": string;
}

interface Traces {
    traces: Trace[]
    filterAttributes: Map<string, Attribute[]>
}


const customLogColours = new Map<Record<string, string>, string[]>(
    [[
        {key: "log_level", value: "error",},
        ["rgb(255, 99, 132)", "rgba(255, 99, 132, 0.2)"]
    ],
        [
            {key: "log_level", value: "warning",},
            ["rgb(255, 159, 64)", "rgba(255, 159, 64, 0.2)"]
        ],
        [
            {key: "log_level", value: "info",},
            ["rgb(54, 162, 235)", "rgba(54, 162, 235, 0.2)"]
        ],
        [
            {key: "log_level", value: "unknown",},
            ["rgb(107, 114, 128)", "rgba(201, 203, 207, 0.2)"]
        ],

    ])

function TimelineViewer(props: { traceMetrics?: GetAllTraceMetricsResponse }) {
    let metric = undefined;
    if (props.traceMetrics) {
        metric = props.traceMetrics?.metrics.find((metric) => metric.name === "NumRequests");
    }

    return <div className={"flex-none w-full h-[180px] bg-backgroundmedium mb-4"}>
        <MetoroChart className={"h-full"} title={"Number of traces"} type={ChartType.Bar}
                     dataToUse={MetricToChartData(metric, false)}/>
    </div>
}

export function getIndicatorColorForStatusCode(statusCode: string): string {
    let indicatorColor = "bg-gray-500";
    if (statusCode.startsWith("2")) {
        indicatorColor = "bg-secondaryTransparent ";
    }
    if (statusCode.startsWith("3")) {
        indicatorColor = "bg-blue-500";
    }
    if (statusCode.startsWith("4")) {
        indicatorColor = "bg-orange-500";
    }
    if (statusCode.startsWith("5")) {
        indicatorColor = "bg-red-500";
    }
    return indicatorColor;
}

export function TraceLevelFilters(props: {
    filter: Map<string, string[]>,
    attributes: Map<string, Attribute[]>,
    setFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
}) {
    const statusCodes = props.attributes.get("http.status_code");
    if (!statusCodes) {
        return null;
    }

    const statusCodeFilters: ReactElement[] = [];
    for (const statusCode of statusCodes) {
        const levelName = statusCode.value;
        const volume = statusCode.volume;
        statusCodeFilters.push(<FilterSelector filterFilter={""}
                                               indicatorColor={getIndicatorColorForStatusCode(statusCode.value)}
                                               key={levelName} filterKey={"http.status_code"}
                                               filterValue={levelName}
                                               volume={volume} filter={props.filter} setFilter={props.setFilter}
                                               excludeFilter={props.excludeFilter}
                                               setExcludeFilter={props.setExcludeFilter}
        />)
    }

    return <div className={"py-2"}>
        {statusCodeFilters}
    </div>
}

export function TracesSearch(props: {
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
}) {
    let pills: ReactElement[] = [];
    const entries = Array.from(props.filter.entries());
    for (let i = 0; i < entries.length; i++) {
        const [key, values] = entries[i]
        if (values.length === 0) {
            continue;
        }
        const valuesString = values.join(" || ")
        pills.push(<Pill key={i.toString()} attributeKey={key} attributeValue={valuesString} filter={props.filter}
                         setFilter={props.setFilter}
                         excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                         isEditable={true}
        />)
    }

    const excludeEntries = Array.from(props.excludeFilter.entries());
    for (let i = 0; i < excludeEntries.length; i++) {
        const [key, values] = excludeEntries[i]
        if (values.length === 0) {
            continue;
        }
        const valuesString = values.join(" || ")
        pills.push(<Pill key={i.toString()} attributeKey={key} attributeValue={valuesString} filter={props.filter}
                         setFilter={props.setFilter}
                         excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                         isExclude={true}
                         isEditable={true}
        />)
    }


    // Add exclude regex pills
    for (let i = 0; i < props.excludeRegexes.length; i++) {
        const regex = props.excludeRegexes[i];
        pills.push(<Pill key={i.toString()} attributeKey={"regex"} attributeValue={regex} filter={props.filter}
                         setFilter={props.setFilter}
                         excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                         isEditable={true}
                         isRegex={true}
                         isExclude={true}
                         regexes={props.regexes} setRegexes={props.setRegexes}
                         excludeRegexes={props.excludeRegexes} setExcludeRegexes={props.setExcludeRegexes}
        />)
    }

    // Add regex pills
    for (let i = 0; i < props.regexes.length; i++) {
        const regex = props.regexes[i];
        pills.push(<Pill key={i.toString()} attributeKey={"regex"} attributeValue={regex} filter={props.filter}
                         setFilter={props.setFilter}
                         excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                         isEditable={true}
                         isRegex={true}
                         regexes={props.regexes} setRegexes={props.setRegexes}
                         excludeRegexes={props.excludeRegexes} setExcludeRegexes={props.setExcludeRegexes}
        />)
    }

    return <div
        className={"bg-backgroundmedium flex-none w-full hover:cursor-pointer hover:border-primary rounded border items-center gap-x-2 flex"}
    >

        <div className="w-6 h-6 pl-2 relative hover:cursor-pointer hover:text-primary text-border">
            <SearchIcon/>
        </div>
        <div
            className="grow shrink px-[7px] py-[9px] justify-start gap-2 flex flex-wrap">
            {
                pills
            }
            <div className={"flex grow shrink"}>
                <InputPill
                    placeholderText={"Search endpoint..."}
                    filter={props.filter} setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                    regexes={props.regexes} setRegexes={props.setRegexes}
                />
            </div>
        </div>
        <div onClick={
            () => {
                const input = document.getElementById("free_text_input") as HTMLInputElement;
                if (input) {
                    input.value = "";
                }
                props.setFilter(new Map());
                props.setExcludeFilter(new Map());
                props.setRegexes([]);
                props.setExcludeRegexes([]);
            }
        } className="w-6 h-6 right-2 absolute  text-textmedium hover:bg-primary rounded hover:cursor-pointer">
            <XIcon/>
        </div>
    </div>

}

function getBreakpointAttributes(isFilterShown: boolean, componentName: string) {
    if (isFilterShown) {
        switch (componentName) {
            case "clientName":
                return " max-[1325px]:hidden"
            case "method":
                return " max-[1124px]:hidden"
            case "statusCode":
                return " max-[1460px]:hidden"
            case "endpoint":
                return " max-[1073px]:flex-none truncate max-[1073px]:w-[144px]"
            case "endpointTitle":
                return " max-[1073px]:flex-none truncate max-[1073px]:w-[144px]"
        }
    } else {
        switch (componentName) {
            case "clientName":
                return " max-[1000px]:hidden"
            case "method":
                return " max-[1124px]:hidden"
            case "statusCode":
                return " max-[1460px]:hidden"
            case "endpoint":
                return " max-[790px]:flex-none max-[790px]:w-[144px]"
            case "endpointTitle":
                return " max-[790px]:flex-none max-[790px]:w-[144px]"
        }
    }
}

function TraceView(props: {
    removeService?: boolean,
    regex: string,
    trace: Trace,
    sidePanelSize?: string
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    isFilterShown: boolean
}) {
    let indicatorColor = "bg-gray-500";
    if (props.trace.spanAttributes["http.status_code"] !== undefined) {
        indicatorColor = getIndicatorColorForStatusCode(props.trace.spanAttributes["http.status_code"])
    }
    let sidePanelSize = "w-2/3";
    if (props.sidePanelSize !== undefined) {
        sidePanelSize = props.sidePanelSize;
    }
    let clientSplit = props.trace.clientName.split("/");
    let clientName = capitalize(clientSplit[clientSplit.length - 1])

    return <Drawer.Root direction="right">
        <Drawer.Trigger asChild>
            <div
                className="w-full max-w-full h-8 px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip">
                <div
                    className="w-full h-8 px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip hover:cursor-pointer">
                    <div className="flex-none h-8 w-[152px] flex justify-start items-center space-x-2">
                        <div className={cn(indicatorColor, " flex-none w-[4px] h-6 left-0 top-[4px] rounded")}/>
                        <div
                            className="flex items-center h-[24px] w-full top-0 text-textmedium text-sm font-medium  leading-[16px] truncate">
                            {
                                format(new Date(props.trace.time), "dd/MM HH:mm:ss.SSS")
                            }
                        </div>
                    </div>
                    <div
                        className={"flex-none flex items-center self-center w-[200px] h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate" + getBreakpointAttributes(props.isFilterShown, "clientName")}>
                        {
                            clientName
                        }
                    </div>
                    {
                        !props.removeService && <div
                            className="flex-none flex items-center w-[200px] h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate">
                            {
                                props.trace.displayServiceName
                            }
                        </div>
                    }
                    <div
                        className={"flex-none flex items-center w-[72px] h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] max-[1124px]:hidden truncate" + getBreakpointAttributes(props.isFilterShown, "method")}>
                        {
                            fillMissingEntry(props.trace.spanAttributes["http.method"])
                        }
                    </div>
                    <div
                        className={"flex-none flex items-center w-[72px] h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] max-[1460px]:hidden truncate" + getBreakpointAttributes(props.isFilterShown, "statusCode")}>
                        {<Badge
                            className={"rounded " + indicatorColor}>{fillMissingEntry(props.trace.spanAttributes["http.status_code"])}</Badge>}
                    </div>
                    <div
                        className={"flex grow shrink items-center h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate " + getBreakpointAttributes(props.isFilterShown, "endpoint")}>
                        {
                            extractAPIPath(props.trace.spanAttributes["http.url"])
                        }
                    </div>
                    <div
                        className="flex-none items-center w-[100px] h-[24px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate">
                        {
                            shortEnglishHumanizer(props.trace.duration / 1_000_000)
                        }
                    </div>
                </div>
            </div>
        </Drawer.Trigger>
        <Drawer.Portal>
            <Drawer.Content
                className={`select-text flex flex-col rounded-t-[10px] h-full ${sidePanelSize} mt-12 fixed bottom-0 right-0`}>
                <TracePanelContent
                    filter={props.filter} setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                    trace={props.trace}/>
            </Drawer.Content>
        </Drawer.Portal>
    </Drawer.Root>
}

function TraceFeed(props: {
    ascending: boolean,
    setAscending: (ascended: boolean) => void
    removeService?: boolean,
    regex: string,
    traces: Trace[],
    scrolledToBottomHandler: () => void
    sidePanelSize?: string
    isLoadingTraces?: boolean
    filter: Map<string, string[]>
    setFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    showExpand?: boolean
    isFilterShown: boolean
}) {
    const navigate = usePreserveQueryParamsNavigate();
    const handleScroll = (e: any) => {
        const bottom = Math.abs(e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)) <= 1
        if (bottom) {
            props.scrolledToBottomHandler();
        }
    }

    return <div
        className="max-w-full max-h-max grow mt-4 min-w-0 bg-backgroundmedium border rounded min-h-0 w-full flex flex-col">
        <div className={"flex justify-end"}>
            <div
                className="w-full h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 inline-flex truncate">
                <div
                    className={`flex-none w-[152px] h-full flex justify-between font-normal leading-8 text-textmedium text-xl`}>
                    <div>Time</div>
                    <div onClick={() => {
                        if (props.setAscending) {
                            props.setAscending(!props.ascending)
                        }
                    }} className="hover:cursor-pointer text-sm flex flex-col justify-center pr-4">
                        {props.ascending ? "▲" : "▼"}
                    </div>
                </div>
                <div
                    className={"w-[200px] flex-none font-normal leading-8 text-textmedium text-xl " + getBreakpointAttributes(props.isFilterShown, "clientName")}>
                    Client
                </div>
                {!props.removeService &&
                    <div
                        className="w-[200px] flex-none font-normal leading-8 text-textmedium text-xl ">Service
                    </div>}
                <div
                    className={"w-[72px] flex-none font-normal leading-8 text-textmedium text-xl " + getBreakpointAttributes(props.isFilterShown, "method")}>Method
                </div>
                <div
                    className={"w-[72px] flex-none font-normal leading-8 text-textmedium text-xl " + getBreakpointAttributes(props.isFilterShown, "statusCode")}>Status
                </div>
                <div
                    className={"flex grow shrink font-normal leading-8 text-textmedium text-xl " + getBreakpointAttributes(props.isFilterShown, "endpointTitle")}>Endpoint
                </div>
                <div
                    className="w-[100px] flex-none justify-end font-normal leading-8 text-textmedium text-xl ">Latency
                </div>
            </div>
            {props.showExpand &&
                <Maximize2Icon
                    className={"w-4 h-4 text-textdark border-l border-b rounded-bl hover:text-primary hover:cursor-pointer"}
                    onClick={() => {
                        let existing = new URLSearchParams(window.location.search)
                        const currentServiceName = existing.get("service")
                        const filterBy = {"server.service.name": [currentServiceName]}
                        navigate(`/traces?filter=${JSON.stringify(filterBy)}`)
                    }}/>
            }
        </div>
        {
            (props.isLoadingTraces !== undefined && !props.isLoadingTraces) && props.traces.length === 0 &&
            <div className="border-t flex-grow flex items-center justify-center">
                <div className="text-textmedium text-lg">No traces found</div>
            </div>
        }
        <div
            className="max-w-full max-h-full min-w-0 min-h-0 overflow-y-auto shrink scrollMedium border-t rounded-b overflow-x-clip"
            onScroll={handleScroll}>
            {
                props.traces.map((trace, index) => {
                    return <TraceView removeService={props.removeService} regex={props.regex} key={index}
                                      trace={trace}
                                      sidePanelSize={props.sidePanelSize}
                                      filter={props.filter} setFilter={props.setFilter}
                                      excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                                      isFilterShown={props.isFilterShown}
                    />
                })
            }
        </div>
    </div>
}


function TracePanel(props: {
    ascending: boolean,
    setAscending: (ascended: boolean) => void
    traces: Traces,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    scrolledToBottomHandler: (() => void),
    sidePanelSize?: string
    isLoadingTraces?: boolean
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
    isFilterShown: boolean
}) {
    return <div className={"pl-4 min-w-0 min-h-0 grow shrink w-full flex flex-col"}>
        <TracesSearch
            filter={props.filter} setFilter={props.setFilter}
            excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
            regexes={props.regexes} setRegexes={props.setRegexes}
            excludeRegexes={props.excludeRegexes} setExcludeRegexes={props.setExcludeRegexes}
        />
        <TraceFeed
            ascending={props.ascending}
            setAscending={props.setAscending}
            regex={props.regexes !== undefined && props.regexes.length > 0 ? props.regexes[0] : ""}
            traces={props.traces.traces}
            scrolledToBottomHandler={props.scrolledToBottomHandler}
            sidePanelSize={props.sidePanelSize}
            isLoadingTraces={props.isLoadingTraces}
            filter={props.filter}
            setFilter={props.setFilter}
            excludeFilter={props.excludeFilter}
            setExcludeFilter={props.setExcludeFilter}
            isFilterShown={props.isFilterShown}
        />
    </div>
}

function MainTracesView(props: {
    ascending: boolean,
    setAscending: (ascended: boolean) => void
    isLoadingTraces?: boolean,
    isLoadingFilter?: boolean,
    traces: Traces,
    setFilter: (filter: Map<string, string[]>) => void,
    filter: Map<string, string[]>,
    scrolledToBottomHandler: (() => void)
    sidePanelSize?: string
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    regexes: string[]
    setRegexes: (regexes: string[]) => void
    excludeRegexes: string[]
    setExcludeRegexes: (regexes: string[]) => void
}) {
    const [shrinkFilter, setShrinkFilter] = useState<boolean>(false);
    return <div className={"w-full min-w-0 min-h-0 flex justify-between grow shrink"}>
        <div className={"flex flex-none relative"}>
            {!shrinkFilter && props.isLoadingFilter && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <FilterPanel
                initiallyOpenFilterKeys={["server.service.name"]}
                attributes={props.traces.filterAttributes} setFilter={props.setFilter}
                filter={props.filter}
                telemetryFiltersComponent={
                    <TraceLevelFilters filter={props.filter} setFilter={props.setFilter}
                                       attributes={props.traces.filterAttributes}
                                       excludeFilter={props.excludeFilter}
                                       setExcludeFilter={props.setExcludeFilter}
                    />
                }
                filteringCriteria={"http.status_code"}
                setExcludeFilter={props.setExcludeFilter}
                excludeFilter={props.excludeFilter}
                setFilterShrank={setShrinkFilter}
            />
        </div>
        <div className={"flex grow shrink min-w-0 min-h-0 relative"}>
            {props.isLoadingTraces && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
            <TracePanel
                ascending={props.ascending}
                setAscending={props.setAscending}
                filter={props.filter} setFilter={props.setFilter}
                traces={props.traces}
                scrolledToBottomHandler={props.scrolledToBottomHandler}
                sidePanelSize={props.sidePanelSize}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}
                regexes={props.regexes}
                setRegexes={props.setRegexes}
                excludeRegexes={props.excludeRegexes}
                setExcludeRegexes={props.setExcludeRegexes}
                isFilterShown={!shrinkFilter}
            />
        </div>

    </div>
}

class TracesResponse {
    constructor(traces: Trace[]
    ) {
        this.traces = traces;
    }

    traces: Trace[]
}


export interface TracesSummaryResponse {
    attributes: Map<string, Attribute[]>
}

interface TracesProps {
    justTraceFeed?: boolean
    service?: string
    filter?: Map<string, string[]>
    removeService?: boolean
    sidePanelSize?: string
}

function getFiltersFromSearch(searchParams: URLSearchParams): Map<string, string[]> {
    const filterJson = searchParams.get("filter") || "";
    if (filterJson !== "") {
        const filterMap = new Map<string, string[]>();
        const filterObject = JSON.parse(filterJson);
        for (const [key, value] of Object.entries(filterObject)) {
            filterMap.set(key, value as string[])
        }
        return filterMap
    }
    return new Map();
}

function getExcludeFiltersFromSearch(searchParams: URLSearchParams): Map<string, string[]> {
    const filterJson = searchParams.get("excludeFilter") || "";
    if (filterJson !== "") {
        const excludeFilterMap = new Map<string, string[]>();
        const excludeFilterObject = JSON.parse(filterJson);
        for (const [key, value] of Object.entries(excludeFilterObject)) {
            excludeFilterMap.set(key, value as string[])
        }
        return excludeFilterMap
    }
    return new Map();
}

async function getTraces(setIsLoadingTraces: (value: (((prevState: boolean) => boolean) | boolean)) => void, debouncedFilter: Map<string, string[]>, timeRange: TimeRange, debouncedExcludeFilter: Map<string, string[]>, service: string | undefined, debouncedRegexes: string[], debouncedExcludeRegexes: string[], prevMaxTime: number | undefined, debouncedAscending: boolean, environments: string[], setTraces: (value: (((prevState: Traces) => Traces) | Traces)) => void) {
    try {
        setIsLoadingTraces(true)
        const filters = Object.fromEntries(debouncedFilter);
        const startEnd = timeRange.getStartEnd();
        let excludeFilters = Object.fromEntries(debouncedExcludeFilter);
        const d: AxiosPromise<TracesResponse> = axios.post("/api/v1/traces", {
                "serviceNames": service ? [service] : [],
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "filters": filters,
                "excludeFilters": excludeFilters,
                "regexes": debouncedRegexes,
                "excludeRegexes": debouncedExcludeRegexes,
                "prevEndTime": prevMaxTime,
                "ascending": debouncedAscending,
                "environments": environments[0] === "" ? [] : environments
            }
        )
        const awaited = (await d).data;
        let awaitedTraces = plainToInstance(TracesResponse, awaited)
        setTraces(prevState => {
            if (prevMaxTime === undefined) {
                return {...prevState, traces: awaitedTraces.traces}
            } else {
                return {...prevState, traces: [...prevState.traces, ...awaitedTraces.traces]}
            }
        })
        setIsLoadingTraces(false)
    } catch (e) {
        console.error(e);
        setIsLoadingTraces(false)
    }
}

async function updateTraceSummary(setIsLoadingFilters: (value: (((prevState: boolean) => boolean) | boolean)) => void, filter: Map<string, string[]>, excludeFilter: Map<string, string[]>, timeRange: TimeRange, regexes: string[], excludeRegexes: string[], environments: string[], setTraces: (value: (((prevState: Traces) => Traces) | Traces)) => void) {
    try {
        setIsLoadingFilters(true)
        const filters = Object.fromEntries(filter)
        const excludeFilters = Object.fromEntries(excludeFilter);
        const startEnd = timeRange.getStartEnd();
        const d: AxiosPromise<TracesSummaryResponse> = axios.post("/api/v1/tracesSummary", {
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "excludeFilters": excludeFilters,
                "filters": filters,
                "regexes": regexes,
                "excludeRegexes": excludeRegexes,
                "environments": environments[0] === "" ? [] : environments

            }
        )
        const awaited = (await d).data.attributes;
        let awaitedMap = new Map<string, Attribute[]>();
        plainToClassFromExist(awaitedMap, awaited)
        setTraces(prevState => {
            return {...prevState, filterAttributes: awaitedMap}
        })
        setIsLoadingFilters(false)
    } catch (e) {
        setIsLoadingFilters(false)
        console.error(e);
    }
}

async function updateTraceMetrics(setIsLoadingTimeline: (value: (((prevState: boolean) => boolean) | boolean)) => void, timeRange: TimeRange, filter: Map<string, string[]>, excludeFilter: Map<string, string[]>, regexes: string[], excludeRegexes: string[], environments: string[], setTraceTimeMetrics: (value: (((prevState: (GetAllTraceMetricsResponse | undefined)) => (GetAllTraceMetricsResponse | undefined)) | GetAllTraceMetricsResponse | undefined)) => void) {
    try {
        setIsLoadingTimeline(true)
        const startEnd = timeRange.getStartEnd();
        await GetAllTraceMetrics({
            filters: filter,
            excludeFilters: excludeFilter,
            startTime: Math.floor(startEnd[0].getTime() / 1000),
            endTime: Math.floor(startEnd[1].getTime() / 1000),
            regexes: regexes,
            excludeRegexes: excludeRegexes,
            onlyNumRequests: true,
            environments: environments[0] === "" ? [] : environments

        }).then((response) => {
            setTraceTimeMetrics(response)
            setIsLoadingTimeline(false)
        })
    } catch (e) {
        console.error(e);
        setIsLoadingTimeline(false)
    }
}

const Traces = (props: TracesProps) => {
    const [service, setService] = useState<string | undefined>(props.service);
    const [traces, setTraces] = useState<Traces>({traces: [], filterAttributes: new Map()});
    const [prevMaxTime, setPrevMaxTime] = useState<number>();
    const [traceTimeMetrics, setTraceTimeMetrics] = useState<GetAllTraceMetricsResponse>();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [isLoadingTraces, setIsLoadingTraces] = useState<boolean>(true);
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
    const [isLoadingTimeline, setIsLoadingTimeline] = useState<boolean>(true);
    const [ascending, setAscending] = useState<boolean>(false);
    const debouncedAscending = useDebounce(ascending, 100);

    const [searchParams, setSearchParams] = useSearchParams()

    const [filter, setFilter] = useState<Map<string, string[]>>(props.filter || getFiltersFromSearch(searchParams));
    const [excludeFilter, setExcludeFilter] = useState<Map<string, string[]>>(getExcludeFiltersFromSearch(searchParams));
    const [environments, setEnvironments] = useState<string[]>([]);
    const [regexes, setRegexes] = useState<string[]>(searchParams.get("regexes") ? JSON.parse(searchParams.get("regexes") as string) : [])
    const [excludeRegexes, setExcludeRegexes] = useState<string[]>(searchParams.get("excludeRegexes") ? JSON.parse(searchParams.get("excludeRegexes") as string) : [])
    const debouncedGetTraces = useDebouncedCallback(getTraces, 10);
    const debouncedUpdateTraceSummary = useDebouncedCallback(updateTraceSummary, 10);
    const debouncedUpdateTraceMetrics = useDebouncedCallback(updateTraceMetrics, 10);

    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null) {
            setEnvironments([environments])
        }
    }, [searchParams])


    useEffect(() => {
        let filterJson = searchParams.get("regexes") || "";
        if (filterJson !== "") {
            setRegexes(JSON.parse(filterJson))
        }
        filterJson = searchParams.get("excludeRegexes") || "";
        if (filterJson !== "") {
            setExcludeRegexes(JSON.parse(filterJson))
        }
    }, [searchParams])

    useEffect(() => {
        setExcludeFilter(getExcludeFiltersFromSearch(searchParams))
        setFilter(getFiltersFromSearch(searchParams))
    }, [searchParams])

    useEffect(() => {
        const ascending = searchParams.get("ascending");
        if (ascending !== null) {
            setAscending(ascending === "true")
        }
    }, [searchParams])

    useEffect(() => {
        if (ascending) {
            setPrevMaxTime(undefined)
        }
    }, [debouncedAscending])

    // Reset prevMaxTime when filter changes so that we can fetch new traces
    useEffect(() => {
        setPrevMaxTime(undefined)
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);


    const scrolledToBottomHandler = () => {
        setPrevMaxTime(traces.traces[traces.traces.length - 1].time + (ascending ? 1 : -1))
    }

    useEffect(() => {
        // Get Traces
        debouncedGetTraces(setIsLoadingTraces, filter, timeRange, excludeFilter, service, regexes, excludeRegexes, prevMaxTime, debouncedAscending, environments, setTraces);
    }, [prevMaxTime, debouncedAscending, filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);

    useEffect(() => {
        if (props.justTraceFeed) {
            // In the case where it's just the trace feed, we don't need the metrics
            return;
        }
        debouncedUpdateTraceSummary(setIsLoadingFilters, filter, excludeFilter, timeRange, regexes, excludeRegexes, environments, setTraces);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);


    useEffect(() => {
        if (props.justTraceFeed) {
            // In the case where it's just the trace feed, we don't need the metrics
            return;
        }
        debouncedUpdateTraceMetrics(setIsLoadingTimeline, timeRange, filter, excludeFilter, regexes, excludeRegexes, environments, setTraceTimeMetrics);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);


    if (props.justTraceFeed) {
        return <TraceFeed
            ascending={ascending}
            setAscending={(ascended: boolean) => {
                setSearchParams(prev => {
                    let existing = new URLSearchParams(window.location.search)
                    existing.set("ascending", ascended.toString())
                    return existing
                })
            }}
            filter={filter} setFilter={setFilter}
            excludeFilter={excludeFilter} setExcludeFilter={setExcludeFilter}
            removeService={props.removeService} regex={""} traces={traces.traces}
            scrolledToBottomHandler={scrolledToBottomHandler} sidePanelSize={props.sidePanelSize}
            isLoadingTraces={isLoadingTraces}
            showExpand={true}
            isFilterShown={true}
        />
    }

    return (
        <BaseView title={"Trace Search"}>
            <div className={"p-4 flex flex-col min-h-0 min-w-0 bg-backgrounddark grow shrink"}>
                <div className={"flex flex-none relative"}>
                    {isLoadingTimeline && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
                    <TimelineViewer traceMetrics={traceTimeMetrics}/>
                </div>
                <MainTracesView
                    ascending={ascending}
                    setAscending={(ascended: boolean) => {
                        setSearchParams(prev => {
                            let prevParams = new URLSearchParams(window.location.search)
                            prevParams.set("ascending", ascended.toString())
                            return prevParams
                        })
                    }}
                    isLoadingTraces={isLoadingTraces} isLoadingFilter={isLoadingFilters}
                    setFilter={(filter: Map<string, string[]>) => setSearchParams(prev => {
                        let existing = new URLSearchParams(window.location.search)
                        existing.set("filter", JSON.stringify(Object.fromEntries(filter)))
                        return existing
                    })}
                    filter={filter}
                    traces={traces}
                    scrolledToBottomHandler={scrolledToBottomHandler}
                    sidePanelSize={props.sidePanelSize}
                    excludeFilter={excludeFilter}
                    setExcludeFilter={(filter: Map<string, string[]>) => setSearchParams(prev => {
                        let existing = new URLSearchParams(window.location.search)
                        existing.set("excludeFilter", JSON.stringify(Object.fromEntries(filter)))
                        return existing
                    })} regexes={regexes}
                    setRegexes={(regexes: string[]) => setSearchParams(prev => {
                        let existing = new URLSearchParams(window.location.search)
                        existing.set("regexes", JSON.stringify(regexes))
                        return existing
                    })}
                    excludeRegexes={excludeRegexes}
                    setExcludeRegexes={(regexes: string[]) => setSearchParams(prev => {
                        let existing = new URLSearchParams(window.location.search)
                        existing.set("excludeRegexes", JSON.stringify(regexes))
                        return existing
                    })}
                />
            </div>
        </BaseView>
    )
}

export default Traces;