import {XIcon} from "lucide-react";
import {cn} from "../ui/lib/utils";
import React, {useEffect, useRef} from "react";
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Command, CommandGroup, CommandItem, CommandList} from "../ui/command";


export function GroupByPill(props: {
    attributeKey: string,
    groupBy: string[],
    setGroupBy: (groupBy: string[]) => void
}) {
    return <div className="group justify-start items-start flex hover:cursor-pointer">
        <div
            className="p-2 bg-backgrounddark rounded-tl rounded-bl border justify-start items-start flex">
            <div
                className="text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]">{props.attributeKey}
            </div>
        </div>
        <div
            onClick={() => {
                const newGroupBy = props.groupBy.filter((value) => value !== props.attributeKey);
                props.setGroupBy(newGroupBy);
            }}
            className="h-8 pt-[3px] bg-backgroundlight hover:bg-primary hidden group-hover:block group-hover:border-t group-hover:border-b group-hover:border-r group-hover:rounded-r text-textmedium">
            <XIcon/>
        </div>
    </div>;
}

export function Pill(props: {
    key?: string,
    attributeKey: string,
    attributeValue: string,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void
    isExclude?: boolean
    excludeFilter?: Map<string, string[]>
    setExcludeFilter?: (filter: Map<string, string[]>) => void
    isEditable?: boolean
    regexes?: string[]
    setRegexes?: (regexes: string[]) => void
    excludeRegexes?: string[]
    setExcludeRegexes?: (regexes: string[]) => void
    isRegex?: boolean
    additionalRemoveAction?: () => void
}) {
    const valueRef = useRef(null);
    useEffect(() => {
        if (valueRef && valueRef.current) {
            // @ts-ignore
            valueRef.current.focus();
        }
    }, [valueRef]);

    const [isOpened, setIsOpened] = React.useState(false);

    return <div className="group justify-start items-start flex hover:cursor-pointer max-w-full">
        <div
            className={cn("bg-backgrounddark rounded-tl rounded-bl justify-start items-start flex border-t border-l border-b")}>
            {
                props.isEditable && !props.isRegex &&
                <div contentEditable={true} key={props.key} id={"key_input"}
                     onKeyDown={(e) => {
                         // if the return key is pressed, blur the input
                         if (e.key === "Enter") {
                             e.preventDefault();
                             e.currentTarget.blur();
                             return
                         }
                     }}
                     onBlur={(e) => {
                         let key = e.currentTarget.textContent!;
                         key = key.replace(/\n/g, "");
                         const newFilter = props.isExclude ? new Map(props.excludeFilter!) : new Map(props.filter);
                         const prevValue = props.filter.get(props.attributeKey);
                         newFilter.delete(props.attributeKey);
                         newFilter.set(key, prevValue || []);
                         if (props.isExclude) {
                             props.setExcludeFilter!(newFilter);
                             return
                         } else {
                             props.setFilter(newFilter);
                             return
                         }
                     }}
                     className={cn("h-[30px] hover:cursor-text p-2 w-max max-w-max flex-none text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]")}>{props.attributeKey}</div>
            }
            {
                (!props.isEditable || props.isRegex) &&
                <div
                    className={cn("p-2 text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]")}>{props.attributeKey}
                </div>
            }
        </div>

        <Popover open={isOpened}>
            <PopoverTrigger>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsOpened(!isOpened)}
                }
                    className={cn("p-2 rounded-tl rounded-bl border justify-start items-start flex text-white", props.isExclude ? "bg-redtransparenter border-red-500" : "bg-secondarytransparenter border-secondary")}>
                    <div
                        className={cn("text-center text-sm font-medium font-['Inter'] leading-[14px] text-textlight")}>{props.isExclude ? "!=" : "="}
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent onInteractOutside={() => {setIsOpened(false)}} className={"bg-backgroundmedium border rounded text-textmedium"}>
                <Command>
                    <CommandList>
                        <CommandGroup>
                            <CommandItem
                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                onSelect={() => {
                                    if (!props.isExclude) {
                                        // Do nothing if the filter already exists
                                        return
                                    }

                                    if (props.isRegex) {
                                        const excludes = props.excludeRegexes!.filter((value) => value !== props.attributeValue);
                                        props.setRegexes!(props.regexes!.concat(props.attributeValue));
                                        props.setExcludeRegexes!(excludes);
                                        return
                                    }

                                    // Remove from the exclude filter if it exists and add to the filter
                                    const newFilter = new Map(props.filter);
                                    const excludeFilter = new Map(props.excludeFilter!);
                                    const value = excludeFilter.get(props.attributeKey);
                                    excludeFilter.delete(props.attributeKey);
                                    newFilter.set(props.attributeKey, value!);
                                    props.setExcludeFilter!(excludeFilter);
                                    props.setFilter(newFilter);
                                }}

                            >=</CommandItem>
                            <CommandItem
                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                onSelect={() => {
                                    if (props.isExclude) {
                                        // Do nothing if the filter already exists
                                        return
                                    }

                                    if (props.isRegex) {
                                        const regexes = props.regexes!.filter((value) => value !== props.attributeValue);
                                        props.setExcludeRegexes!(props.excludeRegexes!.concat(props.attributeValue));
                                        props.setRegexes!(regexes);
                                        return
                                    }

                                    // Remove from the filter if it exists and add to the exclude filter
                                    const newFilter = new Map(props.filter);
                                    const excludeFilter = new Map(props.excludeFilter!);
                                    const value = newFilter.get(props.attributeKey);
                                    newFilter.delete(props.attributeKey);
                                    // If there's already an exclude filter for this key, append the value to the array
                                    const prevExcludeValue = excludeFilter.get(props.attributeKey);
                                    if (prevExcludeValue) {
                                        excludeFilter.set(props.attributeKey, prevExcludeValue.concat(...value!));
                                    } else {
                                        excludeFilter.set(props.attributeKey, value!);
                                    }
                                    props.setFilter(newFilter);
                                    props.setExcludeFilter!(excludeFilter);
                                }}>!=</CommandItem>
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>

        <div
            className={cn("w-max border-t border-b border-r rounded-r group-hover:border-t group-hover:border-b group-hover:border-r-0 group-hover:rounded-r-none justify-center items-center flex max-w-[500px]", " bg-backgroundmedium")}>
            {
                props.isEditable &&
                <div
                    contentEditable={true} key={props.key} id={"key_input"}
                    onKeyDown={(e) => {
                        // if the return or tab key is pressed, blur the input
                        if (e.key === "Enter" || e.key === "Tab") {
                            e.preventDefault();
                            e.currentTarget.blur();
                            return
                        }
                    }}
                    onBlur={(e) => {
                        let value = e.currentTarget.textContent!;
                        value = value.replace(/\n/g, "");

                        if (props.isRegex) {
                            let newRegexes = props.isExclude ? [...props.excludeRegexes!] : [...props.regexes!];
                            // Replace the old value with the new value
                            const index = newRegexes.indexOf(props.attributeValue);
                            newRegexes[index] = value;
                            if (props.isExclude) {
                                props.setExcludeRegexes!(newRegexes);
                                return;
                            } else {
                                props.setRegexes!(newRegexes);
                                return;
                            }
                        }

                        const newFilter = props.isExclude ? new Map(props.excludeFilter!) : new Map(props.filter);
                        const parsedValue = value.split(" || ");
                        newFilter.set(props.attributeKey, parsedValue);
                        if (props.isExclude) {
                            props.setExcludeFilter!(newFilter);
                            return
                        } else {
                            props.setFilter(newFilter);
                            return
                        }
                    }}
                    ref={valueRef}
                    className={cn("h-[30px] hover:cursor-text p-2 w-max flex-none text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px] truncate max-w-full")}>{props.attributeValue}</div>
            }
            {
                !props.isEditable &&
                <div
                    className={cn("p-2 text-left text-textlight text-sm font-medium font-['Inter'] leading-[14px] truncate")}>{props.attributeValue}
                </div>
            }
        </div>
        <div
            onClick={() => {
                if (props.isRegex) {
                    const newRegexes = props.isExclude ? props.excludeRegexes!.filter((value) => value !== props.attributeValue) : props.regexes!.filter((value) => value !== props.attributeValue);
                    if (props.isExclude) {
                        props.setExcludeRegexes!(newRegexes);
                    } else {
                        props.setRegexes!(newRegexes);
                    }
                    return
                }

                if (props.isExclude) {
                    const newFilter = new Map(props.excludeFilter!);
                    newFilter.delete(props.attributeKey);
                    props.setExcludeFilter!(newFilter);
                    return
                } else {
                    const newFilter = new Map(props.filter);
                    newFilter.delete(props.attributeKey);
                    props.setFilter(newFilter);
                }
                if (props.additionalRemoveAction) {
                    props.additionalRemoveAction();
                }
            }}
            className="h-8 pt-[3px] bg-backgroundmedium hover:bg-primary hidden group-hover:block  group-hover:border-t group-hover:border-b group-hover:border-r group-hover:rounded-r text-textlight">
            <XIcon/>
        </div>
    </div>;
}

export function InputPill(props: {
    placeholderText: string,
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter?: Map<string, string[]>,
    setExcludeFilter?: (filter: Map<string, string[]>) => void
    regexes?: string[],
    setRegexes?: (regexes: string[]) => void
}) {
    let value = "";
    return <div
        className={cn(value !== "" ? "group " : "", "grow shrink justify-start items-start flex hover:cursor-pointer")}>
        <div
            className={cn("p-2 bg-backgrounddark rounded-tl rounded-bl border justify-start items-start flex", value === "" ? "hidden" : "")}>
            <div
                className={cn("text-center text-textmedium text-sm font-medium font-['Inter'] leading-[14px]", value === "" ? "hidden" : "")}>regex_search
            </div>
        </div>
        <div
            className={cn("h-8 border-t bg-backgroundlight border-b border-r rounded-r group-hover:border-r-0 group-hover:rounded-r-none self-stretch justify-center items-center flex", value === "" ? "border-none bg-backgoundmedium w-full" : "")}>
            <div className={cn(value === "" ? "w-full" : "")}>
                <div contentEditable={true} id={"free_text_input"}
                     onKeyUp={(e) => {
                         if (e.key === "Enter" || e.key === "Tab") {
                             e.preventDefault();
                             e.currentTarget.blur();
                             return
                         }

                         // If the = key is pressed, create a filter instead of typing the character
                         if (e.key !== "=") {
                             return
                         }

                         // Get the value of the input
                         let s = e.currentTarget.textContent!;
                         // If there is a ! in the last character, create an exclude filter
                         let isExclude = false;
                         if (s.charAt(s.length - 2) === "!") {
                             isExclude = true;
                             s = s.slice(0, -2);
                         } else {
                             s = s.slice(0, -1);
                         }
                         if (isExclude) {
                             const newFilter = new Map(props.excludeFilter!);
                             newFilter.set(s, [""]);
                             props.setExcludeFilter!(newFilter);
                         } else {
                             const newFilter = new Map(props.filter);
                             newFilter.set(s, [""]);
                             props.setFilter(newFilter);
                         }
                         e.currentTarget.textContent = "";
                     }}
                     onBlur={(e) => {
                         if (e.currentTarget.textContent === "") {
                             return
                         }
                         let search = e.currentTarget.textContent!;
                         search = search.replace(/\n/g, "");
                         // Add a regex
                         const newRegexes = props.regexes!.concat(search);
                         props.setRegexes!(newRegexes);
                         e.currentTarget.textContent = "";
                     }}
                     className={cn("h-[30px] flex hover:cursor-text p-2 grow shrink flex-none text-textmedium text-sm font-medium font-['Inter'] leading-[14px]")}></div>
            </div>
        </div>
    </div>;
}