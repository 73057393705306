import React, {useEffect} from "react";

import {K8sPodDetails} from "./model";
import {Drawer} from "vaul";
import {Badge} from "../ui/badge";
import {PodItemSideView} from "./PodItemSideView";
import {FrownIcon, SquareIcon} from "lucide-react";
import yaml from "js-yaml";
import {V1ContainerState, V1Pod} from "@kubernetes/client-node";
import hdate from "human-date";
import {Tooltip, TooltipContent, TooltipTrigger} from "components/ui/tooltip";
import {useSearchParams} from "react-router-dom";
import {Button} from "../ui/button";
import {V1ContainerStatus} from "@kubernetes/client-node/dist/gen/model/v1ContainerStatus";

function PodsTable(props: {
    serviceName: string
    podDetails: K8sPodDetails[] | undefined
    isAggregated: boolean
}) {
    return (
        <div className="h-[256px] flex-none border bg-backgroundmedium rounded flex flex-col">
            <div className={"flex flex-row space-x-4 border-b p-4"}>
                <div className={"text-textmedium font-semibold text-lg"}>Pods</div>
                {(props.podDetails && props.podDetails?.length !== 0) &&
                    <Badge
                        className={"text-textmedium rounded border border-primary bg-primaryTransparenter"}> {props.podDetails.length} result{props.podDetails.length > 1 ? "s" : ""} </Badge>}
            </div>
            <div
                className="w-full grid-cols-6 grid-rows-10 px-4 py-2 rounded-tl rounded-tr gap-4 flex bg-backgrounddark">
                <div className="w-[272px] font-semibold leading-8 text-textdark text-sm flex-none">Name</div>
                <div className="w-[104px] flex-none font-semibold leading-8 text-textdark text-sm">Restarts</div>
                <div className="w-[96px] flex-none font-semibold leading-8 text-textdark text-sm">Age</div>
                <div className="w-[160px] flex-none font-semibold leading-8 text-textdark text-sm ">Status</div>
                <div className="w-[128px] flex-none font-semibold leading-8 text-textdark text-sm">Containers</div>
                <div
                    className="w-[240px] flex-none font-semibold leading-8 text-textdark text-sm hidden lg:block">Created
                </div>
                {props.isAggregated && <div
                    className="w-[240px] flex-none font-semibold leading-8 text-textdark text-sm hidden lg:block">Environment
                </div>}
            </div>
            <div
                className="max-w-full max-h-full min-w-0 min-h-0 overflow-y-auto shrink scrollMedium border-t rounded-b overflow-x-clip">
                {props.podDetails && props.podDetails.map((detail, index) => {
                    return <PodListItem podDetail={detail} serviceName={props.serviceName}
                                        isAggregated={props.isAggregated}/>
                })}

                {(!props.podDetails || props.podDetails?.length === 0) &&
                    <div className={"bg-backgroundmedium rounded flex grow"}>
                        <div className={"flex grow text-textmedium justify-center"}>
                            <div className="flex flex-row justify-center my-8 space-x-2 items-center">
                                <div className={"text-textdark text-center"}>No yaml found</div>
                                <FrownIcon className={"text-textdark"}/>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

function statusBadgeColour(status: string) {
// From go-client v1.Pod -> PodStatus.ContainerStatuses -> ContainerState
    if (status === "Running") {
        return "bg-secondarytransparent"
    } else if (status === "Waiting") {
        return "bg-amber-500"
    } else if (status === "Succeeded") {
        return "bg-primary"
    } else if (status === "Failed" || status === "Terminated") {
        return "bg-red-500"
    } else if (status === "Deleted") {
        return "bg-gray-500"
    } else if (status === "Unknown") {
        return "bg-gray-500"
    } else {
        return "bg-amber-500"
    }
}

export interface PodStatusDetails {
    state: string
    reason: string
    exitCode: number
}

function PodListItem(props: {
    serviceName: string
    podDetail: K8sPodDetails
    isAggregated: boolean
}) {
    const parsedYaml = yaml.load(props.podDetail.yaml)
    const k8sPod = parsedYaml as V1Pod
    const restartCount = k8sPod.status?.containerStatuses?.reduce((acc, containerStatus) => {
        return acc + containerStatus.restartCount
    }, 0);
    const podStartTime = k8sPod.status?.startTime
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const podStatus = getPodStatus(k8sPod, props.podDetail)


    useEffect(() => {
        let podName = searchParams.get("pod");
        if (podName === props.podDetail.name) {
            setIsExpanded(true)
        } else {
            setIsExpanded(false)
        }
    }, [props.podDetail.name, searchParams])

    function getPodStatus(podObj: V1Pod, podDetail: K8sPodDetails) {
        if (podDetail.isKilled) {
            return "Deleted"
        }
        const podState = getPodStatusFromContainerStatuses(podObj.status?.containerStatuses)
        return podState.state
    }

    function getPodStatusFromContainerStatuses(containers: Array<V1ContainerStatus> | undefined): PodStatusDetails {
        if (containers === undefined) {
            return {
                state: "Unknown",
                reason: "n/a",
                exitCode: 0
            }
        }
        let result;

        containers.forEach((container) => {
            if (container.state !== undefined) {
                if (container.state.terminated) {
                    let state = ""
                    if (container.state.terminated.exitCode !== 0) {
                        state = "Failed"
                    } else {
                        state = "Terminated"
                    }
                    result = {
                        state: state,
                        reason: container.state.terminated.reason,
                        exitCode: container.state.terminated.exitCode
                    }
                    return
                }
                if (container.state.waiting) {
                    if (container.state.waiting.reason !== "") {
                        // exitcode is not set for waiting state
                        // prioritize the Reason if it's set as it looks more like a proper state
                        result = {
                            state: container.state.waiting.reason,
                            reason: container.state.waiting.reason,
                            exitCode: 0
                        }
                        return
                    } else {
                        result = {
                            state: "Waiting",
                            reason: container.state.waiting.reason,
                            exitCode: 0
                        }
                        return
                    }
                }
            }
        });
        // If container is not in waiting or terminated state, it's running
        if (result === undefined) {
            return {
                state: "Running",
                reason: "",
                exitCode: 0
            }
        }
        return result
    }

    return (
        <Drawer.Root direction="right" open={isExpanded}>
            <Drawer.Trigger asChild>
                <div onClick={() => {
                    let params = new URLSearchParams(searchParams);
                    params.set("pod", props.podDetail.name);
                    setSearchParams(params);
                }}
                     className="w-full h-12 max-w-full px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip shrink">
                    <div
                        className="w-full px-2 justify-start items-center gap-4 flex hover:bg-backgroundlight overflow-x-clip hover:cursor-pointer">
                        <div
                            className="flex-none items-center w-[272px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate">
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="flex flex-row items-center space-x-2">
                                        <div className="flex-none">
                                            {props.podDetail.name}
                                        </div>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent
                                    className={"border bg-backgroundmedium text-textmedium rounded"}>{props.podDetail.name}</TooltipContent>
                            </Tooltip>
                        </div>
                        <div
                            className="flex-none items-center w-[104px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate">
                            {restartCount !== undefined ? restartCount : "N/A"}
                        </div>
                        <div
                            className="flex-none items-center w-[96px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate">
                            {podStartTime === undefined ? "N/A" : hdate.relativeTime(k8sPod.status?.startTime!, {pastSuffix: " "})}
                        </div>
                        <div
                            className="flex-none items-center w-[160px] top-0 text-textlight text-sm font-medium leading-[16px] truncate">
                            {<Badge
                                className={"rounded " + statusBadgeColour(podStatus) + " text-textlight"}>{podStatus}</Badge>}
                        </div>
                        <div
                            className="flex ml-0.5 flex-none items-center w-[128px] top-0 text-textmedium text-sm font-medium leading-[16px] truncate">
                            {getContainerRepresentation(podStatus, k8sPod)}
                        </div>
                        <div className="flex-none w-[240px] justify-start items-center space-x-2 hidden lg:block">
                            <div
                                className="flex items-center w-full top-0 text-textmedium text-sm font-medium  leading-[16px] truncate">
                                {
                                    hdate.prettyPrint(k8sPod.metadata?.creationTimestamp, {showTime: true})
                                }
                            </div>
                        </div>
                        {props.isAggregated &&
                            <div className="flex-none w-[240px] justify-start items-center space-x-2 hidden lg:block">
                                <div
                                    className="flex items-center w-full top-0 text-textmedium text-sm font-medium  leading-[16px] truncate">
                                    {props.podDetail.environment}
                                </div>
                            </div>
                        }
                        <div className="flex grow shrink justify-end items-center space-x-2">
                            <Button asChild
                                    className={"text-textmedium rounded bg-primarytransparent border-primary border"}>
                                <div className="flex items-center space-x-2">
                                    <div className="text-textmedium">View</div>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </Drawer.Trigger>
            <Drawer.Portal>
                <Drawer.Content
                    onInteractOutside={() => {
                        let params = new URLSearchParams(searchParams);
                        if (params.get("pod") === props.podDetail.name) {
                            params.delete("pod");
                            setSearchParams(params);
                        }
                    }}
                    className="select-text flex flex-col rounded-t-[10px] h-full md:w-2/3 lg:w-2/3 w-3/4 mt-12 fixed bottom-0 right-0">
                    <PodItemSideView podDetails={props.podDetail} indicatorColor={statusBadgeColour(podStatus)}
                                     serviceName={props.serviceName} podStatus={podStatus}/>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root>
    )
}

function getContainerRepresentation(podState: string, pod: V1Pod) {
    return <div className={"flex flex-row space-x-2"}>
        {pod.status?.containerStatuses?.map((containerStatus) => {
            return <>{ContainerRepIcon(podState, containerStatus.state)}
            </>
        })}
    </div>
}


function ContainerRepIcon(podState: string, state: V1ContainerState | undefined) {

    let dotColor = "gray-500"
    // Running state properties
    let stateString = ""
    let startedAt = ""
    // Waiting and Terminated state properties
    let reason = ""
    let message = ""
    // Terminated state properties
    let exitCode = ""
    let finishedAt = ""
    let signal = ""
    let containerID = ""

    // If pod is deleted then we cannot use the state object as its outdated.
    if (podState === "Deleted") {
        stateString = "Deleted"
        dotColor = "gray-500"
    } else {
        if (state === undefined) {
            stateString = "Unknown"
            // If the state is undefined, the pod is in an unknown state
        } else {
            if (state.running) {
                stateString = "Running"
                dotColor = "secondary"
                startedAt = hdate.prettyPrint(state.running.startedAt, {showTime: true})
            }
            if (state.waiting) {
                stateString = "Waiting"
                dotColor = "amber-500"
                reason = state.waiting.reason ? state.waiting.reason : ""
                message = state.waiting.message ? state.waiting.message : ""
            }
            if (state.terminated) {
                stateString = "Terminated"
                dotColor = "gray-500"
                reason = state.terminated.reason ? state.terminated.reason : ""
                message = state.terminated.message ? state.terminated.message : ""
                exitCode = state.terminated.exitCode ? state.terminated.exitCode.toString() : ""
                finishedAt = hdate.prettyPrint(state.terminated.finishedAt, {showTime: true})
                signal = state.terminated.signal ? state.terminated.signal.toString() : ""
                containerID = state.terminated.containerID ? state.terminated.containerID : ""
            }
        }
    }


    return <div>
        <Tooltip delayDuration={10}>
            <TooltipTrigger asChild>
                <SquareIcon className={`rounded bg-${dotColor} text-${dotColor} w-[12px] h-[12px] stroke-0`}/>
            </TooltipTrigger>
            <TooltipContent side={"bottom"} className={"m-4 p-4 bg-backgrounddark border rounded"}>
                <div className={"flex flex-col space-y-2"}>
                    <div className={"flex flex-row"}>
                        <div className={"text-textmedium font-semibold"}>State</div>
                        <div className={"text-textlight font-normal"}>: {stateString}</div>
                    </div>
                    {startedAt !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Started at</div>
                            <div className={"text-textlight font-normal"}>: {startedAt}</div>
                        </div>
                    }
                    {finishedAt !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Finished at</div>
                            <div className={"text-textlight font-normal"}>: {finishedAt}</div>
                        </div>
                    }
                    {containerID !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Container ID</div>
                            <div className={"text-textlight font-normal"}>: {containerID}</div>
                        </div>
                    }
                    {signal !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Signal</div>
                            <div className={"text-textlight font-normal"}>: {signal}</div>
                        </div>
                    }
                    {exitCode !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Exit code</div>
                            <div className={"text-textlight font-normal"}>: {exitCode}</div>
                        </div>
                    }
                    {reason !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Reason</div>
                            <div className={"text-textlight font-normal"}>: {reason}</div>
                        </div>
                    }
                    {message !== "" &&
                        <div className={"flex flex-row"}>
                            <div className={"text-textmedium font-semibold"}>Message</div>
                            <div className={"text-textlight font-normal"}>: {message}</div>
                        </div>
                    }
                </div>
            </TooltipContent>
        </Tooltip>

    </div>

}

export default PodsTable

