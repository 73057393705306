import React, {useEffect, useState} from "react";
import {Radio, RadioGroup} from '@headlessui/react'


import {AxiosResponse} from "axios";

import axios from "utility/customAxios";
import {BaseView} from "./BaseView";
import {cn} from "../components/ui/lib/utils";
import {CheckIcon} from "@radix-ui/react-icons";
import {Button} from "../components/ui/button";
import {PlanChangeDialog} from "../components/PlanChangeDialog";

export interface Product {
    id: string;
    name: string;
}

// Example

export interface ProductResponse {
    products: Product[];
}

// type GetCurrentProductResponse struct {
// 	CurrentProductID          string `json:"currentProductId"`
// 	NextBillingCycleProductID string `json:"nextBillingCycleProductId"`
// 	NextBillingCycleDate      string `json:"nextBillingCycleDate"`
// }

export interface CurrentProductResponse {
    currentProductName: string;
    currentProductId: string;
    nextBillingCycleProductId: string;
    nextBillingCycleProductName: string;
    nextBillingCycleDate: string;
}


// type GetBillingResponse struct {
// 	BillingRedirectURL string `json:"billingRedirectURL"`
// }

export interface BillingResponse {
    billingRedirectURL: string;
}

export function getBillingFromApi() {
    return axios.get('/api/v1/billing') as Promise<AxiosResponse<BillingResponse>>
}

export function getCurrentProductFromApi() {
    return axios.get('/api/v1/billing/current-product') as Promise<AxiosResponse<CurrentProductResponse>>
}

export function getProductDataFromApi() {
    return axios.get('/api/v1/billing/products') as Promise<AxiosResponse<ProductResponse>>
}

export function convertProductToPlan(product: Product): Tier {
    return {
        id: product.id,
        name: product.name,
    }
}

export interface Tier {
    id: string;
    name: string;
}

export interface PlanComparisonProps {
    // Assumed to be in order of increasing price
    plans: Tier[];
    onChoosePlan: (plan: Tier) => Promise<void>;
    canClick: boolean;
    currentPlanId?: string;
    nextPlanId?: string;
    nextBillingCycleDate?: string;
}


//type CheckoutRequest struct {
// 	ProductID string `json:"productId"`
// }
//
// type CheckoutResponse struct {
// 	DowngradeScheduled *bool   `json:"downgradeScheduled"`
// 	CancelledDowngrade *bool   `json:"cancelledDowngrade"`
// 	UpgradeRedirectURL *string `json:"upgradeRedirectURL"`
// }

export interface CheckoutResponse {
    downgradeScheduled?: boolean;
    cancelledDowngrade?: boolean;
    upgradeRedirectURL?: string;
}

export interface CheckoutRequest {
    productId: string;
}

export function checkout(productId: string) {
    return axios.post('/api/v1/checkout', {productId} as CheckoutRequest) as Promise<AxiosResponse<CheckoutResponse>>
}


const frequencies = [
    {value: 'month', label: 'Month'},
]
const tiers = [
    {
        name: 'Hobby',
        id: 'tier-hobby',
        href: 'https://us-east.metoro.io',
        featured: false,
        price: {month: '$0'},
        mainFeatures: ['1 Cluster', '1 User', '2 Nodes'],
    },
    {
        name: 'Scale',
        id: 'tier-scale',
        href: 'https://us-east.metoro.io/pricing',
        featured: true,
        price: {month: '$20'},
        mainFeatures: [
            'Unlimited clusters',
            'Unlimited users',
            'Unlimited nodes',
            'Support via slack',
        ],
    },
    {
        name: 'Enterprise',
        id: 'tier-enterprise',
        href: 'mailto:enterprise@metoro.io',
        featured: false,
        price: {month: 'Contact us'},
        mainFeatures: [
            'Unlimited clusters',
            'Unlimited users',
            'Unlimited nodes',
            '24/7 white glove support and onboarding',
            'Custom SLAs',
            'On-premises deployments',
        ],
    },
]


const Billing = () => {
    const [plans, setPlans] = useState<Tier[]>();
    const [currentProductResponse, setCurrentProductResponse] = useState<CurrentProductResponse>();
    const [billingRedirectURL, setBillingRedirectURL] = useState<string>();
    const [hasAccessToBilling, setHasAccessToBilling] = useState<boolean | undefined>(undefined);
    const [frequency, setFrequency] = useState(frequencies[0])
    const [openDialogs, setOpenDialogs] = React.useState<boolean[]>(tiers.map(p => false));

    async function updateDetails() {
        await getProductDataFromApi().then(response => {
            let plansToUse = response.data.products.map((p: Product) => convertProductToPlan(p));
            setPlans(plansToUse)
        }).catch(err => {
            console.log(err);
        });
        await getCurrentProductFromApi().then(response => {
            setCurrentProductResponse(response.data);
        }).catch(err => {
            console.log(err);
        });
        await getBillingFromApi().then(async response => {
                setBillingRedirectURL(response.data.billingRedirectURL);
                setHasAccessToBilling(true);
            }
        ).catch(err => {
            if (err.response.status === 403 || err.response.status === 401) {
                setHasAccessToBilling(false);
                return;
            }
            console.log(err);
        });
    }

    useEffect(() => {
        updateDetails();
    }, []);

    let onChoosePlan = async (tier: Tier) => {
        if (tier.name === "Enterprise") {
            window.location.href = "mailto:sales@metoro.io";
        }
        let planId = "";
        for (let plan of plans!) {
            if (plan.name === tier.name) {
                planId = plan.id;
            }
        }

        await checkout(planId).then(response => {
            if (response.data.upgradeRedirectURL) {
                window.location.href = response.data.upgradeRedirectURL;
            }
        }).then(updateDetails).catch(err => {
            console.log(err);
        });
    }

    return <BaseView disableTimeRange title={"Billing"} disableClusterSelector={true}>
        <div className={"flex flex-col grow shrink justify-center"}>
            <div className={"p-4"}>
                <div className="flow-root py-24">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="relative z-10">
                            <div className={"flex flex-col items-center justify-center"}>
                                <div id={"pricing"}
                                     className="inline text-center font-display text-3xl tracking-tight text-textlight mb-6">
                                    {"Make a change to your plan"}
                                </div>
                                <Button onClick={async () => {
                                    if (billingRedirectURL && hasAccessToBilling) {
                                        window.location.href = billingRedirectURL!;
                                    }
                                }} className={"bg-primarytransparent border border-primary text-textlight"}>
                                    Update your billing details
                                </Button>
                            </div>
                            {frequencies.length > 1 &&
                                <div className="mt-16 flex justify-center">
                                    <fieldset aria-label="Payment frequency">
                                        <RadioGroup
                                            value={frequency}
                                            onChange={setFrequency}
                                            className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-textlight"
                                        >
                                            {frequencies.map((option) => (
                                                <Radio
                                                    key={option.value}
                                                    value={option}
                                                    className={({checked}) =>
                                                        cn(checked ? 'bg-indigo-500' : '', 'cursor-pointer rounded-full px-2.5 py-1')
                                                    }
                                                >
                                                    {option.label}
                                                </Radio>
                                            ))}
                                        </RadioGroup>
                                    </fieldset>
                                </div>
                            }
                        </div>
                        <div
                            className="relative mx-auto mt-10 grid max-w-sm grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
                            <svg
                                viewBox="0 0 604 512"
                                aria-hidden="true"
                                className=" hidden lg:block absolute -bottom-48 left-1/2 h-[40rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-32 lg:bottom-auto lg:translate-y-0"
                            >
                                <ellipse cx={302} cy={256} fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)" rx={302}
                                         ry={256}/>
                                <defs>
                                    <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                                        <stop stopColor="#38bdf8"/>
                                        <stop offset={2} stopColor="#38bdf8"/>
                                    </radialGradient>
                                </defs>
                            </svg>
                            <div
                                className="hidden lg:absolute lg:inset-x-px lg:top-4 lg:bottom-4 lg:block lg:rounded-t lg:bg-backgrounddark lg:ring-1 lg:ring-primary"
                                aria-hidden="true"
                            />
                            {tiers.map((tier, index) => (
                                <div
                                    key={tier.id}
                                    className={cn(
                                        tier.featured
                                            ? 'z-10 bg-backgrounddark border border-primary shadow-xl'
                                            : ' ring-1 ring-white/10 lg:bg-transparent lg:pb-14 lg:ring-0',
                                        currentProductResponse?.currentProductName === tier.name ? "border-secondary" : currentProductResponse?.nextBillingCycleProductName === tier.name ? "border-orange-600" : '',
                                        'relative rounded'
                                    )}
                                >
                                    <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                                        <h3
                                            id={tier.id}
                                            className={cn(
                                                tier.featured ? 'text-textmedium' : 'text-textmedium',
                                                'text-sm font-semibold leading-6'
                                            )}
                                        >
                                            {tier.name}
                                        </h3>
                                        <div
                                            className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                                            <div className="mt-2 flex items-center gap-x-4">
                                                <p
                                                    className={cn(
                                                        tier.featured ? 'text-textmedium' : 'text-textmedium',
                                                        'text-4xl font-bold tracking-tight'
                                                    )}
                                                >
                                                    {
                                                        // @ts-ignore
                                                        tier.price[frequency.value]
                                                    }
                                                </p>
                                                {
                                                    // @ts-ignore
                                                    tier.price[frequency.value] !== 'Contact us' &&
                                                    <div className="text-sm leading-5">
                                                        <p className={tier.featured ? 'text-textmedium' : 'text-textmedium'}>USD</p>
                                                        <p
                                                            className={tier.featured ? 'text-textmedium' : 'text-textmedium'}
                                                        >{`/ node / ${frequency.value}`}</p>
                                                    </div>}
                                            </div>
                                            <button
                                                onClick={() => {
                                                    if (tier.name === currentProductResponse?.nextBillingCycleProductName) {
                                                        return;
                                                    }
                                                    if (tier.name === "Enterprise") {
                                                        window.location.href = "mailto:enterprise@metoro.io";
                                                        return
                                                    }
                                                    let newOpenDialogs = [...openDialogs];
                                                    newOpenDialogs[index] = true;
                                                    setOpenDialogs(newOpenDialogs);
                                                }}
                                                aria-describedby={tier.id}
                                                className={cn(
                                                    tier.featured
                                                        ? 'bg-secondarytransparenter border border-secondary shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600'
                                                        : 'bg-primarytransparent border-primary border hover:bg-white/20 focus-visible:outline-white',
                                                    'rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-textlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
                                                   currentProductResponse?.nextBillingCycleProductName === tier.name ? 'bg-gray-500 text-textmedium border-gray-700 hover:cursor-default hover:bg-gray-500' : '',
                                                )}
                                            >
                                                {
                                                    // @ts-ignore
                                                    tier.name === currentProductResponse?.currentProductName && currentProductResponse.nextBillingCycleProductName === currentProductResponse.currentProductName ? "Current plan" : tier.name === currentProductResponse?.nextBillingCycleProductName ? "Changing to plan next billing cycle" : tier.price[frequency.value] === 'Contact us' ? 'Contact us' : 'Change to plan'}
                                            </button>
                                        </div>
                                        <div className="mt-8 flow-root sm:mt-10">
                                            <ul
                                                role="list"
                                                className={cn(
                                                    tier.featured
                                                        ? 'divide-white/5 border-gray-900/5 text-gray-600'
                                                        : 'divide-white/5 border-white/5 text-textlight',
                                                    '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'
                                                )}
                                            >
                                                {tier.mainFeatures.map((mainFeature) => (
                                                    <li key={mainFeature} className="flex gap-x-3 py-2 text-textmedium">
                                                        <CheckIcon
                                                            className={cn(
                                                                tier.featured ? 'text-secondary' : 'text-textmedium',
                                                                'h-6 w-5 flex-none'
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                        {mainFeature}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <PlanChangeDialog openDialog={openDialogs.at(index)!} onConfirm={async () => {
                                            // @ts-ignore
                                            await onChoosePlan(tier).then(() => {
                                                setOpenDialogs(new Array(tiers.length).fill(false));
                                            })
                                        }} onCancel={async () => {
                                            setOpenDialogs(new Array(tiers.length).fill(false));
                                        }}/>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseView>

}

export default Billing;