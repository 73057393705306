"use client"

import * as React from "react"

import {cn} from "components/ui/lib/utils"
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import useAuth from "hooks/useAuth";
import {EmailAuthLogin} from "./EmailAuthLogin";

interface UserAuthFormLoginProps extends React.HTMLAttributes<HTMLDivElement> {
}

export function UserAuthFormLogin({className, ...props}: UserAuthFormLoginProps) {
    const {isLoggedIn, loginGoogleSSO} = useAuth();
    const [isLoading, setIsLoading] = React.useState<boolean>(false)

    async function onSubmit(event: React.SyntheticEvent) {
        event.preventDefault()
        setIsLoading(true)

        setTimeout(() => {
            setIsLoading(false)
        }, 3000)
    }

    return (
        <div className={cn("w-full grid gap-6", className)} {...props}>
            <GoogleOAuthProvider
                clientId="822301410763-mlt2jbo7blucoek4d1vbm4efuc30gd16.apps.googleusercontent.com"
            >
                <GoogleLogin
                    containerProps={{
                        style: {
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                    text="signin_with"
                    logo_alignment={"left"}
                    theme="outline"
                    size="medium"
                    useOneTap={false}
                    type={"standard"}
                    onSuccess={async (credentialResponse) => {
                        try {
                            // No invite token.
                            await loginGoogleSSO(credentialResponse, "");
                        } catch (err: any) {
                            alert("Login unsuccessful. Please try again.")
                        }
                    }}
                    onError={() => {
                        console.log('Login Failed');
                        alert("Login unsuccessful. Please try again.")
                    }}
                />
            </GoogleOAuthProvider>
            <div className="relative">
                <div className="absolute inset-0 flex items-center text-textmedium">
                    <span className="w-full border-t"/>
                </div>
                <div className="relative flex justify-center text-xs uppercase text-textmedium">
                    <span className="px-2 text-muted-foreground bg-background text-textmedium">
                        Or
                    </span>
                </div>
            </div>
            <EmailAuthLogin/>
        </div>
    )
}