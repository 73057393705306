import React, {MouseEvent, ReactElement, useState} from "react";
import {Checkbox} from "components/ui/checkbox";
import humanFormat from "human-format";
import {ChevronDownIcon, ChevronRightIcon} from "@radix-ui/react-icons";
import {ArrowLeftToLine, ArrowRightFromLine, SearchIcon, XIcon} from "lucide-react";
import {Input} from "components/ui/input";
import {Attribute} from "types/telemetry";
import {cn} from "../ui/lib/utils";
import {Tooltip, TooltipTrigger} from "../ui/tooltip";
import {TooltipContent} from "@radix-ui/react-tooltip";


export function FilterPanel(props: {
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>
    setExcludeFilter: (filter: Map<string, string[]>) => void
    attributes: Map<string, Attribute[]>,
    telemetryFiltersComponent: ReactElement,
    filteringCriteria: string,
    setFilterShrank?: (val: boolean) => void,
    initiallyOpenFilterKeys?: string[]
}) {
    const [filterFilter, setFilterFilter] = useState<string>("");
    const [shrinkFilter, setShrinkFilter] = useState<boolean>(false);

    let updateShrinkFilters = (shrank: boolean) => {
        if (props.setFilterShrank !== undefined) {
            props.setFilterShrank(shrank);
        }
        setShrinkFilter(shrank);
    };

    if (shrinkFilter) {
        return <div className={"flex hover:text-primary text-textlight"}>
            <ArrowRightFromLine
                className={"absolute top-0 right-0 z-20 w-5 h-5 hover:cursor-pointer hover:text-primary"}
                onClick={() => updateShrinkFilters(false)}
            />
            <div style={{writingMode: "vertical-lr", transform: "rotate(180deg)"}}
                 onClick={() => updateShrinkFilters(false)}
                 className={"text-textlight bg-backgroundmedium text-center border rounded h-full hover:bg-primarytransparent hover:cursor-pointer hover:text-primary hover:border-primaryhover"}
            >
                Show Filters
            </div>
        </div>
    }

    return <div className={"w-[304px] bg-backgroundmedium flex-none select-none flex flex-col rounded"}>
        <Header setFilterFilter={setFilterFilter} setShrinkFilter={updateShrinkFilters}/>
        <div className={"h-full min-h-0 overflow-y-auto scrollMedium border-l grow border-b border-r rounded-b "}>
            {props.telemetryFiltersComponent}
            <CustomFilters
                initiallyOpenFilterKeys={props.initiallyOpenFilterKeys}
                filterFilter={filterFilter} filter={props.filter} setFilter={props.setFilter}
                excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}
                attributes={props.attributes} filteringCriteria={props.filteringCriteria}/>
        </div>
    </div>
}

function Header(props: { setFilterFilter: (filter: string) => void, setShrinkFilter?: (val: boolean) => void }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    return <div className={"flex-none rounded-t h-[48px] w-full border border-border"}>
        <div className={"flex justify-between p-2 pt-[5px] "}>
            {!isOpen ?
                <div onClick={() => setIsOpen(true)}
                     className={"w-6 h-6 relative pt-[5px] text-textmedium hover:text-textlight hover:cursor-pointer"}>
                    <SearchIcon/>
                </div> : <SearchFilter setFilterFilter={props.setFilterFilter}/>}
            {
                !isOpen &&
                <div className={"font-normal font-['Inter'] leading-8 text-textmedium text-xl"}>Filter</div>
            }
            {isOpen &&
                <div onClick={() => {
                    setIsOpen(false);
                    props.setFilterFilter("");
                }}
                     className={"w-6 h-6 mt-[5px] relative text-textmedium hover:bg-primary rounded hover:cursor-pointer"}>
                    <XIcon/>
                </div>
            }
            {!isOpen && <Tooltip delayDuration={10}>
                <TooltipTrigger>
                    <div onClick={() => {
                        if (props.setShrinkFilter !== undefined) return props.setShrinkFilter(true)
                    }}
                         className={"w-6 h-6 mt-[5px] relative text-textmedium hover:text-primary rounded hover:cursor-pointer"}>
                        <ArrowLeftToLine/>
                    </div>
                </TooltipTrigger>
                <TooltipContent
                    side={"right"}
                    className={"z-50 ml-2 py-1 px-2 bg-background border rounded text-textmedium"}>
                    Hide Filters </TooltipContent>
            </Tooltip>}
        </div>
    </div>;
}

function SearchFilter(props: { setFilterFilter: (filter: string) => void }) {
    return <Input placeholder={"Search for filter..."} id={"search_filter_input"} onChangeCapture={(e) => {
        props.setFilterFilter(e.currentTarget.value);
    }} className={"h-[32px] rounded border-border w-10/12"}/>
}

export function CustomFilters(props: {
    filterFilter: string,
    filter: Map<string, string[]>,
    excludeFilter: Map<string, string[]>,
    attributes: Map<string, Attribute[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    setExcludeFilter: (filter: Map<string, string[]>) => void,
    filteringCriteria: string
    initiallyOpenFilterKeys?: string[]
}) {
    const filters = Array.from(props.attributes.keys()).filter((key) => key !== props.filteringCriteria);
    filters.sort((a, b) => {
        if (a === "server.service.name") return -1;
        if (b === "server.service.name") return 1;
        if (a === "client.service.name") return -1;
        if (b === "client.service.name") return 1;
        if (a === "service.name") return -1;
        if (b === "service.name") return 1;
        return 0;
    });
    const customFilters: ReactElement[] = [];
    for (const filter of filters) {
        const filterValues = props.attributes.get(filter);
        if (filterValues) {
            customFilters.push(<FilterGroup
                initiallyOpenFilterKeys={props.initiallyOpenFilterKeys}
                filterFilter={props.filterFilter} key={filter} filterKey={filter}
                filterValues={filterValues}
                filter={props.filter} setFilter={props.setFilter}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}/>)
        }
    }
    return <div>
        {customFilters}
    </div>
}

function FilterGroup(props: {
    filterFilter: string,
    filter: Map<string, string[]>,
    filterKey: string,
    filterValues: Attribute[],
    setFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
    initiallyOpenFilterKeys?: string[]
}) {
    // These are the filter keys that should be open by default
    const [isOpen, setIsOpen] = useState<boolean>((props.initiallyOpenFilterKeys || []).includes(props.filterKey));
    let filters: ReactElement[] = [];
    for (const filterValue of props.filterValues) {
        if (props.filterFilter !== "" && !filterValue.value.includes(props.filterFilter) && !props.filterKey.includes(props.filterFilter)) {
            continue;
        }
        filters.push(<FilterSelector filterFilter={props.filterFilter} key={filterValue.value}
                                     filterKey={props.filterKey} filterValue={filterValue.value}
                                     filterValueDisplay={formatFilterValues(props.filterKey, filterValue.value)}
                                     volume={filterValue.volume} filter={props.filter} setFilter={props.setFilter}
                                     excludeFilter={props.excludeFilter} setExcludeFilter={props.setExcludeFilter}/>)
    }
    filters = filters.filter((filter) => filter !== null);
    if (filters.length === 0) {
        return null;
    }
    let open = isOpen || props.filterFilter !== "";
    return <div className={cn("group border rounded mx-2 mb-2", !open ? "hover:bg-primary" : "")}>
        <FilterHeader filterFilter={props.filterFilter} isOpen={open} setIsOpen={setIsOpen} name={props.filterKey}/>
        {open && <div className={"py-2"}>
            {filters}
        </div>}
        {!isOpen && <div className={""}/>}
    </div>
}

export function FilterSelector(props: {
    filterValueDisplay?: string,
    filterFilter: string,
    filter: Map<string, string[]>,
    indicatorColor?: string,
    filterKey: string,
    filterValue: string,
    volume: number,
    setFilter: (filter: Map<string, string[]>) => void
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void
}) {
    let checked = false;
    if (!props.filter.has(props.filterKey)) {
        checked = true;
    }
    if (props.filter.has(props.filterKey) && props.filter.get(props.filterKey)?.includes(props.filterValue)) {
        checked = true;
    }
    if (props.excludeFilter.has(props.filterKey) && props.excludeFilter.get(props.filterKey)?.includes(props.filterValue)) {
        checked = false;
    }
    const updateChecked = (checked: boolean) => {
        const newExcludeFilter = new Map(props.excludeFilter);
        // If it isnt checked, then we should remove it from the exclude filter if it exists, otherwise we need to add it to the inclusive filter
        if (checked) {
            // If the filter is already in the exclude filter, we should remove it
            if (newExcludeFilter.has(props.filterKey) && newExcludeFilter.get(props.filterKey)?.includes(props.filterValue)) {
                // Remove the value from the exclude filter
                const newValues = newExcludeFilter.get(props.filterKey)?.filter((value) => value !== props.filterValue);
                if (newValues && newValues.length > 0) {
                    newExcludeFilter.set(props.filterKey, newValues);
                } else {
                    newExcludeFilter.delete(props.filterKey);
                }
                props.setExcludeFilter(newExcludeFilter);
                return;
            }
            // Otherwise it means that there is another inclusive filter for the key so we should add this value to the inclusive filter
            const newFilter = new Map(props.filter);
            if (newFilter.has(props.filterKey)) {
                newFilter.get(props.filterKey)?.push(props.filterValue);
                props.setFilter(newFilter);
                return;
            }
        } else {
            // We must add it to the exclude filter
            if (!newExcludeFilter.has(props.filterKey)) {
                newExcludeFilter.set(props.filterKey, [props.filterValue]);
                props.setExcludeFilter(newExcludeFilter);
                return;
            } else {
                newExcludeFilter.get(props.filterKey)?.push(props.filterValue);
                props.setExcludeFilter(newExcludeFilter);
                return;
            }
        }
    }
    const all = props.filter.has(props.filterKey) && props.filter.get(props.filterKey)?.length == 1 && props.filter.get(props.filterKey)?.includes(props.filterValue);

    const updateOverallClicked = (e: MouseEvent<HTMLDivElement>) => {
        if (all) {
            const newExcludeFilter = new Map(props.excludeFilter);
            newExcludeFilter.delete(props.filterKey);
            props.setExcludeFilter(newExcludeFilter);
            const newFilter = new Map(props.filter);
            newFilter.delete(props.filterKey);
            props.setFilter(newFilter);
            return;
        } else {
            const newExcludeFilter = new Map(props.excludeFilter);
            newExcludeFilter.delete(props.filterKey);
            props.setExcludeFilter(newExcludeFilter);
            const newFilter = new Map(props.filter);
            newFilter.set(props.filterKey, [props.filterValue]);
            props.setFilter(newFilter);
            return;
        }
    }

    return <div id={"toplevel"} onClick={(e) => updateOverallClicked(e)}
                className={"min-w-0 overflow-hidden group/item flex justify-between p-1 px-4 items-center hover:cursor-pointer hover:bg-primarytransparent border-primary hover:p-[3px] hover:px-[15px] hover:border border-opacity-0 hover:border-opacity-100"}>
        <div className={"flex justify-start space-x-2 items-center"}>
            <Checkbox onClick={(e) => {
                e.stopPropagation()
            }} checked={checked} onCheckedChange={updateChecked}/>
            {
                props.indicatorColor &&
                <div className={"mt-[1px] w-0.5 h-3.5 rounded " + props.indicatorColor}/>
            }
            <Tooltip delayDuration={30}>
                <TooltipTrigger>
                    {
                        <div
                            dangerouslySetInnerHTML={{__html: props.filterFilter !== "" ? (props.filterValueDisplay ? props.filterValueDisplay : props.filterValue).replace(props.filterFilter, '<mark class="highlight">$&</mark>') : (props.filterValueDisplay ? props.filterValueDisplay : props.filterValue)}}
                            className={"mt-[1px] text-left truncate w-[140px] text-textmedium text-sm font-medium font-['Inter'] leading-[16px]"}>
                        </div>
                    }
                </TooltipTrigger>
                <TooltipContent side={"right"}
                                className={"ml-[100px] border bg-backgroundmedium text-textmedium p-1 rounded z-50"}>
                    {props.filterValueDisplay ? props.filterValueDisplay : props.filterValue}
                </TooltipContent>
            </Tooltip>

        </div>
        <div className={"flex mt-[1px] justify-end"}>
            <div className={"flex"}>
                <div
                    className={"hidden group-hover/item:block text-textlight bold text-sm font-bold leading-[16px] mr-[-4px]"}>
                    {
                        all ? "All" : "Only"
                    }
                </div>
                <div
                    className={"text-textmedium text-sm font-medium font-['Inter'] leading-[16px] group-hover/item:hidden"}>{humanFormat(props.volume, {maxDecimals: 1})}</div>
            </div>
        </div>
    </div>
}


function FilterHeader(props: {
    name: string,
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    filterFilter: string
}) {
    return <div className={""}>
        <div onClick={() => props.setIsOpen(!props.isOpen)}
             className={cn("hover:cursor-pointer hover:bg-primary h-6 border-b bg-backgroundlight rounded-t justify-start items-center px-2 gap-2 inline-flex w-full", !props.isOpen && props.filterFilter === "" ? "border-b-0 group-hover:bg-primary" : "")}>
            <div className="stroke-textmedium">
                {
                    props.isOpen ?
                        <ChevronDownIcon/> :
                        <ChevronRightIcon/>
                }</div>
            <Tooltip delayDuration={30}>
                <TooltipTrigger className={"text-textmedium text-sm font-semibold leading-tight truncate"}>
                    <div
                        dangerouslySetInnerHTML={{__html: props.filterFilter !== "" ? props.name.replace(props.filterFilter, '<mark class="highlight">$&</mark>') : props.name}}
                        className="text-textmedium text-sm font-semibold leading-tight truncate"></div>
                </TooltipTrigger>
                <TooltipContent
                    className={"border bg-backgroundmedium text-textmedium p-1 rounded z-50"}>{props.name}</TooltipContent>
            </Tooltip>

        </div>
    </div>
}

export function formatFilterValues(filterKey: string, filterValue: string) {
    if (filterKey === "server.service.name") {
        return formatServiceNameFilterValues(filterValue);
    }
    if (filterKey === "client.service.name") {
        return formatServiceNameFilterValues(filterValue);
    }
    if (filterKey === "service.name") {
        return formatServiceNameFilterValues(filterValue);
    }
    if (filterKey === "peer.service.name") {
        return formatServiceNameFilterValues(filterValue);
    }
    if (filterKey === "client.name") {
        return formatServiceNameFilterValues(filterValue);
    }

    if (filterKey === "container.id") {
        return formatContainerIdFilterValues(filterValue);
    }
    if (filterKey === "client.container.id") {
        return formatContainerIdFilterValues(filterValue);
    }
    return filterValue;
}

function formatServiceNameFilterValues(filterValue: string) {
// Split the path by slashes
    const segments = filterValue.split('/');
// Return the last segment
    return segments[segments.length - 1];
}

function formatContainerIdFilterValues(filterValue: string): string {
// Split the path by slashes
    const segments = filterValue.split('/');
// Get the last two segments
    return segments.slice(-2).join('/');
}

