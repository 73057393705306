import React, {Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {CircularProgress} from "@mui/material";


export interface PlanChangeDialogProps {
    openDialog: boolean;
    onConfirm: () => Promise<void>;
    onCancel: () => Promise<void>;
}

export const PlanChangeDialog: React.FC<PlanChangeDialogProps> = ({openDialog, onConfirm, onCancel}) => {
    let open = openDialog;
    const [loading, setLoading] = useState(false)

    const cancelButtonRef = useRef(null)


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onCancel}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden bg-backgroundmedium border rounded px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-textlight">
                                            Are you sure you want to change your plan?
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-textmedium">
                                                If you are upgrading, you will be taken to stripe to complete the
                                                upgrade immediately and you will be prorated for the remainder of the
                                                billing cycle.
                                                If you are downgrading, the change will take effect at the end of your
                                                current billing cycle.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {loading ?
                                    <div className="flex justify-center mt-5">
                                        <CircularProgress/>
                                    </div> :
                                    <div
                                        className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-textmedium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 border border-primary bg-primarytransparent"
                                            onClick={async () => {
                                                setLoading(true);
                                                await onConfirm()
                                                setLoading(false);
                                            }}
                                        >
                                            Change plan
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold bg-button text-textmedium border-none shadow-sm hover:bg-buttonhover sm:col-start-1 sm:mt-0"
                                            onClick={async () => {
                                                await onCancel();
                                            }}
                                            ref={cancelButtonRef}
                                        >
                                            Don't change
                                        </button>
                                    </div>}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}