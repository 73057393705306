import React, {ReactElement, useEffect, useState} from "react";
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import 'chartjs-adapter-date-fns';
import {Bar, Line} from "react-chartjs-2";
import {cn} from "../components/ui/lib/utils";
import axios from "../utility/customAxios";
import {plainToInstance} from "class-transformer";
import {shortEnglishHumanizer} from "../components/ServicePanel/ServicePanel";
import {AxiosPromise} from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {Dashboard} from "../components/Dashboarding/Dashboard";
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import {ThresholdComparator} from "./AlertCreation";
import {TimeRange} from "../types/time";
import {useDispatch} from "react-redux";
import {set} from "../store/reducers/timerange";
import {useDebouncedCallback} from "use-debounce";
import {MetricFunction} from "../components/Dashboarding/widgets/MetricSelector";
import {MonitorEvaluationPayload} from "./alerts/MetricAlert";


interface GetMetricRequest {
    metricName: string;
    startTime: number;
    endTime: number;
    filters: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits: string[];
    aggregation: string;
    isRate: boolean;
    functions: MetricFunction[];
}

export interface MathExpression {
    variables: string[];
    expression: string;
}

// A variable in a math expression
interface MathExpressionVariable {
    // variable name, e.g. a, b, c, etc.
    name: string;
    // value is the name of the metric this variable represents
    // e.g. a / 60 where a = requests_total metric.
    value: string;
}

interface GetMetricAggregateEvaluationRequest extends GetMetricRequest {
    aggregateParams: MonitorEvaluationPayload;
}
class GetMetricResponse {
    constructor(metric: Metric) {
        this.metric = metric;
    }

    metric: Metric;
}

interface Metric {
    // The name of the metric
    name: string;
    // The time series that make up this metric
    timeSeries: TimeSeries[];
    // The type of the metric, e.g. "gauge", "counter", etc.
    type: string;
    // The attributes that are common to all time series
    attributes: Map<string, string[]>;
}

interface TimeSeries {
    data: DataPoint[];
    // The attributes that are specific to this time series
    attributes: Map<string, string>;
}

interface DataPoint {
    // The time at which the data point was recorded in milliseconds since epoch
    time: number;
    // The value of the data point
    value: number;
}

interface ColorPairing {
    backgroundColor: string;
    borderColor: string;
}

const colorings: ColorPairing[] = [
    {
        borderColor: "rgb(55, 147, 255)",
        backgroundColor: "rgba(55, 147, 255, 0.2)",
    },
    {
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
    },
    {
        borderColor: "rgb(255, 206, 86)",
        backgroundColor: "rgba(255, 206, 86, 0.2)",
    },
    {
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
    },
    {
        borderColor: "rgb(255, 159, 64)",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
    },
    {
        borderColor: "rgb(153, 102, 255)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
    },
    {
        borderColor: "rgb(255, 205, 86)",
        backgroundColor: "rgba(255, 205, 86, 0.2)",
    },

    {
        borderColor: "rgb(54, 162, 235)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
    },
]

function MetricToChartData(metric: Metric | undefined, isTime: boolean, colorBackground: string | undefined = undefined, colorBorder: string | undefined = undefined, customColours: Map<Record<string, string>, string[]> | undefined = undefined): any {
    if (metric === undefined) {
        return undefined;
    }
    let data = [];
    let i = 0;
    // Sorted by total value of the time series
    let sorted = metric.timeSeries.sort((a, b) => {
        let totalA = a.data.reduce((acc, curr) => {
            return acc + curr.value
        }, 0)
        let totalB = b.data.reduce((acc, curr) => {
            return acc + curr.value
        }, 0)
        return totalB - totalA
    })

    for (let timeSeries of sorted) {
        let label = "";
        const entries = Object.entries(timeSeries.attributes)
        let customColourBackground = undefined;
        let customColourBorder = undefined;
        if (customColours !== undefined) {
            for (let [attributePair, colour] of customColours) {
                for (let entry of entries) {
                    if (entry[0] === attributePair.key && entry[1] === attributePair.value) {
                        customColourBackground = colour[1];
                        customColourBorder = colour[0];
                        break;
                    }
                }
            }
        }
        for (const entry of entries) {
            label += entry[0] + "=" + entry[1] + ", ";
        }
        if (label.length > 0) {
            label = label.substring(0, label.length - 2);
        }
        let dataset = {
            label: label,
            data: timeSeries.data.map((dataPoint) => {
                return {
                    x: dataPoint.time,
                    y: isTime ? dataPoint.value / 1_000_000 : dataPoint.value // nanoseconds to milliseconds
                }
            }),
            borderWidth: 1,
            // borderSkipped: "middle",
            backgroundColor: customColourBackground !== undefined ? customColourBackground : colorBackground ? colorBackground : colorings[i % colorings.length].backgroundColor,
            borderColor: customColourBorder !== undefined ? customColourBorder : colorBorder ? colorBorder : colorings[i % colorings.length].borderColor
        };
        data.push(dataset);
        i++;
    }
    return {
        datasets: data,
    };
}

//	// Required: Start time of when to get the logs in seconds since epoch
// 	StartTime int64 `json:"startTime"`
// 	// Required: End time of when to get the logs in seconds since epoch
// 	EndTime int64 `json:"endTime"`
//
// 	// Temporary:
// 	ServiceName string `json:"serviceName"`
//
// 	// The filters to apply to the logs, so for example, if you want to get logs for a specific service
// 	//you can pass in a filter like {"service_name": ["microservice_a"]}
// 	Filters map[string][]string `json:"filters"`
// 	// Splts is a list of attributes to split the metrics by, for example, if you want to split the metrics by service
// 	// you can pass in a list like ["service_name"]
// 	Splits []string `json:"splits"`
interface GetAllTraceMetricsRequest {
    startTime: number;
    endTime: number;
    serviceNames?: string[];
    filters?: any;
    excludeFilters?: any;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    onlyNumRequests?: boolean;
    environments?: string[];
}

interface GetTraceMetricRequest {
    startTime: number;
    endTime: number;
    serviceNames?: string[];
    filters?: any;
    excludeFilters?: any;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    environments?: string[];
    aggregate: string;
    functions: MetricFunction[];
}

class GetAllTraceMetricsResponse {
    constructor(metrics: Metric[]) {
        this.metrics = metrics;
    }

    metrics: Metric[];
}

interface GetLogMetricsRequest {
    startTime: number;
    endTime: number;
    serviceName?: string;
    filters?: Map<string, string[]>;
    excludeFilters?: Map<string, string[]>;
    splits?: string[];
    regexes?: string[];
    excludeRegexes?: string[];
    environments?: string[];
}

class GetLogMetricsResponse {
    constructor(metric: Metric) {
        this.metric = metric;
    }

    metric: Metric;
}


async function GetLogMetrics(request: GetLogMetricsRequest): Promise<GetLogMetricsResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/logMetrics", transformed,
    )
    let awaited = (await d).data;
    return plainToInstance(GetLogMetricsResponse, awaited);
}

async function GetAllTraceMetrics(request: GetAllTraceMetricsRequest): Promise<GetAllTraceMetricsResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/traceMetrics", transformed,
    )
    let awaited = (await d).data;
    return plainToInstance(GetAllTraceMetricsResponse, awaited);
}

async function GetTraceMetric(request: GetTraceMetricRequest): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: request.filters ? Object.fromEntries(request.filters) : undefined,
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }
    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/traceMetric", transformed,
    )
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}


async function GetMetric(request: GetMetricRequest): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: Object.fromEntries(request.filters),
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }

    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/metric", transformed)
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}

async function GetAggregateMetricEvaluation(request: GetMetricAggregateEvaluationRequest): Promise<GetMetricResponse> {
    const transformed = {
        ...request,
        filters: Object.fromEntries(request.filters),
        excludeFilters: request.excludeFilters ? Object.fromEntries(request.excludeFilters) : undefined,
    }

    const d: AxiosPromise<GetMetricResponse> = axios.post("/api/v1/metric/aggregate", transformed)
    let awaited = (await d).data;
    return plainToInstance(GetMetricResponse, awaited);
}


const MetricsTest: React.FC = () => {
    return (
        <div className={"w-screen h-screen flex justify-center overflow-y-auto"}>
            <div className={"flex flex-col justify-center"}>
                <div className={"flex flex-col justify-center h-screen w-screen"}>
                    {/*<MetricSelectorPanel/>*/}
                    <Dashboard/>
                </div>
            </div>
        </div>
    );
}

enum ChartType {
    Bar = "bar",
    Line = "line",
}

enum MetricType {
    Metric = "metric",
    Trace = "trace",
}

function ChartContainer(props: {
    editDialog?: ReactElement,
    styling?: ChartStyling,
    className?: string,
    title?: string,
    children: any
    dataUsed?: any
    chartType?: ChartType,
    chartOptions?: any,
    hideLegend?: boolean
}) {
    const [selected, setSelected] = useState(props.dataUsed !== undefined && props.dataUsed.datasets !== undefined ? props.dataUsed.datasets : []);
    const [chart, setChart] = useState(props.children);

    useEffect(() => {
        if (props.dataUsed !== undefined && props.dataUsed.datasets !== undefined) {
            setSelected(props.dataUsed.datasets)
        }
    }, [props.dataUsed]);

    useEffect(() => {
        if (selected == null) {
            setChart(props.children)
        } else {
            const selectedData = {datasets: selected}
            switch (props.chartType) {
                case ChartType.Line:
                    setChart(<Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                   data={selectedData} options={props.chartOptions}/>)
                    break;
                case ChartType.Bar:
                    setChart(<Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                                  data={selectedData} options={props.chartOptions}/>)
                    break;
            }
        }

    }, [selected, props.children]);

    function getColor(currColor: string, item: any, type: string) {
        if (selected.includes(item)) {
            return currColor
        } else {
            if (type === "bg") {
                return "transparent"
            } else {
                return "rgb(30 41 59)"
            }
        }
    }

    let showLegend = props.hideLegend === undefined ? true : !props.hideLegend;
    // handle edge cases for showing legend
    if (props.dataUsed && props.dataUsed.datasets) {
        if (props.dataUsed.datasets.length === 0) {
            showLegend = false
        } else if (props.dataUsed.datasets.length === 1 && props.dataUsed.datasets[0].label == "") {
            showLegend = false
        }
    }

    return (
        <div className={cn("flex flex-col justify-center min-w-0 min-h-0 grow shrink", props.className)}>
            <div className={"flex justify-center min-w-0 min-h-0 grow shrink"}>
                <div className={"flex-none flex flex-col min-w-0 min-h-0 grow shrink"}>
                    {((props.title !== undefined && props.title !== "") || (props.editDialog)) &&
                        <div
                            className={cn("min-w-0 min-h-0 h-[24px] bg-backgroundmedium border rounded-t text-sm font-medium text-textmedium leading-[14px] px-2 py-1 truncate flex justify-between draggablehandle", props.styling?.borderless ? "border-none" : "")}>
                            {
                                props.title !== undefined && props.title !== "" &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.title}
                                </div>
                            }
                            {
                                props.editDialog &&
                                <div className={"flex flex-col justify-center"}>
                                    {props.editDialog}
                                </div>
                            }
                        </div>
                    }
                    <div
                        className={cn((props.title !== undefined && props.title !== "") ? "border-b border-l border-r rounded-b" : "border rounded", "p-1 flex flex-col min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium", props.styling?.borderless ? "border-none" : "", props.className)}
                    >
                        <div
                            className={"p-1 flex min-h-0 min-w-0 grow shrink justify-center hover:cursor-crosshair bg-backgroundmedium border-none"}>
                            {chart}
                        </div>
                        {props.dataUsed && props.dataUsed.datasets && showLegend &&
                            <div id="legend-container"
                                 className={"min-h-[40px] max-h-[80px] overflow-y-auto ml-4 my-2"}>
                                <ul>
                                    {/* Sort by the biggest to smallest*/}
                                    {props.dataUsed.datasets.sort((a: any, b: any) => {
                                        let totalA = a.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        let totalB = b.data.reduce((acc: number, curr: any) => {
                                            return acc + curr.y
                                        }, 0)
                                        return totalB - totalA
                                    }).map((item: any, index: number) => {
                                        if (item.label === "") {
                                            return null;
                                        }
                                        return (<li key={index}
                                                    className={"flex items-center hover:cursor-pointer hover:bg-backgroundlight hover:text-textlight text-textmedium"}
                                        >
                                        <span className={"border mr-1 w-[13px] h-[13px] inline-block hover:border-2"}
                                              onClick={() => {
                                                  if (selected.includes(item)) {
                                                      setSelected(selected.filter((d: any) => {
                                                          return d !== item
                                                      }))
                                                  } else {
                                                      setSelected([...selected, item])
                                                  }
                                              }}
                                              style={{
                                                  backgroundColor: getColor(item.backgroundColor, item, "bg"),
                                                  borderColor: getColor(item.borderColor, item, "border")
                                              }}></span>
                                            <div className={cn("text-xs")}
                                                 onClick={() => {
                                                     if (selected.length === 1 && selected.includes(item)) {
                                                         setSelected(props.dataUsed.datasets)
                                                     } else {
                                                         setSelected([item])
                                                     }
                                                 }}
                                            >{item.label}</div>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


Chart.register(zoomPlugin, BarController, PointElement, LineElement, Legend, TimeScale, BarElement, LinearScale, CategoryScale, Title, Tooltip, ChartDataLabels, annotationPlugin);

function MetoroChart(props: {
    hideOnNoData?: boolean,
    editDialog?: ReactElement,
    className?: string,
    isDuration?: boolean,
    type: ChartType,
    dataToUse: any,
    title?: string,
    styling?: ChartStyling,
    annotations?: any[],
    hideLegend?: boolean
}) {
    const dispatch = useDispatch();

    if (props.dataToUse === undefined || props.dataToUse.datasets.length === 0) {
        if (props.hideOnNoData) {
            return null;
        }
        return <ChartContainer styling={props.styling} className={props.className}
                               children={
                                   <div className={"flex justify-center w-full h-full"}>
                                       <div className={"flex flex-col justify-center"}>
                                           <div className={"flex justify-center text-textmedium text-sm"}>
                                               No data to display
                                           </div>
                                       </div>
                                   </div>
                               }
                               title={props.title}></ChartContainer>
    }

    const options =
        {
            maintainAspectRatio: false,
            color: "#C6D3E2",
            plugins: {
                annotation: {
                    annotations: props.annotations ? props.annotations : []
                },
                zoom: {
                    enabled: true,
                    mode: 'x',
                    zoom: {
                        mode: 'x',
                        onZoomComplete: function ({chart}: any) {
                            const {min, max} = chart.scales.x;
                            let timeRange = new TimeRange(undefined, new Date(min), new Date(max));
                            dispatch(set(new TimeRange(undefined, new Date(min), new Date(max))))
                        },
                        wheel: {
                            enabled: false
                        },
                        pinch: {
                            enabled: false
                        },
                        drag: {
                            enabled: true,
                            backgroundColor: 'rgba(255, 99, 132, 0.2)',
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 1,
                        },
                    },
                    pan: {
                        enabled: false,
                        mode: 'x'
                    }
                },
                tooltip: {
                    intersect: false,
                    borderColor: "#334670",
                    borderWidth: 1,
                    cornerRadius: 2,
                    backgroundColor: "#1D273F",
                    titleColor: "#C6D3E2",
                    bodyColor: "#C6D3E2",
                    titleAlign: "center",
                    bodyAlign: "center",
                    displayColors: false,
                    padding: 8,
                },
                datalabels: {
                    display: false,
                },
                legend: {
                    display: false, // we use custom HTML Legends
                },
            },
            responsive: true,
            scales: {
                x: {
                    grid: {
                        display: false,
                        color: "#334670"
                    },
                    time: {
                        displayFormats: {
                            minute: 'HH:mm:ss',
                            second: 'HH:mm:ss',
                            hour: 'HH:mm:ss',
                            day: 'dd HH:mm:ss',
                        },
                    },
                    ticks: {
                        color: "#C6D3E2",
                        autoSkip: true,
                        maxTicksLimit: 4,
                    },
                    type: 'time',
                    stacked: props.type === ChartType.Bar,
                },
                y: {
                    border: {
                        display: false,
                    },
                    display: true,
                    grid: {
                        drawTicks: true,
                        color: "#334670"
                    },
                    ticks: {
                        callback: function (value: number) {
                            return props.isDuration ? shortEnglishHumanizer(value) : value;
                        },
                        color: "#C6D3E2",
                        maxTicksLimit: 5,
                        autoSkip: true,
                    },
                    stacked: props.type === ChartType.Bar,
                    beginAtZero: true,
                }
            }
        }


    return <ChartContainer editDialog={props.editDialog} styling={props.styling} className={props.className}
                           dataUsed={props.dataToUse}
                           chartType={props.type}
                           chartOptions={options}
                           hideLegend={props.hideLegend}
                           title={props.title} children={
        (() => {
            switch (props.type) {
                case ChartType.Line:
                    return <Line height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                        // @ts-ignore
                                 data={props.dataToUse} options={options}/>
                case ChartType.Bar:
                    return <Bar height={"0vh"} width={"0vw"} className={"absolute flex grow shrink min-h-0 min-w-0"}
                        // @ts-ignore
                                data={props.dataToUse} options={options}/>
            }
        })()
    }/>
}

interface ChartStyling {
    borderless?: boolean;
}

interface MetoroMetricsChartProps {
    // The epoch time in seconds of the start of the time range
    startTime: number;
    // The epoch time in seconds of the end of the time range
    endTime: number;
    // The name of the metric
    metricName: string;
    // The filters to apply to the metric
    filters?: Map<string, string[]>;
    // The filters to exclude from the metric
    excludeFilters?: Map<string, string[]>;
    // The splits to apply to the metric
    splits?: string[];
    // The aggregation to apply to the metric
    aggregation: string;
    // If the metric should be a rate of change
    isRate?: boolean;
    // The title of the chart
    title?: string;
    // The type of the chart
    type: ChartType;
    // Hide on no data
    hideOnNoData?: boolean;
    // Extra styling options for the chart
    styling?: ChartStyling;
    // Class name overrides for the chart container on the inside
    className?: string;
    // threshold if defined
    threshold?: Threshold;
    // Type of the metric to show, could be gauge or trace
    metricType: MetricType;
    // hide the legend
    hideLegend?: boolean;
    // Function to apply to the metric, the functions will be applied the same order it appears in the array.
    functions: MetricFunction[];
}

export interface Threshold {
    value: string;
    comparator?: ThresholdComparator;
}

async function updateChartMetrics(props: MetoroMetricsChartProps, setMetric: (value: (((prevState: (Metric | undefined)) => (Metric | undefined)) | Metric | undefined)) => void) {
    let filters = props.filters;
    if (filters === undefined) {
        filters = new Map<string, string[]>();
    }
    let splits = props.splits;
    if (splits === undefined) {
        splits = [];
    }

    try {
        if (props.metricType === MetricType.Trace) {
            const request = {
                startTime: props.startTime,
                endTime: props.endTime,
                filters: props.filters,
                excludeFilters: props.excludeFilters,
                splits: props.splits,
                aggregate: props.aggregation,
                functions: props.functions
            };
            // Request the Traces endpoint
            const awaitedTraceMetrics = await GetTraceMetric(request);
            setMetric(awaitedTraceMetrics.metric)
        } else {
            const request = {
                metricName: props.metricName,
                startTime: props.startTime,
                endTime: props.endTime,
                filters: filters,
                excludeFilters: props.excludeFilters,
                splits: splits,
                aggregation: props.aggregation,
                isRate: props.isRate ? props.isRate : false,
                functions: props.functions
            };
            const awaitedMetrics = await GetMetric(request);
            setMetric(awaitedMetrics.metric);
        }
    } catch (e) {
        console.error(e);
    }
}

function MetoroMetricsChart(props: MetoroMetricsChartProps) {
    const [metric, setMetric] = React.useState<Metric>();
    const debouncedUpdateChartMetrics = useDebouncedCallback(updateChartMetrics, 10);

    let annotations: any[] = [];

    function getLineStyleForThreshold(threshold: Threshold): any {
        if (threshold.comparator != undefined && threshold.comparator == ThresholdComparator.GreaterThan || threshold.comparator == ThresholdComparator.LessThan) {
            return [5, 5]
        } else {
            return [];
        }
    }

    if (props.threshold != undefined && props.threshold.value != undefined) {
        // single line on the threshold
        annotations.push({
            type: 'line',
            mode: 'horizontal',
            yMin: props.threshold.value,
            yMax: props.threshold.value,
            borderColor: 'rgb(239 68 68)',
            borderWidth: 2,
            borderDash: getLineStyleForThreshold(props.threshold),
            label: {
                enabled: false,
                content: 'Test label'
            }
        });
        // shaded area on the threshold
        if (props.threshold.comparator != undefined) {
            if (props.threshold.comparator == ThresholdComparator.GreaterThan || props.threshold.comparator == ThresholdComparator.GreaterThanOrEqual) {
                annotations.push({
                    type: 'box',
                    yMin: props.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0,
                })
            } else if (props.threshold.comparator == ThresholdComparator.LessThan || props.threshold.comparator == ThresholdComparator.LessThanOrEqual) {
                annotations.push({
                    type: 'box',
                    yMax: props.threshold.value,
                    backgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderWidth: 0,
                })
            }
        }
    }

    useEffect(() => {
        debouncedUpdateChartMetrics(props, setMetric);
    }, [props.metricName, props.startTime, props.endTime, props.filters, props.excludeFilters, props.splits, props.aggregation, props.type, props.title, props.metricType, props.functions]);

    const durationAggregations = ["p50", "p90", "p95", "p99"];
    const isTime = durationAggregations.includes(props.aggregation);

    const dataToUse = MetricToChartData(metric, isTime);
    return (
        <MetoroChart className={props.className} hideOnNoData={props.hideOnNoData} styling={props.styling}
                     type={props.type} dataToUse={dataToUse} title={props.title} annotations={annotations}
                     hideLegend={props.hideLegend} isDuration={isTime}/>
    );
}


export {
    MetricToChartData,
    GetAllTraceMetricsResponse,
    GetLogMetricsResponse,
    GetLogMetrics,
    GetAllTraceMetrics,
    GetTraceMetric,
    MetoroMetricsChart,
    GetMetric,
    GetAggregateMetricEvaluation,
    MetricsTest,
    MetoroChart, ChartType, MetricType
};
export type {MetoroMetricsChartProps, Metric};
