import React, {useEffect, useState} from "react";
import {Trace} from "../../../pages/Traces";
import {TraceIdTitle, TraceRequestResultDetails} from "./TraceTitle";
import {extractAPIPath, fillMissingEntry, getMessageForStatusCode} from "./utils";
import {TraceChart} from "./TraceChart";
import axios from "utility/customAxios";
import {AxiosPromise} from "axios";

// The Trace type should be renamed imo to Span but will do that later.
export type Span = Trace

class SpansResponse {
    traces: Span[]

    constructor(spans: Span[]) {
        this.traces = spans;
    }
}

function TracePanelContent(props: {
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void,
    trace: Trace
}) {
    const [spans, setSpans] = useState<Span[]>([]);
    const [traceId, setTraceId] = useState<string>(props.trace.traceId);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const d: AxiosPromise<SpansResponse> = axios.post("/api/v1/spans", {
                        "startTime": Math.trunc(props.trace.time / 1000),
                        "endTime": Math.trunc((props.trace.time + props.trace.duration) / 1000),
                        "traceId": props.trace.traceId,
                        "shouldReturnNonMetoroEpbfSpans": true
                    }
                )
                const awaited = (await d).data;
                setSpans(awaited.traces)
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [traceId]);

    return <div data-vaul-no-drag
                className="h-full p-4 bg-backgrounddark shadow flex-col justify-start items-center gap-8 inline-flex border-l">
        <div className={"w-full flex justify-between items-end"}>
            <TraceIdTitle traceId={props.trace.traceId}
                          serviceName={props.trace.displayServiceName}
                          method={fillMissingEntry(props.trace.spanAttributes["http.method"])}
                          path={extractAPIPath(props.trace.spanAttributes["http.url"])}/>
            <div className={"w-full flex items-end"}>
                <TraceRequestResultDetails
                    datetime={props.trace.time}
                    statusCode={fillMissingEntry(props.trace.spanAttributes["http.status_code"])}
                    duration={props.trace.duration}
                    statusMessage={getMessageForStatusCode(props.trace.spanAttributes["http.status_code"])}/>
            </div>
        </div>
        <div className={"w-full"}>
            <TraceChart
                filter={props.filter}
                setFilter={props.setFilter}
                excludeFilter={props.excludeFilter}
                setExcludeFilter={props.setExcludeFilter}
                spans={spans}/>
        </div>
    </div>
}

// TODO: This example span can be deleted, its just useful for development of the span graph:
const example_spans: Span[] = [
    {
        time: 1000,
        duration: 10000,
        traceId: "36323932373163616163306139663932",
        spanId: "topLevelSpanId",
        parentSpanId: "0000000000000000",
        displayServiceName: "ServerX",
        displayClientName: "ClientY",
        clientName: "ClientY",
        spanAttributes: {
            "http.method": "POST",
            "http.status_code": "200",
            "http.url": "http://192.168.30.7:9090/api/v1/query_range",
            "net.peer.name": "192.168.30.7",
            "net.peer.port": "9090",
            "otel.scope.name": "coroot-node-agent",
            "otel.scope.version": "1.19.1",
            "server.service.name": "/k8s/coroot/coroot-connect"
        },
        resourceAttributes: {
            "container.id": "/k8s/coroot/coroot-connect-84b8b9bbdb-lvdh9/connect",
            "host.id": "ec23818279058e7ff3dbcfb69648316f",
            "host.name": "ip-192-168-46-89.ec2.internal",
            "server.service.name": "/k8s/coroot/coroot-connect"
        },
        serviceName: "Coroot-Connect"
    },
    {
        time: 2000,
        duration: 3000,
        traceId: "36323932373163616163306139663932",
        spanId: "secondLevelSpanId1",
        parentSpanId: "topLevelSpanId",
        displayServiceName: "ServerX",
        displayClientName: "ClientY",
        clientName: "ClientY",
        spanAttributes: {
            "http.method": "POST",
            "http.status_code": "200",
            "http.url": "/data",
            "net.peer.name": "somethingelse",
            "net.peer.port": "somethingelse",
            "otel.scope.name": "somethingelse",
            "otel.scope.version": "somethingelse",
            "server.service.name": "somethingelse"
        },
        resourceAttributes: {
            "container.id": "somethingelse",
            "host.id": "somethingelse",
            "host.name": "isomethingelse",
            "server.service.name": "somethingelse"
        },
        serviceName: "somethingelse"
    },
    {
        time: 5000,
        duration: 5000,
        traceId: "36323932373163616163306139663932",
        spanId: "secondLevelSpan2",
        parentSpanId: "topLevelSpanId",
        displayServiceName: "ServerX",
        displayClientName: "ClientY",
        clientName: "ClientY",
        spanAttributes: {
            "http.method": "POST",
            "http.status_code": "200",
            "http.url": "/range",
            "net.peer.name": "somethingelse",
            "net.peer.port": "somethingelse",
            "otel.scope.name": "somethingelse",
            "otel.scope.version": "somethingelse",
            "server.service.name": "somethingelse"
        },
        resourceAttributes: {
            "container.id": "somethingelse",
            "host.id": "somethingelse",
            "host.name": "isomethingelse",
            "server.service.name": "somethingelse"
        },
        serviceName: "somethingelse"
    },
    {
        time: 8000,
        duration: 2000,
        traceId: "36323932373163616163306139663932",
        spanId: "thirLevelSpan",
        parentSpanId: "secondLevelSpan2",
        displayServiceName: "Ringo",
        displayClientName: "Star",
        clientName: "Star",
        spanAttributes: {
            "http.method": "POST",
            "http.status_code": "200",
            "http.url": "/api/v1/query",
            "net.peer.name": "wrf",
            "net.peer.port": "4012",
            "otel.scope.name": "ringo",
            "otel.scope.version": "ringo",
            "server.service.name": "/api/v1/query"
        },
        resourceAttributes: {
            "container.id": "ec23818279058e7ff3dbcfb69648316f",
            "host.id": "aws-ec2-1",
            "host.name": "aws-ec2-1",
            "server.service.name": "ringo"
        },
        serviceName: "somethingelse"
    }
];

export default TracePanelContent;
