import {BaseView} from "./BaseView";
import React, {useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {AxiosPromise} from "axios";
import axios from "../utility/customAxios";
import {Attribute} from "../types/telemetry";
import {plainToClassFromExist} from "class-transformer";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {TraceLevelFilters, TracesSearch, TracesSummaryResponse} from "./Traces";
import {LoadingSpinner} from "../components/ui/customSpinner";
import {FilterPanel} from "../components/Filter/Filter";
import Dagre from '@dagrejs/dagre';
import {useDebouncedCallback} from 'use-debounce';
import {
    Background,
    BackgroundVariant,
    BaseEdge,
    EdgeLabelRenderer,
    EdgeProps,
    getBezierPath,
    Handle,
    Position,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useReactFlow
} from "reactflow";

import 'reactflow/dist/style.css';
import humanFormat from "human-format";
import {FaExternalLinkAlt, FaLongArrowAltRight} from "react-icons/fa";
import {cn, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {ReactFlowStyled} from "./Onboarding";
import {TimeRange} from "../types/time";
import Scale = humanFormat.Scale;


interface ServiceMapProps {
    justTraceMap?: boolean;
}

interface TraceService {
    serviceName: string;
}

interface Endpoint {
    endpointName: string;
    protocol?: string;
    p50?: number;
    p90?: number;
    p95?: number;
    p99?: number;
    requestCount: number;
    fiveXXCount: number;
    fourXXCount: number;
    twoXXCount: number;
}

interface Edge {
    from: string;
    to: string;
    endpoints: Endpoint[];
}

interface ServiceGraphResponse {
    services: TraceService[];
    edges: Edge[];
}


async function updateServiceGraph(setIsServiceGraphLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, debouncedFilter: Map<string, string[]>, debouncedExcludeFilter: Map<string, string[]>, timeRange: TimeRange, debouncedRegexes: string[], debouncedExcludeRegexes: string[], environments: string[], setServiceGraph: (value: (((prevState: (ServiceGraphResponse | null)) => (ServiceGraphResponse | null)) | ServiceGraphResponse | null)) => void) {
    try {
        setIsServiceGraphLoading(true)
        const filters = Object.fromEntries(debouncedFilter);
        const excludeFilters = Object.fromEntries(debouncedExcludeFilter);
        const startEnd = timeRange.getStartEnd();
        const d: AxiosPromise<ServiceGraphResponse> = axios.post("/api/v1/serviceGraph", {
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "excludeFilters": excludeFilters,
                "filters": filters,
                "regexes": debouncedRegexes,
                "excludeRegexes": debouncedExcludeRegexes,
                "environments": environments[0] === "" ? [] : environments

            }
        )
        const awaited = (await d).data
        setServiceGraph(awaited)
        setIsServiceGraphLoading(false)
    } catch (e) {
        setIsServiceGraphLoading(false)
        console.error(e);
    }
}

async function updateTraces(setIsLoadingFilters: (value: (((prevState: boolean) => boolean) | boolean)) => void, filter: Map<string, string[]>, excludeFilter: Map<string, string[]>, timeRange: TimeRange, regexes: string[], excludeRegexes: string[], environments: string[], setFilterAttributes: (value: (((prevState: Map<string, Attribute[]>) => Map<string, Attribute[]>) | Map<string, Attribute[]>)) => void) {
    try {
        setIsLoadingFilters(true)
        const filters = Object.fromEntries(filter);
        const excludeFilters = Object.fromEntries(excludeFilter);
        const startEnd = timeRange.getStartEnd();
        const d: AxiosPromise<TracesSummaryResponse> = axios.post("/api/v1/tracesSummary", {
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "excludeFilters": excludeFilters,
                "filters": filters,
                "regexes": regexes,
                "excludeRegexes": excludeRegexes,
                "environments": environments[0] === "" ? [] : environments
            }
        )
        const awaited = (await d).data.attributes;
        let awaitedMap = new Map<string, Attribute[]>();
        plainToClassFromExist(awaitedMap, awaited)
        setFilterAttributes(awaitedMap)
        setIsLoadingFilters(false)
    } catch (e) {
        setIsLoadingFilters(false)
        console.error(e);
    }
}

export const ServiceMap = (props: ServiceMapProps) => {
    const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(true);
    const [searchParams, setSearchParams] = useSearchParams()
    const [filter, setFilter] = useState<Map<string, string[]>>(() => {
        let filterJson = searchParams.get("filter") || "";
        if (filterJson !== "") {
            return new Map(Object.entries(JSON.parse(filterJson)))
        }
        return new Map()
    });
    const [excludeFilter, setExcludeFilter] = useState<Map<string, string[]>>(() => {
        let filterJson = searchParams.get("excludeFilter") || "";
        if (filterJson !== "") {
            return new Map(Object.entries(JSON.parse(filterJson)))
        }
        return new Map()
    });
    const [regexes, setRegexes] = useState<string[]>(() => {
            let filterJson = searchParams.get("regexes") || "";
            if (filterJson !== "") {
                return JSON.parse(filterJson)
            }
            return []
        }
    )
    const [excludeRegexes, setExcludeRegexes] = useState<string[]>(
        () => {
            let filterJson = searchParams.get("excludeRegexes") || "";
            if (filterJson !== "") {
                return JSON.parse(filterJson)
            }
            return []
        }
    )
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [filterAttributes, setFilterAttributes] = useState(new Map<string, Attribute[]>());
    const [serviceGraph, setServiceGraph] = useState<ServiceGraphResponse | null>(null);
    const [isServiceGraphLoading, setIsServiceGraphLoading] = useState<boolean>(true);
    const [environments, setEnvironments] = useState<string[]>([]);

    const debouncedUpdateServiceGraph = useDebouncedCallback(updateServiceGraph, 10)
    const deboucedUpdateTrace = useDebouncedCallback(updateTraces, 10)

    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null) {
            setEnvironments([environments])
        }
    }, [searchParams])


    useEffect(() => {
        let filterJson = searchParams.get("regexes") || "";
        if (filterJson !== "") {
            setRegexes(JSON.parse(filterJson))
        }
        filterJson = searchParams.get("excludeRegexes") || "";
        if (filterJson !== "") {
            setExcludeRegexes(JSON.parse(filterJson))
        }
    }, [searchParams])


    useEffect(() => {
        let filterJson = searchParams.get("excludeFilter") || "";
        if (filterJson !== "") {
            const excludeFilterMap = new Map<string, string[]>();
            const excludeFilterObject = JSON.parse(filterJson);
            for (const [key, value] of Object.entries(excludeFilterObject)) {
                excludeFilterMap.set(key, value as string[])
            }
            setExcludeFilter(excludeFilterMap)
        }
        filterJson = searchParams.get("filter") || "";
        if (filterJson !== "") {
            const filterMap = new Map<string, string[]>();
            const filterObject = JSON.parse(filterJson);
            for (const [key, value] of Object.entries(filterObject)) {
                filterMap.set(key, value as string[])
            }
            setFilter(filterMap)
        }
    }, [searchParams])

    useEffect(() => {
        if (props.justTraceMap) {
            // In the case where it's just the trace feed, we don't need the metrics
            return;
        }
        debouncedUpdateServiceGraph(setIsServiceGraphLoading, filter, excludeFilter, timeRange, regexes, excludeRegexes, environments, setServiceGraph);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);


    useEffect(() => {
        // Get the service map data
        deboucedUpdateTrace(setIsLoadingFilters, filter, excludeFilter, timeRange, regexes, excludeRegexes, environments, setFilterAttributes);
    }, [filter, excludeFilter, regexes, excludeRegexes, timeRange, environments]);

    let setFilterUrlParams = (filter: Map<string, string[]>) => setSearchParams(prev => {
        let existing = new URLSearchParams(window.location.search)
        existing.set("filter", JSON.stringify(Object.fromEntries(filter)))
        return existing
    })

    let setExcludeFilterUrlParams = (filter: Map<string, string[]>) => setSearchParams(prev => {
        let existing = new URLSearchParams(window.location.search)
        existing.set("excludeFilter", JSON.stringify(Object.fromEntries(filter)))
        return existing
    })

    let setRegexesUrlParams = (regexes: string[]) => setSearchParams(prev => {
        let existing = new URLSearchParams(window.location.search)
        existing.set("regexes", JSON.stringify(regexes))
        return existing
    })

    let setExcludeRegexesUrlParams = (regexes: string[]) => setSearchParams(prev => {
        let existing = new URLSearchParams(window.location.search)
        existing.set("excludeRegexes", JSON.stringify(regexes))
        return existing
    })


    return <BaseView title={"Service Map"}>
        <div className={"w-full min-w-0 min-h-0 flex justify-between grow shrink p-4"}>
            <div className={"flex flex-none relative"}>
                {isLoadingFilters && <LoadingSpinner className={`absolute top-1/2 left-1/2 z-40"}`}/>}
                <FilterPanel
                    initiallyOpenFilterKeys={["server.service.name"]}
                    attributes={filterAttributes} setFilter={setFilterUrlParams}
                             filter={filter}
                             telemetryFiltersComponent={
                                 <TraceLevelFilters filter={filter} setFilter={setFilterUrlParams}
                                                    attributes={filterAttributes}
                                                    excludeFilter={excludeFilter}
                                                    setExcludeFilter={setExcludeFilterUrlParams}
                                 />
                             }
                             filteringCriteria={"http.status_code"}
                             setExcludeFilter={setExcludeFilter}
                             excludeFilter={excludeFilter}

                />
            </div>
            <div className={"pl-4 min-w-0 min-h-0 grow shrink relative flex flex-col"}>
                <TracesSearch
                    filter={filter} setFilter={setFilterUrlParams}
                    excludeFilter={excludeFilter} setExcludeFilter={setExcludeFilterUrlParams}
                    regexes={regexes} setRegexes={setRegexesUrlParams}
                    excludeRegexes={excludeRegexes} setExcludeRegexes={setExcludeRegexesUrlParams}
                />
                {serviceGraph &&
                    <ReactFlowProvider>
                        <ServiceMapInner serviceMap={serviceGraph}/>
                    </ReactFlowProvider>
                }
            </div>
        </div>
    </BaseView>
}


interface NodesAndEdges {
    nodes: any[]
    edges: any[]
}

const getLayoutedElements = (nodes: any, edges: any, options: any) => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setGraph({rankdir: options.direction});

    edges.forEach((edge: any) => {
        g.setEdge(edge.source, edge.target)
    });
    nodes.forEach((node: any) =>
        g.setNode(node.id, {
            ...node,
            width: node.measured?.width ?? 256,
            height: node.measured?.height ?? 64,
        }),
    );

    Dagre.layout(g);

    return {
        nodes: nodes.map((node: any) => {
            const position = g.node(node.id);
            // We are shifting the dagre node position (anchor=center center) to the top left
            // so it matches the React Flow node anchor point (top left).
            const x = position.x - (node.measured?.width ?? 0) / 2;
            const y = position.y - (node.measured?.height ?? 0) / 2;

            return {...node, position: {x, y}};
        }),
        edges,
    };
};

function createNodesAndEdges(serviceMap: ServiceGraphResponse): NodesAndEdges {
    let nodes: any[] = []
    let edges: any[] = []

    serviceMap.services = serviceMap.services.sort((a, b) => a.serviceName.localeCompare(b.serviceName))
    serviceMap.edges = serviceMap.edges.sort((a, b) => a.from.localeCompare(b.from))

    for (const service of serviceMap.services) {
        if (service.serviceName === "") {
            continue
        }

        let inboundEdges = serviceMap.edges.filter((edge) => edge.to === service.serviceName)
        let outboundEdges = serviceMap.edges.filter((edge) => edge.from === service.serviceName)

        nodes.push({
            id: service.serviceName,
            type: 'serviceNode',
            data: {
                serviceName: service.serviceName,
                inboundEdges: inboundEdges,
                outboundEdges: outboundEdges
            },
            position: {x: 0, y: 0},
            sourcePosition: 'right',
            targetPosition: 'left',
            style: {background: "#f5f5f5", color: "#000000", border: "1px solid #000000", borderRadius: "10px"}
        })
    }

    for (const edge of serviceMap.edges) {
        if (edge.from === "" || edge.to === "") {
            continue
        }
        edges.push({
            id: `${edge.from}-${edge.to}`,
            type: 'serviceEdge',
            source: edge.from,
            target: edge.to,
            animated: true,
            data: {
                from: edge.from,
                to: edge.to,
                endpoints: edge.endpoints,
            },
        })
    }

    const layouted = getLayoutedElements(nodes, edges, {direction: "LR"})

    return {
        nodes: layouted.nodes,
        edges: layouted.edges,
    }
}

function ServiceMapInner(props: { serviceMap: ServiceGraphResponse }) {
    const {fitView} = useReactFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState(createNodesAndEdges(props.serviceMap).nodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(createNodesAndEdges(props.serviceMap).edges);
    const nodeTypes = useMemo(() => ({serviceNode: ServiceNode}), []);
    const edgeTypes = useMemo(() => ({serviceEdge: ServiceEdge}), []);


    useEffect(() => {
        setNodes(createNodesAndEdges(props.serviceMap).nodes)
        setEdges(createNodesAndEdges(props.serviceMap).edges)
        setTimeout(() => {
            window.requestAnimationFrame(() => {
                fitView();
            })
        }, 50)
    }, [props.serviceMap])

    return <div className={"mt-4 flex flex-col grow shrink border bg-backgroundmedium rounded "}>
        <ReactFlowStyled
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            fitView
            proOptions={{hideAttribution: true}}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            edgesUpdatable={false}
            edgesFocusable={false}
            nodesDraggable={true}
            nodesConnectable={false}
            nodesFocusable={false}
            draggable={false}
            elementsSelectable={false}
            maxZoom={1.0} minZoom={0.1}
            nodes={nodes}
            edges={edges}>
            <Background gap={32} color="#ccc" variant={BackgroundVariant.Dots}/>
        </ReactFlowStyled>
    </div>
}


const handleStyle = {left: 10};


function calculateEdgeColor(endpoints: any) {
    for (let endpoint of endpoints) {
        if (endpoint.fiveXXCount > 0) {
            return "#ff0000"
        }
    }
    return "#2DD881"
}

function ProtocolContainer(props: {
    from: string,
    to: string
    endpoints: Endpoint[]
}) {
    let timeRange = useSelector(timerange.selectors.getTimeRange)

    let protocol = props.endpoints.reduce((acc: string[], endpoint: Endpoint) => {
        if (endpoint.protocol === undefined) {
            return acc
        }
        if (acc.includes(endpoint.protocol)) {
            return acc
        }
        return [...acc, endpoint.protocol]
    }, []).join(", ")

    if (protocol === "") {
        protocol = "unknown"
    }

    let tracesUrl = `/traces?filter=${JSON.stringify({"client.service.name": [props.from], "server.service.name": [props.to]})}`


    return <div className={"text-textmedium group"}>
        <div className={"block group-hover:hidden"}>
            {protocol}
        </div>
        <div className={"hidden group-hover:block py-2 max-h-[360px] overflow-y-auto cursor-default"}>
            <div className={"flex gap-1 text-lg mb-2"}>
                <div>{getServiceName(props.from)}</div>
                <div className={"text-primary flex flex-col items-center justify-center"}><FaLongArrowAltRight/></div>
                <div>{getServiceName(props.to)}</div>
            </div>
            <StatsBlock url={tracesUrl} endpoints={props.endpoints} title={"Total"}/>
            {
                props.endpoints.sort(
                    (a, b) => b.requestCount - a.requestCount
                ).map((endpoint: Endpoint) => {
                    const filterBy = {"client.service.name": [props.from], "server.service.name": [props.to]}
                    let tracesUrl = `/traces?filter=${JSON.stringify(filterBy)}&regexes=${JSON.stringify([endpoint.endpointName])}`
                    return <div className={"mt-2"}><StatsBlock url={tracesUrl} title={endpoint.endpointName}
                                                               endpoints={[endpoint]}/>
                    </div>
                })
            }
        </div>
    </div>
}

function StatsBlock(props: {
    endpoints: Endpoint[]
    title: string
    url?: string
}) {
    let timeRange = useSelector(timerange.selectors.getTimeRange)
    let totalRequests = props.endpoints.reduce((acc: number, endpoint: Endpoint) => {
        return acc + endpoint.requestCount
    }, 0)
    const navigate = usePreserveQueryParamsNavigate();

    let startEnd = timeRange.getStartEnd();
    let numSeconds = (startEnd[1].getTime() - startEnd[0].getTime()) / 1000
    let requestsPerSecond = humanFormat(totalRequests / numSeconds, {
        unit: "/s",
        scale: new Scale({
            "": 1,
            "K": 1000,
            "M": 1000000,
            "B": 1000000000,
        })
    })

    let totalErrors = props.endpoints.reduce((acc: number, endpoint: Endpoint) => {
        return acc + endpoint.fiveXXCount
    }, 0)

    let errorRate = totalRequests !== 0 ? humanFormat(totalErrors / totalRequests * 100,
        {
            unit: "%",
            scale: new Scale({
                "": 1,
            })
        }) : 0

    let fourXXErrors = props.endpoints.reduce((acc: number, endpoint: Endpoint) => {
        return acc + endpoint.fourXXCount
    }, 0)

    let fourXXErrorRate = totalRequests !== 0 ? humanFormat(fourXXErrors / totalRequests * 100,
        {
            unit: "%",
            scale: new Scale({
                "": 1,
            })
        }) : 0

    let weightedP50 = 0
    let weightedP90 = 0
    let weightedP99 = 0
    let totalWeight = 0
    for (let endpoint of props.endpoints) {
        let weight = endpoint.requestCount
        totalWeight += weight
        weightedP50 += (endpoint.p50 ?? 0) * weight
        weightedP90 += (endpoint.p90 ?? 0) * weight
        weightedP99 += (endpoint.p99 ?? 0) * weight
    }
    weightedP50 = totalWeight !== 0 ? weightedP50 / totalWeight : 0
    weightedP90 = totalWeight !== 0 ? weightedP90 / totalWeight : 0
    weightedP99 = totalWeight !== 0 ? weightedP99 / totalWeight : 0
    weightedP50 = weightedP50 / 1_000_000
    weightedP90 = weightedP90 / 1_000_000
    weightedP99 = weightedP99 / 1_000_000

    let p50 = humanFormat(weightedP50, {
        unit: "s",
        decimals: 1
    })
    let p90 = humanFormat(weightedP90, {
        unit: "s",
        decimals: 1
    })
    let p99 = humanFormat(weightedP99, {
        unit: "s",
        decimals: 1
    })

    return <div>
        <div className={"flex justify-between gap-2"}>
            <div className={"font-bold mb-2"}>{props.title}</div>
            {props.url &&
                <div
                    className={"hover:cursor-pointer"}
                    onClick={() => {
                        navigate(props.url!)
                    }}
                ><FaExternalLinkAlt className={"text-primary"}/></div>
            }
        </div>
        <div className={"grid grid-cols-3 border rounded divide-x"}>
            <div className={"p-1"}>
                <div className={"text-center font-bold"}>
                    Requests
                </div>
                <div className={"text-center"}>
                    {requestsPerSecond}
                </div>
            </div>
            <div className={"p-1"}>
                <div className={"text-center font-bold"}>
                    5XXs
                </div>
                <div className={cn("text-center", totalErrors > 0 ? "text-red-500" : "")}>
                    {errorRate}
                </div>
            </div>
            <div className={"p-1"}>
                <div className={"text-center font-bold"}>
                    4XXs
                </div>
                <div className={"text-center"}>
                    {fourXXErrorRate}
                </div>
            </div>
        </div>

        <div className={"grid grid-cols-3 divide-x border-r border-l border-b rounded"}>
            <div className={"p-1"}>
                <div className={"text-center font-bold"}>
                    P50
                </div>
                <div className={"text-center"}>
                    {p50}
                </div>
            </div>
            <div className={"p-1"}>
                <div className={"text-center font-bold"}>
                    P90
                </div>
                <div className={"text-center"}>
                    {p90}
                </div>
            </div>
            <div className={"p-1"}>
                <div className={"text-center font-bold"}>
                    P99
                </div>
                <div className={"text-center"}>
                    {p99}
                </div>
            </div>
        </div>
    </div>
}

function ServiceEdge({
                         data,
                         id,
                         sourceX,
                         sourceY,
                         targetX,
                         targetY,
                         sourcePosition,
                         targetPosition,
                     }: EdgeProps) {
    const {setEdges} = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX: sourceX,
        sourceY: sourceY,
        sourcePosition: sourcePosition,
        targetX: targetX,
        targetY: targetY,
        targetPosition: targetPosition,
    });


    let markerEnd = {
        color: calculateEdgeColor(data.endpoints),
    }

    let style = {
        stroke: calculateEdgeColor(data.endpoints),
    }

    return (
        <>
            {/*// @ts-ignore*/}
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        // everything inside EdgeLabelRenderer has no pointer events by default
                        // if you have an interactive element, set pointer-events: all
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan text-secondary hover:z-10"
                >
                    <div className={"text-textmedium pr-2 pl-2 pt-1 pb-1 border rounded bg-backgroundlight"}>
                        <ProtocolContainer from={data.from} to={data.to} endpoints={data.endpoints}/>
                    </div>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}


function ServiceNodeInner(props: {
    inboundEdges: any[],
    outboundEdges: any[],
    serviceName: string
}) {
    let allInboundEndpoints = props.inboundEdges.reduce((acc: Endpoint[], edge: any) => {
        return [...acc, ...edge.endpoints]
    }, [])

    let serviceUrl = `/service?service=${props.serviceName}`

    return <div className={"flex flex-col grow shrink justify-center text-center"}>
        <div
            className={"group-hover:hidden flex flex-col grow shrink justify-center text-center"}>{getServiceName(props.serviceName)}</div>
        <div className={"hidden group-hover:block p-2 max-h-[360px] overflow-y-auto"}>
            <div className={"text-lg mb-2"}>
                {getServiceName(props.serviceName)}
            </div>
            <StatsBlock url={serviceUrl} endpoints={allInboundEndpoints} title={"Overall"}/>
            {
                props.inboundEdges.sort(
                    (a, b) => b.endpoints.reduce((acc: number, endpoint: Endpoint) => acc + endpoint.requestCount, 0) -
                        a.endpoints.reduce((acc: number, endpoint: Endpoint) => acc + endpoint.requestCount, 0)
                ).map((edge: any) => {
                    const filterBy = {"client.service.name": [edge.from], "server.service.name": [edge.to]}
                    let tracesUrl = `/traces?filter=${JSON.stringify(filterBy)}`
                    return <div className={"mt-2"}><StatsBlock url={tracesUrl} title={getServiceName(edge.from)}
                                                               endpoints={edge.endpoints}/>
                    </div>
                })
            }
        </div>
    </div>
}

function ServiceNode(props: { data: any }) {
    return (
        <div
            className={"group hover:w-max w-[150px] hover:h-max h-[75px] bg-backgroundlight border rounded text-textmedium flex justify-center hover:z-10 z-0"}>
            <Handle type="target" position={Position.Left}/>
            <ServiceNodeInner inboundEdges={props.data.inboundEdges} outboundEdges={props.data.outboundEdges}
                              serviceName={props.data.serviceName}/>
            <Handle type="source" position={Position.Right}/>
        </div>
    );
}

function getServiceName(service: string): string {
    // Get the last segment split by /
    let segments = service.split("/")
    return segments[segments.length - 1]
}