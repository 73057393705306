import React, {useEffect, useState} from "react";
import axios from 'utility/customAxios';
import {useSelector} from "react-redux";
import timerange from "../../store/reducers/timerange";
import SummaryTable from "./SummaryTable";
import PodsTable from "./PodsTable";
import {K8sPodDetails} from "./model";
import {GanttCell, GanttChart, TooltipBodyAlign} from "../Gantt/GanttChart";
import {ChartType, MetoroMetricsChart, MetricType} from "../../pages/MetricsTest";
import yaml from "js-yaml";
import {V1Pod} from "@kubernetes/client-node";
import {Tooltip, TooltipContent, TooltipTrigger} from "../ui/tooltip";
import {FrownIcon, InfoIcon} from "lucide-react";
import {plainToClassFromExist} from "class-transformer";
import {useSearchParams} from "react-router-dom";
import {TimeRange} from "../../types/time";
import {useDebouncedCallback} from "use-debounce";
import {RateFunctionType} from "../Dashboarding/widgets/MetricSelector";
import { v4 as uuid } from 'uuid';


interface K8sSummaryResponse {
    kind: string;                // e.g. Deployment
    resourceYaml: string; // e.g. Deployment details
    pods: K8sPodDetails[];
    environments: string[];
}


export interface PodState {
    startTime: number;
    endTime: number;
    timestamp: number;
    phase: string;
    reason: string;
    exitCode: string;
    version: string;
}

interface KubernetesViewProps {
    serviceName: string;
}

function MetricsPanel(props: { serviceName: string, pods: K8sPodDetails[] | undefined }) {
    if (!props.pods) {
        return <div/>
    }

    if (props.pods.length === 0) {
        return <div className={"bg-backgroundmedium rounded border border-border flex grow"}>
            <div className={"flex grow text-textmedium justify-center"}>
                <div className="flex flex-row justify-center my-8 space-x-2 items-center">
                    <div className={"text-textdark text-center"}>No pods found</div>
                    <FrownIcon className={"text-textdark"}/>
                </div>
            </div>
        </div>

    }

    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();
    let containerIds: string[] = [];
    props.pods.forEach(pod => {
        const parsedYaml = yaml.load(pod.yaml)
        const k8sPod = parsedYaml as V1Pod;
        const namespace = k8sPod.metadata?.namespace!;
        k8sPod.status?.containerStatuses?.forEach((containerStatus) => {
            containerIds.push(`/k8s/${namespace}/${pod.name}/${containerStatus.name}`);
        })
    })
    let filters = new Map<string, string[]>();
    filters.set("service_name", [props.serviceName]);

    return <div className={"bg-backgroundmedium border p-4 relative"}>
        <Tooltip>
            <TooltipTrigger>
                <div className={"flex gap-2 mb-4"}>
                    <div className={"text-lg text-textmedium flex flex-col justify-center"}>
                        Aggregated Pod Metrics
                    </div>
                    <div className={"flex flex-col justify-center"}>
                        <InfoIcon className={"h-4 w-4 text-primary"}/>
                    </div>
                </div>
            </TooltipTrigger>
            <TooltipContent className={"border bg-backgroundmedium text-textmedium p-2"}>
                This panel shows aggregated metrics for all containers in the selected service. For more detailed
                information, drill into individual pods.
            </TooltipContent>
        </Tooltip>
        <div className={"grid-cols-2 grid gap-4 rounded text-textlight overflow-y-auto relative"}>
            <MetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_resources_cpu_usage_seconds_total"}
                functions={[{id: uuid(), functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`CPU Usage - Seconds`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                hideOnNoData={true}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_resources_cpu_limit_cores"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`CPU Limit - Cores`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_resources_memory_rss_bytes"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Memory Usage - Bytes`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_resources_memory_limit_bytes"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Memory Limit - Bytes`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_resources_disk_read_bytes_total"}
                functions={[{id: uuid(), functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Disk Bytes Read`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_resources_disk_written_bytes_total"}
                functions={[{id: uuid(), functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Disk Bytes Written`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_resources_disk_used_bytes"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Disk Usage`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_net_tcp_active_connections"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Open TCP Connections`}
                splits={["container_id"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_net_bytes_received_total"}
                functions={[{id: uuid(), functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Network Bytes Received`}
                splits={["pod_name"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={Math.floor(startEnd[0].getTime() / 1000)}
                endTime={Math.floor(startEnd[1].getTime() / 1000)}
                metricName={"container_net_bytes_sent_total"}
                functions={[{id: uuid(), functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Network Bytes Sent`}
                splits={["pod_name"]}
                filters={filters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
        </div>
    </div>
}

function updatePods(timeRange: TimeRange, props: {
    serviceName: string
}, environments: string[], setPodLifecycle: (value: (((prevState: (Map<string, PodState[]> | undefined)) => (Map<string, PodState[]> | undefined)) | Map<string, PodState[]> | undefined)) => void) {
    try {
        const startEnd = timeRange.getStartEnd();
        axios.post("/api/v1/k8s/pods", {
                "serviceName": props.serviceName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            }
        ).then((response) => {
            let awaitedMap = new Map<string, PodState[]>();
            plainToClassFromExist(awaitedMap, response.data.states)
            setPodLifecycle(awaitedMap);
        });
    } catch (e) {
        console.error(e);
    }
}

async function updateCurrentRunningServices(environments: string[], timeRange: TimeRange, props: {
    serviceName: string
}, setCurrentRunningServices: (value: (((prevState: K8sSummaryResponse | undefined) => K8sSummaryResponse) | K8sSummaryResponse | undefined)) => void) {
    const responses: Map<string, K8sSummaryResponse> = new Map();
    try {
        const startEnd = timeRange.getStartEnd();
        const response = await axios.post("/api/v1/k8s", {
                "serviceName": props.serviceName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            }
        )
        const responseData = response.data as K8sSummaryResponse;
        setCurrentRunningServices(responseData);
    } catch (e) {
        console.error(e);
    }
}

export const KubernetesView: React.FC<KubernetesViewProps> = (props: {
    serviceName: string,
}) => {
    const [currentRunningServices, setCurrentRunningServices] = React.useState<K8sSummaryResponse>();
    const [currentRunningPods, setCurrentRunningPods] = React.useState<K8sPodDetails[]>([]);
    const [podLifecycle, setPodLifecycle] = React.useState<Map<string, PodState[]>>();
    const [podSelectedPhase, setPodSelectedPhase] = React.useState<PodState>();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [searchParams, setSearchParams] = useSearchParams();
    const [environments, setEnvironments] = useState<string[]>([]);
    const [envToResourceYamlMap, setEnvToResourceYamlMap] = useState<Map<string, string>>(new Map<string, string>());
    const debouncedUpdatePods = useDebouncedCallback(updatePods, 10);
    const debouncedUpdateCurrentRunningServices = useDebouncedCallback(updateCurrentRunningServices, 10);

    const [allEnvironments, setAllEnvironments] = useState<string[]>([]);
    useEffect(() => {
        axios.get("/api/v1/environments").then((response) => {
            setAllEnvironments(response.data.environments);
        })
    }, [])

    useEffect(() => {
        let environment = searchParams.get("environment") || "";
        if (environment !== "") {
            if (environments.length === 1 && environments[0] === environment) {
                return
            }
            setEnvironments([environment])
        } else {
            setEnvironments([])
        }
    }, [searchParams, allEnvironments])

    useEffect(() => {
        debouncedUpdateCurrentRunningServices(environments, timeRange, props, setCurrentRunningServices);
    }, [timeRange, environments]);

    useEffect(() => {
        debouncedUpdatePods(timeRange, props, environments, setPodLifecycle);
    }, [timeRange, environments]);


    // The kind should be the same for both. If not, we will just use the first one
    let summaryTableKind = currentRunningServices?.kind;

    useEffect(() => {
        if (currentRunningServices === undefined) {
            return
        }
        const temporaryEnvToResourceYamlMap = new Map<string, string>();
        temporaryEnvToResourceYamlMap.set(currentRunningServices.environments[0], currentRunningServices.resourceYaml);
        setCurrentRunningPods(currentRunningServices.pods);
        setEnvToResourceYamlMap(temporaryEnvToResourceYamlMap);
    }, [currentRunningServices, environments]);


    function colorCodePhase(phase: string) {
        switch (phase) {
            case "Running":
                return "#2DD881";
            case "Pending":
                return "#f59e0b";
            case "Failed":
                return "#ef4444";
            case "Terminated":
                return "#64748b";
            case "Succeeded":
                return "#2DD881";
            case "Unknown":
                return "#182338";
            default:
                return "#f59e0b";
        }
    }

    function podStatusToGanttData(podStates: Map<string, PodState[]>, startDateTime: Date, endDateTime: Date) {
        let graphRows = [];
        for (const [podName, states] of podStates.entries()) {
            let cells: GanttCell[] = []
            states.forEach((podState, idx) => {
                    let res = {
                        startTime: new Date(podState.startTime * 1000),
                        endTime: new Date(podState.endTime * 1000),
                        text: podState.phase,
                        color: colorCodePhase(podState.phase),
                        onClick: () => {
                        },
                        metadata: podState
                    }
                    cells.push(res);
                }
            )
            ;
            graphRows.push({label: podName, cells: cells})
        }

        return {
            barThickness: 20,
            startTime: startDateTime,
            endTime: endDateTime,
            rows: graphRows,
            tooltipBodyAlign: TooltipBodyAlign.left,
            tooltipCallback: (tooltipItem: any) => {
                const dataIndex = tooltipItem.dataIndex
                const podState = tooltipItem.dataset.data[dataIndex][2] as PodState
                if (podState.phase === "Running") {
                    let out = [`Phase: ${podState.phase}`]
                    if (podState.timestamp) {
                        out.push(`Since: ${new Date(podState.timestamp * 1000).toLocaleString()}`)
                    }
                    return out
                }
                if (podState.phase === "Failed") {
                    let out = [`Phase: ${podState.phase}`]
                    if (podState.timestamp) {
                        out.push(`Timestamp: ${new Date(podState.timestamp * 1000).toLocaleString()}`)
                    }
                    if (podState.reason) {
                        out.push(`Reason: ${podState.reason}`)
                    }
                    if (podState.exitCode) {
                        out.push(`Exit Code: ${podState.exitCode}`)
                    }
                    return out
                }
                if (podState.phase === "Terminated") {
                    let out = [`Phase: ${podState.phase}`]
                    if (podState.timestamp) {
                        out.push(`Timestamp: ${new Date(podState.timestamp * 1000).toLocaleString()}`)
                    }
                    if (podState.reason) {
                        out.push(`Reason: ${podState.reason}`)
                    }
                    if (podState.exitCode) {
                        out.push(`Exit Code: ${podState.exitCode}`)
                    }
                    return out
                } else {
                    let out = [`Phase: ${podState.phase}`]
                    if (podState.timestamp) {
                        out.push(`Timestamp: ${new Date(podState.timestamp * 1000).toLocaleString()}`)
                    }
                    if (podState.reason) {
                        out.push(`Reason: ${podState.reason}`)
                    }
                    return out
                }
            }
        }
    }

    return <div className={"flex flex-col grow shrink gap-4 min-w-0 min-h-0 overflow-y-auto"}>
        <div className={"grid grid-cols-2 grid-rows-1 h-[256px] gap-x-4"}>
            <SummaryTable kind={summaryTableKind ? summaryTableKind : ""}
                          resourceYamls={envToResourceYamlMap}
                          podDetails={currentRunningPods}/>
            <div className={"text-textmedium border relative bg-backgroundmedium p-4 overflow-y-auto"}>
                <div className={"text-lg font-semibold pb-2"}>
                    Pod Lifecycle
                </div>
                {podLifecycle &&
                    <GanttChart
                        data={podStatusToGanttData(podLifecycle, timeRange.getStartEnd()[0], timeRange.getStartEnd()[1])}
                        axisPosition={"top"}/>}
            </div>
        </div>
        <PodsTable serviceName={props.serviceName}
                   isAggregated={environments.length > 1}
                   podDetails={currentRunningPods}/>
        <MetricsPanel serviceName={props.serviceName} pods={currentRunningPods}/>
    </div>

}
