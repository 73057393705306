import {useDispatch, useSelector} from "react-redux";
import onboarding from "../store/reducers/onboarding";
import {Outlet, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {AxiosPromise} from "axios";
import axios from "../utility/customAxios";
import {BaseView} from "./BaseView";
import logoIcon from 'assets/images/logoColour.png';
import {LaptopIcon, LogOutIcon, Server} from "lucide-react";
import {cn} from "../components/ui/lib/utils";
import {ReactFlow, ReactFlowProvider} from "reactflow";
import humanFormat from "human-format";

import styled from "@emotion/styled";
import {plainToClassFromExist} from "class-transformer";
import {Button} from "../components/ui/button";
import useAuth from "../hooks/useAuth";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {toast} from "sonner";
import {Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "../components/ui/form";
import {Input} from "../components/ui/input";
import {z} from "zod";
import {Label} from "../components/ui/label";
import {Separator} from "../components/ui/separator";
import {waitFor} from "@testing-library/react";

interface OnboardedResponse {
    onboarded: boolean
}

interface OnboardingScriptsResponse {
    scripts: Map<string, string>
}

interface OnboardingProps {
}

function InstallationMethodSelector(props: {
    icon: React.ReactNode,
    method: string,
    displayMethod: string,
    selectedMethod: string,
    setSelectedMethod: (value: (((prevState: string) => string) | string)) => void
}) {
    return (
        <div
            onClick={() => props.setSelectedMethod(props.method)}
            className={cn(props.selectedMethod === props.method ? "border-secondary" : "", "flex flex-col gap-2 p-2 min-w-0 min-h-0 grow shrink border rounded bg-backgroundmedium hover:border-primary hover:cursor-pointer")}>
            <div
                className={"text-center text-textmedium text-base font-normal font-['Inter'] leading-normal flex justify-center grow shrink"}>
                {props.displayMethod}
            </div>
            <div
                className={cn("flex text-textmedium justify-center grow shrink hover:text-primary", props.selectedMethod === props.method ? "text-secondary" : "")}>
                {props.icon}
            </div>
        </div>
    )
}

function InstallationScript(props: { selectedScript: string | undefined }) {
    if (props.selectedScript === undefined) {
        return <></>
    }

    return (
        <div className={"flex flex-col gap-4 p-4 min-w-0 min-h-0 grow shrink"}>
            <div
                className="text-center text-textmedium text-base font-normal font-['Inter'] leading-normal flex justify-center grow shrink">
                Copy and paste the following script into your terminal to install Metoro
            </div>
            <div
                className={"flex flex-col gap-4 p-4 min-w-0 min-h-0 grow shrink border rounded bg-backgroundmedium max-h-[10vh] overflow-y-auto"}>
                <div className={"text-textlight text-base font-normal font-['Inter'] leading-normal break-all"}>
                    {props.selectedScript}
                </div>
            </div>
        </div>
    )
}

interface KnownService {
    displayName: string
    serviceName: string
    requestsPerSecond: number
    logsPerSecond: number
}

interface GetKnownServicesResponse {
    services: KnownService[]
}

interface NodesAndEdges {
    nodes: any[]
    edges: any[]
}

function createNodesAndEdges(knownServices: GetKnownServicesResponse): NodesAndEdges {
    let nodes: any[] = []
    let edges: any[] = []

    let height = 16

    for (let service of knownServices.services) {
        nodes.push({
            id: service.serviceName,
            type: '',
            data: {label: service.displayName},
            sourcePosition: 'right',
            targetPosition: 'left',
            // Relative to the parent
            position: {x: 400, y: height},
            style: {
                color: '#C6D3E2',
                background: 'none',
                border: '1px solid #334670',
                borderRadius: '2px',
            }
        })
        nodes.push({
            id: service.serviceName + '-stats-traces',
            type: '',
            data: {label: `${humanFormat(service.requestsPerSecond, {maxDecimals: 0})} traces / min`},
            sourcePosition: 'right',
            targetPosition: 'left',
            // Relative to the parent
            position: {x: 400, y: height + 32},
            style: {
                color: '#2DD881',
                background: 'none',
                border: '0px',
            }
        })

        nodes.push({
            id: service.serviceName + '-stats-logs',
            type: '',
            data: {label: `${humanFormat(service.logsPerSecond, {maxDecimals: 0})} logs / min`},
            sourcePosition: 'right',
            targetPosition: 'left',
            // Relative to the parent
            position: {x: 400, y: height + 48},
            style: {
                color: '#2DD881',
                background: 'none',
                border: '0px',
            }
        })
        edges.push({
            id: `e${service.serviceName}-metoro`,
            source: service.serviceName,
            target: 'metoro',
            animated: true,
            style: {
                text: {
                    fill: '#C6D3E2',
                    background: 'none',
                    border: '0px',
                    stroke: '#2DD881'
                },
                stroke: '#2DD881'
            },
        })
        height += 96
    }


    nodes.push({
        id: 'metoro',
        type: 'output',
        targetPosition: 'left',
        data: {label: 'Metoro'},
        position: {x: 800, y: 200},
        style: {
            color: '#EBF1F7',
            background: 'rgba(45, 216, 129, 0.1)',
            border: '1px solid #2DD881',
            borderRadius: '2px',
        }
    })


    return {nodes, edges}

}

export const ReactFlowStyled = styled(ReactFlow)`
    .react-flow__handle-left {
        opacity: 0;
    }

    .react-flow__handle-right {
        opacity: 0;
    }

    .react-flow__node:hover {
        z-index: 10 !important;
    }

    .react-flow__node {
        z-index: 0 !important;
    }
`;


function OnboardingFlowDiagram(props: { knownServices: GetKnownServicesResponse | undefined }) {
    if (props.knownServices === undefined) {
        return <></>
    }
    const {nodes, edges} = createNodesAndEdges(props.knownServices!)

    let nodesToFit = []
    for (let i = 0; i < nodes.length; i++) {
        if (i < nodes.length && i < 15) {
            nodesToFit.push({id: nodes[i].id})
        }
    }

    // Add metoro to the nodes to fit
    nodesToFit.push({id: "metoro"})

    return (
        <div className={"flex min-w-0 min-h-0 grow shrink h-[60vh] border rounded mx-4"}>
            {/* @ts-ignore */}
            <ReactFlowStyled
                fitViewOptions={{nodes: nodesToFit}}
                fitView
                proOptions={{hideAttribution: true}}
                edgesUpdatable={false}
                edgesFocusable={false}
                nodesDraggable={false}
                nodesConnectable={false}
                nodesFocusable={false}
                draggable={false}
                elementsSelectable={false}
                maxZoom={1.0} minZoom={0.1} nodes={nodes}
                edges={edges}/>
        </div>
    )
}

// Returns the text Waiting for cluster to connect... with a green blinking terminal cursor at the end
function LoadingBar() {
    return (
        <div className={"flex justify-center mt-4 p-4 min-w-0 min-h-0 grow shrink items-center"}>
            <div
                className="text-center text-textdark text-2xl font-bold font-['Inter'] leading-normal">
                Waiting for cluster to connect
            </div>
            <div className={"ml-1 bg-green-500 w-4 h-6 animate-blink"}/>
        </div>
    )
}

export function OnboardingFlow(props: {
    addCluster?: boolean
}) {
    const [scripts, setScripts] = React.useState<Map<string, string>>(new Map())
    const [selectedMethod, setSelectedMethod] = React.useState<string>("")
    const [knownServices, setKnownServices] = React.useState<GetKnownServicesResponse>()
    const [clusterName, setClusterName] = React.useState<string>("")
    const [triggerReload, setTriggerReload] = React.useState<boolean>(false)
    const [showScript, setShowScript] = React.useState<boolean>(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (clusterName === "") {
            return
        }
        try {
            const d: AxiosPromise<OnboardingScriptsResponse> = axios.get("/api/v1/onboardingScript?environment=" + clusterName)
            d.then((response) => {
                let awaitedMap = new Map<string, string>();
                plainToClassFromExist(awaitedMap, response.data.scripts)
                setScripts(awaitedMap)
            })
        } catch (e) {
            console.error(e)
        }
    }, [triggerReload])

    useEffect(() => {
        function updateKnownServices() {
            if (clusterName === "") {
                return
            }
            try {
                const d: AxiosPromise<GetKnownServicesResponse> = axios.get("/api/v1/knownServices?environment=" + clusterName)
                d.then((response) => {
                    setKnownServices(response.data)
                })
            } catch (e) {
                console.error(e)
            }
        }

        updateKnownServices()
        let knowServiceInterval = setInterval(updateKnownServices, 1000)

        // @ts-ignore
        return () => clearInterval(knowServiceInterval)
    }, [clusterName])

    let selectedScript: string | undefined = undefined
    if (selectedMethod !== "") {
        selectedScript = scripts.get(selectedMethod)
    }

    return <div className={"flex flex-col min-w-0 min-h-0 grow shrink justify-center items-center"}>
        <div className={"flex flex-col space-y-2 text-center"}>
            <img src={logoIcon} alt="Metoro" className="w-24 h-24 mb-8 mx-auto"/>
            <div
                className={"flex flex-col gap-4 p-4 min-w-0 min-h-0 grow shrink border rounded bg-backgroundmedium w-[66vw]"}>
                {props.addCluster !== undefined && !props.addCluster && <div
                    className="text-center text-textlight text-2xl font-semibold font-['Inter'] leading-7 flex justify-center grow shrink">Welcome
                    to next generation observability
                </div>}
                {(knownServices === undefined || knownServices.services.length == 0) &&
                    <div
                        className="text-center text-textmedium text-base font-normal font-['Inter'] leading-normal flex justify-center grow shrink">To
                        get started with Metoro, you need to connect a Kubernetes cluster
                    </div>
                }
                { knownServices !== undefined && knownServices.services.length > 0 && <div
                    className="text-center text-textmedium text-base font-normal font-['Inter'] leading-normal flex justify-center grow shrink">Your Cluster is connected to Metoro. Happy Observing!
                </div>}
                {(knownServices === undefined || knownServices.services.length == 0) &&
                    <div className={"flex justify-evenly gap-4 px-4"}>
                        <InstallationMethodSelector icon={<LaptopIcon className={"w-16 h-16"}/>}
                                                    displayMethod={"Local Dev Cluster"}
                                                    method={"local"}
                                                    setSelectedMethod={setSelectedMethod}
                                                    selectedMethod={selectedMethod}/>
                        <InstallationMethodSelector icon={<Server className={"w-16 h-16"}/>}
                                                    displayMethod={"Existing Cluster"}
                                                    method={"existing"}
                                                    setSelectedMethod={setSelectedMethod}
                                                    selectedMethod={selectedMethod}/>
                    </div>}
                {(knownServices === undefined || knownServices.services.length == 0) && selectedMethod !== "" &&
                    <div className={"flex flex-col gap-y-4 mt-4"}>
                        <div className={"flex flex-col gap-2 items-center"}>
                            <div
                                className="text-textmedium text-base font-normal font-['Inter'] leading-normal flex justify-center grow shrink">What
                                would you like to call this cluster?
                            </div>
                            <div className={"flex gap-2"}>
                                <Input className={"border-border rounded"}
                                       placeholder="e.g. Dev Cluster"
                                       onChange={(e) => {
                                           setShowScript(false)
                                           setClusterName(e.target.value)
                                       }}
                                />
                                <Button
                                    onClick={() => {
                                        if (clusterName === "") {
                                            toast.error("Cluster name cannot be empty")
                                            return
                                        }
                                        setShowScript(true)
                                        setTriggerReload(prev => !prev)
                                    }}
                                    className={"border rounded bg-primarytransparent border-primary text-textlight hover:cursor-pointer hover:border-primaryhover"}>Next</Button>
                            </div>
                        </div>
                    </div>}

                {knownServices === undefined || knownServices.services.length == 0 && selectedMethod !== "" && clusterName !== "" && showScript &&
                    <InstallationScript selectedScript={selectedScript}/>}
                { knownServices !== undefined && knownServices.services.length > 0 &&
                    <ReactFlowProvider>
                        <OnboardingFlowDiagram knownServices={knownServices}/>
                    </ReactFlowProvider>}
                {
                    knownServices === undefined || knownServices.services.length == 0 && selectedMethod !== "" && clusterName !== "" && showScript &&
                    <LoadingBar/>
                }
                { knownServices !== undefined && knownServices.services.length > 0 &&
                    <div className={"w-full flex justify-center"}>
                        <Button type="submit"
                                className={"w-[20vw] h-16 text-base text-textlight border border-primary rounded bg-primarytransparent hover:bg-primaryhover"}
                                onClick={() => {
                                    axios.post("/api/v1/onboard?environment=" + clusterName)
                                    if (props.addCluster !== undefined && props.addCluster) {
                                        navigate("/")
                                    } else {
                                        dispatch(onboarding.actions.set(true))

                                    }
                                }}>
                            Continue to Metoro
                        </Button>
                    </div>
                }
            </div>
        </div>
    </div>
}

export function OnboardingPage() {
    const jwtContext = useAuth();

    return (
        <div className={"relative"}>
            <BaseView className={"blur-sm"} title={"Onboarding"}/>
            <div
                className={"absolute top-0 left-0 w-screen h-screen blur-none flex min-w-0 min-h-0 grow shrink justify-center"}>
                <OnboardingFlow/>
                <div className={"absolute bottom-0 right-0 m-4 "} onClick={() => {
                    jwtContext.logout()
                    window.location.href = "/login"
                }}>
                    <div
                        className={"flex gap-2 bg-backgroundmedium items-center hover:cursor-pointer p-2 border border-secondary hover:border-primary rounded hover:text-primary text-secondary"}>
                        <div className={"text-lg text-textmedium"}>Logout</div>
                        <div className={"flex flex-col justify-center items-center"}>
                            <LogOutIcon className={"w-8 h-8"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FormSchema = z.object({
    clustername: z.string().min(2, {
        message: "Username must be at least 2 characters.",
    }),
})

function InputClusterNameForm() {
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            clustername: "",
        },
    })

    return (
        <div className={"flex justify-center w-full"}>
            <Form {...form}>
                <form className="space-y-4">
                    <FormField
                        control={form.control}
                        name="clustername"
                        render={({field}) => (
                            <FormItem className={"flex flex-col items-center"}>
                                <FormLabel
                                    className={"text-center text-textmedium text-base font-normal font-['Inter'] leading-normal flex justify-center grow shrink"}>What
                                    would you like to call this
                                    cluster? </FormLabel>
                                <FormControl>
                                    <Input className={"border-border rounded"}
                                           placeholder="e.g. Dev Cluster" {...field}
                                           onChange={(e) => {
                                               form.setValue("clustername", e.target.value)
                                           }}
                                    />
                                </FormControl>
                                <FormDescription className={"text-textdark font-['Inter']"}>
                                    This is the name of your cluster that you want to connect to Metoro. You can add
                                    multiple clusters later.
                                </FormDescription>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </div>
    )
}

const Onboarding = (props: OnboardingProps) => {
    const isOnboarded = useSelector(onboarding.selectors.getOnboarded)
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            const d: AxiosPromise<OnboardedResponse> = axios.get("/api/v1/isOnboarded")
            d.then((response) => {
                dispatch(onboarding.actions.set(response.data.onboarded))
            })
        } catch (e) {
            console.error(e)
        }
    }, [])


    if (!isOnboarded) {
        return <OnboardingPage/>
    }

    return <Outlet/>
}

export default Onboarding;