import {BaseView} from "./BaseView";
import React, {Dispatch, ReactElement, SetStateAction, useEffect} from "react";
import {cn, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {defaultKubernetesAlert, KubernetesAlert, kubernetesEvents} from "./alerts/KubernetesAlert";
import {GroupByPill, Pill} from "../components/Filter/Pill";
import {Popover, PopoverContent, PopoverTrigger} from "../components/ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../components/ui/command";
import axios from "../utility/customAxios";
import {useSearchParams} from "react-router-dom";
import {Textarea} from "../components/ui/textarea";
import {Input} from "../components/ui/input";
import {defaultLogAlert, LogAlert, LogEventRegexMatch, LogEventRegexMatchPayload, logEvents} from "./alerts/LogAlert";
import {Button} from "../components/ui/button";
import {EmbedMetricSelector, MetricFunction,} from "../components/Dashboarding/widgets/MetricSelector";
import {ChartType, GetAggregateMetricEvaluation, MetoroMetricsChartProps, Metric, MetricType} from "./MetricsTest";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {Form, FormControl, FormDescription, FormField, FormItem, FormMessage} from "../components/ui/form";
import {useForm} from "react-hook-form";
import {z} from "zod"
import {zodResolver} from "@hookform/resolvers/zod"
import {
    AggregationFunction,
    defaultMetricAlert,
    EvalType,
    MetricAlert,
    monitorEvents,
    WindowUnit
} from "./alerts/MetricAlert";
import {defaultTraceAlert, TraceAlert} from "./alerts/TraceAlert";
import {adjustThreshold} from "./alerts/utils";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuTrigger
} from "../components/ui/dropdown-menu";
import {ChevronDownIcon} from "@radix-ui/react-icons";
import {plainToClassFromExist} from "class-transformer";
import {useDebouncedCallback} from "use-debounce";

const nanoToMilliSeconds = 1_000_000


enum AlertType {
    Kubernetes = "Kubernetes",
    Log = "Log",
    Metric = "Metric",
    Trace = "Trace"
}

enum AlertDestinationType {
    Slack = "Slack",
    PagerDuty = "PagerDuty",
}

interface SlackAlertDestination {
    channel: string
    additionalMessage?: string
}

interface PagerDutyAlertDestination {
    serviceName: string
    serviceId: string
}

interface AlertDestination {
    type: AlertDestinationType
    alertDestination: SlackAlertDestination | PagerDutyAlertDestination
}

interface Alert {
    uuid: string,
    type: AlertType,
    name: string,
    description: string,
    alert: KubernetesAlert | MetricAlert | LogAlert | TraceAlert,
    destination: AlertDestination
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function AlertTypeSelector(
    props: {
        alert: Alert,
        setAlert: Dispatch<SetStateAction<Alert>>
    }) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                1
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Alert Type
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    Object.values(AlertType).map((type, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", props.alert.type === type ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => {
                                        if (type === AlertType.Metric) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultMetricAlert}
                                            })
                                        }

                                        if (type === AlertType.Kubernetes) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultKubernetesAlert}
                                            })
                                        }

                                        if (type === AlertType.Log) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultLogAlert}

                                            })
                                        }

                                        if (type === AlertType.Trace) {
                                            props.setAlert((prev) => {
                                                return {...prev, alert: defaultTraceAlert}
                                            })
                                        }
                                        props.setAlert((prev) => {
                                            return {...prev, type: type as AlertType}
                                        })
                                    }}>
                            {capitalizeFirstLetter(type)}
                        </div>
                    })
                }
            </div>
        </div>
    </div>;
}


function LogEventSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                2
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Event
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    logEvents.map((event, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", (props.alert.alert as KubernetesAlert).monitorEvent === event ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => {
                                        let logAlert = (props.alert.alert as LogAlert)
                                        logAlert.monitorEvent = event
                                        logAlert.eventPayload = {regex: ""}
                                        props.setAlert({...props.alert, alert: logAlert})
                                    }}>
                            <Tooltip delayDuration={10}>
                                <TooltipTrigger>
                                    {capitalizeFirstLetter(event.name)}
                                </TooltipTrigger>
                                <TooltipContent side={"right"}
                                                className={"ml-4 bg-backgroundlight border text-textlight rounded"}>
                                    {event.description}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    })
                }
            </div>
        </div>
        {
            (props.alert.alert as LogAlert).monitorEvent === LogEventRegexMatch &&
            <div className={"flex flex-col justify-start text-textmedium"}>
                <div>
                    The alert will fire when the regex matches the log body.
                    The regex is an <a target={"_blank"} className={"text-textlight underline"}
                                       href={"https://github.com/google/re2/wiki/Syntax"}>re2</a> regex.
                </div>
                <div className={"mt-4 flex gap-2 grow"}>
                    <Input
                        value={((props.alert.alert as LogAlert).eventPayload as LogEventRegexMatchPayload).regex}
                        onChange={(e) => {
                            props.setAlert((prev) => {
                                let logAlert = prev.alert as LogAlert
                                let regexPayload = logAlert.eventPayload as LogEventRegexMatchPayload
                                regexPayload.regex = e.target.value
                                logAlert.eventPayload = regexPayload
                                return {...prev, alert: logAlert}
                            })
                        }}
                        className={"h-[32px] flex justify-center border-border items-center text-textmedium border gap-2 p-2 rounded bg-none hover:text-textlight hover:border-primary cursor-pointer px-4"}
                        placeholder={"re2 regex to match against"}
                    />
                </div>
            </div>
        }
    </div>;
}

function AlertEventSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                2
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Event
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                {
                    kubernetesEvents.map((event, index) => {
                        return <div key={index}
                                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", (props.alert.alert as KubernetesAlert).monitorEvent === event ? "border bg-secondarytransparenter border-secondary" : "")}
                                    onClick={() => {
                                        let k8sAlert = (props.alert.alert as KubernetesAlert)
                                        k8sAlert.monitorEvent = event
                                        props.setAlert({...props.alert, alert: k8sAlert})
                                    }}>
                            <Tooltip delayDuration={10}>
                                <TooltipTrigger>
                                    {capitalizeFirstLetter(event.name)}
                                </TooltipTrigger>
                                <TooltipContent side={"right"}
                                                className={"ml-4 bg-backgroundlight border text-textlight rounded"}>
                                    {event.description}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    })
                }
            </div>
        </div>
    </div>;
}

function initialFilterMap(clusters: string[], services: string[]): Map<string, string[]> {
    let filterMap = new Map<string, string[]>()
    filterMap.set("cluster", clusters)
    filterMap.set("service", services)
    return filterMap
}

export interface KnownService {
    displayName: string
    serviceName: string
    requestsPerSecond: number
    logsPerSecond: number
}


function AlertFilterSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    let k8sAlert = (props.alert.alert as KubernetesAlert)

    const [filters, setFilters] = React.useState<Map<string, string[]>>(initialFilterMap(k8sAlert.filters.clusters, k8sAlert.filters.services))
    const [openClusterFilter, setOpenClusterFilter] = React.useState(false)
    const [openServiceFilter, setOpenServiceFilter] = React.useState(false)
    const [environments, setEnvironments] = React.useState<string[]>([])
    const [services, setServices] = React.useState<KnownService[]>([])
    const [selectedEnvironments, setSelectedEnvironments] = React.useState<string[]>([])

    useEffect(() => {
        // Fetch environments
        axios.get("/api/v1/environments").then((response) => {
            setEnvironments(response.data.environments)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        // Fetch environments
        axios.post("/api/v1/knownServices", {
            environments: selectedEnvironments
        }).then((response) => {
            setServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [selectedEnvironments])


    useEffect(() => {
        let k8sAlert = (props.alert.alert as KubernetesAlert)
        k8sAlert.filters.clusters = filters.get("cluster") ?? []
        k8sAlert.filters.services = filters.get("service") ?? []
        props.setAlert({...props.alert, alert: k8sAlert})
    }, [filters])

    useEffect(() => {
        if (!filters.has("cluster")) {
            setSelectedEnvironments([])
        }
        filters.get("cluster")?.forEach((cluster) => {
            setSelectedEnvironments(filters.get("cluster") ?? [])
        })
    }, [filters]);

    let clusterPills: ReactElement[] = [];
    filters.get("cluster")?.forEach((cluster) => {
        clusterPills.push(<Pill key={cluster} attributeKey={"cluster"} attributeValue={cluster}
                                filter={filters}
                                setFilter={setFilters}
                                additionalRemoveAction={() => {
                                    setFilters((prev: Map<string, string[]>) => {
                                        const newFilter = new Map(prev);
                                        if (newFilter.has("service")) {
                                            newFilter.delete("service")
                                        }
                                        return newFilter
                                    })
                                }}
        />)
    })

    let servicePills: ReactElement[] = [];
    filters.get("service")?.forEach((service) => {
        servicePills.push(<Pill key={service} attributeKey={"service"} attributeValue={service}
                                filter={filters}
                                setFilter={setFilters}/>)
    })

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                3
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Filters
            </div>
        </div>
        <div className={"text-textmedium"}>
            <div>
                The alert will fire for:
            </div>
            <div>
                <ul className={"list-disc list-inside"}>
                    <li>
                        {
                            k8sAlert.filters.clusters.length === 0 ? "All clusters" : "Only the following clusters: " + k8sAlert.filters.clusters.join(", ")
                        }
                    </li>
                    <li>
                        {
                            k8sAlert.filters.services.length === 0 ? "All services in each cluster" : "Only the following services: " + k8sAlert.filters.services.join(", ")
                        }
                    </li>
                </ul>
            </div>
        </div>
        <div className={"flex flex-col justify-start gap-4"}>
            <div className={"flex flex-wrap border bg-backgroundmedium rounded"}>
                <div className={"text-textmedium flex flex-col justify-center border-r bg-backgrounddark p-2"}>
                    Clusters
                </div>
                <Popover open={openClusterFilter}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div onClick={() =>
                            setOpenClusterFilter(true)
                        } className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}>
                            {
                                clusterPills.length === 0 &&
                                <div className={"text-textmedium"}>
                                    All Clusters
                                </div>
                            }
                            {clusterPills}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenClusterFilter(false)}
                                    onEscapeKeyDown={() => setOpenClusterFilter(false)}
                                    onInteractOutside={() => setOpenClusterFilter(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No clusters found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        environments.filter(env => !filters.get("cluster")?.includes(env)).map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenClusterFilter(false);

                                                setFilters((prev: Map<string, string[]>) => {
                                                    const newFilter = new Map(prev);
                                                    if (newFilter.has("cluster")) {
                                                        newFilter.get("cluster")?.push(kv);
                                                    } else {
                                                        newFilter.set("cluster", [kv]);
                                                    }
                                                    if (newFilter.has("service")) {
                                                        newFilter.delete("service")
                                                    }
                                                    return newFilter;
                                                })
                                            }}>
                                                {kv}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
            <div className={"flex flex-wrap border bg-backgroundmedium rounded"}>
                <div className={"text-textmedium border-r bg-backgrounddark p-2"}>
                    Services
                </div>
                <Popover open={openServiceFilter}>
                    <PopoverTrigger asChild className={"flex grow"}>
                        <div onClick={() =>
                            setOpenServiceFilter(true)
                        } className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}>
                            {
                                servicePills.length === 0 &&
                                <div className={"text-textmedium"}>
                                    All Services
                                </div>
                            }
                            {servicePills}
                        </div>
                    </PopoverTrigger>
                    <PopoverContent side={"bottom"} avoidCollisions={true}
                                    onFocusOutside={() => setOpenServiceFilter(false)}
                                    onEscapeKeyDown={() => setOpenServiceFilter(false)}
                                    onInteractOutside={() => setOpenServiceFilter(false)}
                                    className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                    >
                        <Command>
                            <CommandInput id={"free_text_input2"}
                                          className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                            <CommandList className={"text-textlight"}>
                                <CommandEmpty>No services found.</CommandEmpty>
                                <CommandGroup>
                                    {
                                        services.filter(service => !filters.get("service")?.includes(service.displayName)).map((kv, index) => {
                                            return <CommandItem
                                                className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                key={index} onSelect={() => {
                                                setOpenServiceFilter(false);

                                                setFilters((prev: Map<string, string[]>) => {
                                                    const newFilter = new Map(prev);
                                                    if (newFilter.has("service")) {
                                                        newFilter.get("service")?.push(kv.serviceName);
                                                    } else {
                                                        newFilter.set("service", [kv.serviceName]);
                                                    }
                                                    return newFilter;
                                                })
                                            }}>
                                                {kv.displayName}
                                            </CommandItem>
                                        })
                                    }
                                </CommandGroup>
                            </CommandList>
                        </Command>
                    </PopoverContent>
                </Popover>
            </div>
        </div>
    </div>
}

interface PagerDutyService {
    id: string
    name: string
}

function AlertDestinationsSelector(props: {
    stepNumber: number
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
}) {
    const [slackIntegrationExists, setSlackIntegrationExists] = React.useState(true)
    const [slackShareToken, setSlackShareToken] = React.useState("")
    const [slackChannels, setSlackChannels] = React.useState<string[]>([])
    const [pagerDutyServices, setPagerDutyServices] = React.useState<PagerDutyService[]>([])

    useEffect(() => {
        axios.get("/api/v1/slack/channels").then((response) => {
            setSlackChannels(response.data.channels)
        }).catch((e) => {
            console.error(e)
        })
    }, [slackIntegrationExists])

    useEffect(() => {
        axios.get("/api/v1/integrationExists?type=slack").then((response) => {
            setSlackIntegrationExists(response.data.exists)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    useEffect(() => {
        if (slackIntegrationExists) return
        axios.get("/api/v1/slack/shareToken").then((response) => {
            setSlackShareToken(response.data.token)
        }).catch((e) => {
            console.error(e)
        })
    }, [slackIntegrationExists])

    useEffect(() => {
        axios.get("/api/v1/pagerDuty/selectedServices").then((response) => {
            setPagerDutyServices(response.data.services)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    let host = window.location.host

    function alertDestinationDefault(type: AlertDestinationType): AlertDestination {
        if (type === AlertDestinationType.Slack) {
            return {
                type: AlertDestinationType.Slack,
                alertDestination: {
                    channel: "none"
                }
            }
        } else {
            return {
                type: AlertDestinationType.PagerDuty,
                alertDestination: {
                    serviceName: "none",
                    serviceId: "none"
                }
            }
        }
    }

    // TODO: Use this instead once pagerDuty review is done!
    // <div className={"flex gap-2 items-center"}>
    //     {
    //         Object.values(AlertDestinationType).map((type, index) => {
    //             return <div key={index}
    //                         className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", props.alert.destination.type === type ? "border bg-secondarytransparenter border-secondary" : "")}
    //                         onClick={() => {
    //                             props.setAlert({
    //                                 ...props.alert,
    //                                 destination: alertDestinationDefault(type)
    //                             })
    //                         }}>
    //                 {capitalizeFirstLetter(type)}
    //             </div>
    //         })
    //     }
    // </div>

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                {props.stepNumber}
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Destination
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>

                <div key={0}
                     className={cn("h-[32px] flex justify-center items-center text-textmedium gap-2 p-2 rounded bg-none border hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4 bg-secondarytransparenter border-secondary")}
                     onClick={() => {
                         props.setAlert({
                             ...props.alert,
                             destination: alertDestinationDefault(AlertDestinationType.Slack)
                         })
                     }}>
                    {capitalizeFirstLetter(AlertDestinationType.Slack)}
                </div>

            </div>
        </div>
        {
            // Channel selection for slack channel: popover command
            props.alert.destination.type === AlertDestinationType.Slack &&
            <SlackDestinationPicker alert={props.alert}
                                    setAlert={props.setAlert}
                                    slackChannels={slackChannels}/>

        }
        {/*{*/}
        {/*    // Channel selection for slack channel: popover command*/}
        {/*    props.alert.destination.type === AlertDestinationType.PagerDuty &&*/}
        {/*    <PagerDutyServicePicker alert={props.alert}*/}
        {/*                            setAlert={props.setAlert}*/}
        {/*                            pagerDutyServices={pagerDutyServices}/>*/}

        {/*}*/}
        {
            props.alert.destination.type === AlertDestinationType.Slack && !slackIntegrationExists &&
            <div className={"text-textmedium"}>
                <div>
                    <a href={"https://slack.com/oauth/v2/authorize?client_id=5445777126257.5568817569717&scope=channels:read,chat:write,chat:write.public&user_scope=&redirect_uri=https://" + host + "/slack-redirect&state=" + slackShareToken}><img
                        alt="Add to Slack" height="40" width="139"
                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"/></a>
                </div>
            </div>
        }
    </div>
}

function SlackDestinationPicker(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    slackChannels: string[]
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                <div className={"flex flex-wrap border bg-backgroundmedium rounded"}>
                    <div
                        className={"text-textmedium flex flex-col justify-center border-r bg-backgrounddark p-2"}>
                        Channel
                    </div>
                    <Popover>
                        <PopoverTrigger asChild className={"flex grow"}>
                            <div className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}>
                                <div className={"text-textmedium"}>
                                    {(props.alert.destination.alertDestination as SlackAlertDestination).channel}
                                </div>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent side={"bottom"} avoidCollisions={true}
                                        className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                        >
                            <Command>
                                <CommandInput id={"free_text_input2"}
                                              className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                                <CommandList className={"text-textlight"}>
                                    <CommandEmpty>No channels found.</CommandEmpty>
                                    <CommandGroup>
                                        {
                                            props.slackChannels.map((channel, index) => {
                                                return <CommandItem
                                                    className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                    key={index} onSelect={() => {
                                                    props.setAlert({
                                                        ...props.alert,
                                                        destination: {
                                                            type: AlertDestinationType.Slack,
                                                            alertDestination: {
                                                                channel: channel
                                                            }
                                                        }
                                                    })
                                                }}>
                                                    {channel}
                                                </CommandItem>
                                            })
                                        }
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
        </div>
    </div>
}

function PagerDutyServicePicker(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    pagerDutyServices: PagerDutyService[]
}) {
    const [open, setOpen] = React.useState(false)

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 items-center"}>
                <div className={"flex flex-wrap border bg-backgroundmedium rounded"}>
                    <div
                        className={"text-textmedium flex flex-col justify-center border-r bg-backgrounddark p-2"}>
                        PagerDuty Service
                    </div>
                    <Popover open={open}>
                        <PopoverTrigger asChild className={"flex grow"}>
                            <div className={"flex flex-wrap gap-4 p-2 hover:cursor-pointer grow"}
                                 onClick={() => setOpen(true)}>
                                <div className={"text-textmedium"}>
                                    {(props.alert.destination.alertDestination as PagerDutyAlertDestination).serviceName}
                                </div>
                            </div>
                        </PopoverTrigger>
                        <PopoverContent side={"bottom"} avoidCollisions={true}
                                        className="p-0 text-textlight bg-backgroundmedium w-[50vw]"
                        >
                            <Command>
                                <CommandInput id={"free_text_input2"}
                                              placeholder={"Search for PagerDuty Service..."}
                                              className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                                <CommandList className={"text-textlight"}>
                                    <CommandEmpty>No services found.</CommandEmpty>
                                    <CommandGroup>
                                        {
                                            props.pagerDutyServices.map((service, index) => {
                                                return <CommandItem
                                                    className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                    key={index} onSelect={() => {
                                                    props.setAlert({
                                                        ...props.alert,
                                                        destination: {
                                                            type: AlertDestinationType.PagerDuty,
                                                            alertDestination: {
                                                                serviceName: service.name,
                                                                serviceId: service.id
                                                            }
                                                        }
                                                    })
                                                    setOpen(false)
                                                }}>
                                                    {service.name}
                                                </CommandItem>
                                            })
                                        }
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
        </div>
    </div>
}


interface ApiServerAlert {
    status: string
    uuid: string
    name: string
    description: string
    type: AlertType
    destination: {
        type: AlertDestinationType
        slackDestination: SlackAlertDestination
        pagerDutyDestination: PagerDutyAlertDestination
    },
    metricAlert: {
        filters: {
            metricName: string,
            filters: Map<string, string[]>,
            excludeFilters: Map<string, string[]>,
            splits: string[],
            aggregation: string,
            functions: MetricFunction[]
        },
        monitorEvaluation: {
            monitorEvaluationType: EvalType,
            monitorEvalutionPayload: {
                evaluationFunction: AggregationFunction
                window: number,
                windowUnit: WindowUnit,
            }
        },
        alarmCondition: {
            condition: ThresholdComparator,
            threshold: string
        },
    },
    traceAlert: {
        filters: {
            filters: Map<string, string[]>,
            excludeFilters: Map<string, string[]>,
            splits: string[],
            aggregation: string,
            functions: MetricFunction[]
        },
        monitorEvaluation: {
            monitorEvaluationType: EvalType,
            monitorEvalutionPayload: {
                evaluationFunction: AggregationFunction
                window: number,
                windowUnit: WindowUnit,
            }
        },
        alarmCondition: {
            condition: ThresholdComparator,
            threshold: string
        },
    },
    kubernetesAlert: {
        filters: {
            services: string[],
            clusters: string[]
        },
        monitorEvent: string,
        scope: {
            service: boolean,
            environment: boolean
        }
    },
    logAlert: {
        filters: {
            services: string[],
            clusters: string[]
        },
        monitorEvent: string,
        logAlertRegexPayload?: LogEventRegexMatchPayload
    }
}

function ConvertToApiServerAlert(alert: Alert): any {
    let destination = {}
    if (alert.destination.type === AlertDestinationType.Slack) {
        destination = {
            type: alert.destination.type,
            slackDestination: {
                channel: (alert.destination.alertDestination as SlackAlertDestination).channel,
                additionalMessage: (alert.destination.alertDestination as SlackAlertDestination).additionalMessage
            }
        }
    }
    if (alert.destination.type === AlertDestinationType.PagerDuty) {
        destination = {
            type: alert.destination.type,
            pagerDutyDestination: {
                serviceName: (alert.destination.alertDestination as PagerDutyAlertDestination).serviceName,
                serviceId: (alert.destination.alertDestination as PagerDutyAlertDestination).serviceId
            }
        }

    }

    if (alert.type === AlertType.Kubernetes) {
        return {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destination: destination,
                metricAlert: null,
                kubernetesAlert: {
                    filters: (alert.alert as KubernetesAlert).filters,
                    monitorEvent: (alert.alert as KubernetesAlert).monitorEvent.id,
                    scope: (alert.alert as KubernetesAlert).scope
                }
            }
        }
    }
    if (alert.type === AlertType.Log) {
        return {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destination: destination,
                metricAlert: null,
                logAlert: {
                    filters: (alert.alert as LogAlert).filters,
                    monitorEvent: (alert.alert as LogAlert).monitorEvent.id,
                    logAlertRegexPayload: (alert.alert as LogAlert).monitorEvent.id === LogEventRegexMatch.id ? (alert.alert as LogAlert).eventPayload : null
                }
            }
        }
    }
    if (alert.type === AlertType.Metric) {
        const toSend = {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destination: destination,
                metricAlert: {
                    filters: {
                        metricName: (alert.alert as MetricAlert).filters.metricName,
                        filters: Object.fromEntries((alert.alert as MetricAlert).filters.filters),
                        excludeFilters: Object.fromEntries((alert.alert as MetricAlert).filters.excludeFilters || {}),
                        splits: (alert.alert as MetricAlert).filters.splits,
                        aggregation: (alert.alert as MetricAlert).filters.aggregation,
                        functions: (alert.alert as MetricAlert).filters.functions
                    },
                    monitorEvaluation: (alert.alert as MetricAlert).monitorEvaluation,
                    alarmCondition: (alert.alert as MetricAlert).eventPayload
                }
            }
        }
        return toSend
    }
    if (alert.type === AlertType.Trace) {
        const aggregation = (alert.alert as TraceAlert).filters.aggregation
        const isTime = ["P50", "P90", "P95", "P99"].includes(aggregation)
        const thresholdAdjustedForTime = isTime ? (alert.alert as TraceAlert).eventPayload.threshold * nanoToMilliSeconds : (alert.alert as TraceAlert).eventPayload.threshold
        const toSend = {
            alert: {
                uuid: alert.uuid,
                name: alert.name,
                description: alert.description,
                type: alert.type,
                destination: destination,
                traceAlert: {
                    filters: {
                        metricName: "",
                        filters: Object.fromEntries((alert.alert as TraceAlert).filters.filters),
                        excludeFilters: Object.fromEntries((alert.alert as TraceAlert).filters.excludeFilters || {}),
                        splits: (alert.alert as TraceAlert).filters.splits,
                        aggregation: (alert.alert as TraceAlert).filters.aggregation,
                        functions: (alert.alert as TraceAlert).filters.functions
                    },
                    monitorEvaluation: (alert.alert as TraceAlert).monitorEvaluation,
                    alarmCondition: {
                        condition: (alert.alert as TraceAlert).eventPayload.condition,
                        threshold: thresholdAdjustedForTime
                    }
                }
            }
        }
        return toSend
    }
}

function AlertNameSelector(props: {
    stepNumber: number,
    alert: Alert,
    setAlert: (value: (((prevState: Alert) => Alert) | Alert)) => void
}) {
    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                {props.stepNumber}
            </div>
            <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Name alert
            </div>
        </div>
        <div className={"flex justify-start text-textmedium"}>
            <div className={"flex gap-2 grow"}>
                <Input
                    value={props.alert.name}
                    onChange={(e) => {
                        props.setAlert((prev) => {
                            return {...prev, name: e.target.value}
                        })
                    }}
                    className={"h-[32px] flex justify-center border-border items-center text-textmedium border gap-2 p-2 rounded bg-none hover:text-textlight hover:border-primary cursor-pointer px-4"}
                    placeholder={"Alert Name"}
                />
            </div>
        </div>
        <div className={"flex justify-start"}>
            <div className={"flex gap-2 grow"}>
                <Textarea
                    value={props.alert.description}
                    onChange={(e) => {
                        props.setAlert((prev) => {
                            return {...prev, description: e.target.value}
                        })
                    }}
                    className={"h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-border hover:text-textlight hover:border cursor-pointer px-4"}
                    placeholder={"Alert Description"}
                />
            </div>
        </div>
    </div>
}

export enum ThresholdComparator {
    GreaterThan = "GreaterThan",
    GreaterThanOrEqual = "GreaterThanOrEqual",
    LessThanOrEqual = "LessThanOrEqual",
    LessThan = "LessThan",
}

const FormSchema = z.object({
    thresholdValue: z.coerce.number()
})

function AlarmConditionSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    threshold: number,
    setThreshold: Dispatch<SetStateAction<number>>,
    thresholdComparator: ThresholdComparator,
    setThresholdComparator: Dispatch<SetStateAction<ThresholdComparator>>
}) {
    const [thresholdComparator, setThresholdComparator] = React.useState(props.thresholdComparator)
    const [thresholdValue, setThresholdValue] = React.useState(props.threshold)
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            thresholdValue: thresholdValue,
        },
    });
    useEffect(() => {
        props.setAlert((prev) => {
            let metricAlert = prev.alert as MetricAlert | TraceAlert
            metricAlert.eventPayload = {condition: thresholdComparator, threshold: thresholdValue}
            return {...prev, alert: metricAlert}
        })
    }, [thresholdComparator, thresholdValue]);

    function onSubmit(data: z.infer<typeof FormSchema>) {
        setThresholdValue(data.thresholdValue)
        props.setThreshold(data.thresholdValue) // this is for the chart
    }

    function getPlaceholderString() {
        switch (thresholdComparator) {
            case ThresholdComparator.GreaterThan:
                return "Greater than..."
            case ThresholdComparator.GreaterThanOrEqual:
                return "Greater than or equal to..."
            case ThresholdComparator.LessThanOrEqual:
                return "Lower than or equal to..."
            case ThresholdComparator.LessThan:
                return "Lower than..."
        }
    }

    return <div className={"flex flex-col gap-4"}>
        <div className={"flex items-baseline"}>
            <div className={"text-textdark font-semibold text-nowrap w-[230px]"}> • Select Threshold Comparator:
            </div>
            <div className={"flex gap-2 justify-start items-start text-center grow shrink"}>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.GreaterThan ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.GreaterThan)
                        props.setThresholdComparator(ThresholdComparator.GreaterThan) // for chart
                    }}>
                    Greater &gt;
                </Button>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.GreaterThanOrEqual ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.GreaterThanOrEqual)
                        props.setThresholdComparator(ThresholdComparator.GreaterThanOrEqual) // for chart
                    }}>
                    Greater / Equal &gt;=
                </Button>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.LessThanOrEqual ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.LessThanOrEqual)
                        props.setThresholdComparator(ThresholdComparator.LessThanOrEqual) // for chart
                    }}>
                    Lower / Equal &lt;=
                </Button>
                <Button
                    className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", thresholdComparator === ThresholdComparator.LessThan ? " bg-secondarytransparenter border-secondary" : "bg-backgroundmedium")}
                    onClick={() => {
                        setThresholdComparator(ThresholdComparator.LessThan)
                        props.setThresholdComparator(ThresholdComparator.LessThan) // for chart
                    }}>
                    Lower &lt;
                </Button>
            </div>
        </div>
        <div className={"flex items-baseline"}>
            <div className={"text-textdark font-semibold w-[230px]"}> • Define Threshold Value:</div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} onBlur={form.handleSubmit(onSubmit)}
                      className="w-2/3 space-y-6">
                    <FormField
                        control={form.control}
                        name="thresholdValue"
                        render={({field}) => (
                            <FormItem>
                                <FormControl>
                                    <Input type={"number"}
                                           className={"bg-backgroundmedium border-border text-textmedium border rounded w-[514px]"}
                                           placeholder={`${getPlaceholderString()}`} {...field} />
                                </FormControl>
                                <FormDescription className={"text-textdark"}>
                                    Must be a number
                                </FormDescription>
                                <FormMessage className={"text-red-500"}/>
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </div>
    </div>
}

function MetricAndTraceEventSelector(props: {
    stepNumber: number
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>
    threshold: number,
    setThreshold: Dispatch<SetStateAction<number>>,
    thresholdComparator: ThresholdComparator,
    setThresholdComparator: Dispatch<SetStateAction<ThresholdComparator>>
}) {
    const [openGroupBy, setOpenGroupBy] = React.useState(false);
    const [metricAggregateEvaluation, setMetricAggregateEvaluation] = React.useState<Metric>();
    const debouncedUpdateMetricAggregateEvaluation = useDebouncedCallback(updateMetricAggregateEvaluation, 10);
    const startEnd = useSelector(timerange.selectors.getTimeRange).getStartEnd()
    const typedAlert = props.alert.alert as MetricAlert | TraceAlert
    let groupByPills: ReactElement[] = [];


    const evaluateDropdown: string[] = []
    if (typedAlert.filters.splits !== undefined) {
        for (const split of typedAlert.filters.splits) {
            evaluateDropdown.push(split)
        }
    }

    if (typedAlert.monitorEvaluation.monitorEvalutionPayload !== undefined && typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationSplits !== undefined && typedAlert.monitorEvaluation.monitorEvalutionPayload.evaluationSplits !== null) {
        typedAlert.monitorEvaluation.monitorEvalutionPayload!.evaluationSplits.forEach((split) => {
            groupByPills.push(<GroupByPill key={split} attributeKey={split}
                                           groupBy={typedAlert.filters.splits}
                                           setGroupBy={(groupBy) => {
                                               props.setAlert((prev) => {
                                                   const typedalert = prev.alert as MetricAlert | TraceAlert
                                                   if (typedalert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
                                                       typedalert.monitorEvaluation.monitorEvalutionPayload!.evaluationSplits = groupBy
                                                   }
                                                   return {...prev, alert: typedalert}
                                               })
                                           }}/>)
        })
    }

    useEffect(() => {
        if (typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate) {
            const endTime = Math.floor(startEnd[1].getTime() / 1000)
            const payload = typedAlert.monitorEvaluation.monitorEvalutionPayload
            let startTime = Math.floor(startEnd[0].getTime() / 1000)
            if (payload !== undefined) {
                startTime = endTime - payload.window * getWindowUnitSeconds(payload.windowUnit)
            }
            debouncedUpdateMetricAggregateEvaluation(props.alert, startTime, endTime, setMetricAggregateEvaluation);
        }
    }, [props.alert]);

    function getWindowUnitSeconds(windowUnit: WindowUnit) {
        switch (windowUnit) {
            case WindowUnit.Minutes:
                return 60
            case WindowUnit.Hours:
                return 3600
            case WindowUnit.Days:
                return 86400
        }
    }

    async function updateMetricAggregateEvaluation(alert: Alert,
                                                   startTime: number,
                                                   endTime: number,
                                                   setMetric: (value: (((prevState: (Metric | undefined)) => (Metric | undefined)) | Metric | undefined)) => void) {

        const typedAlert = alert.alert as MetricAlert | TraceAlert
        let filters = typedAlert.filters.filters;
        if (filters === undefined) {
            filters = new Map<string, string[]>();
        }
        let excludeFilters = typedAlert.filters.excludeFilters;
        if (excludeFilters === undefined) {
            excludeFilters = new Map<string, string[]>();
        }

        let splits = typedAlert.filters.splits;
        if (splits === undefined) {
            splits = [];
        }
        try {
            let metricName = ""
            if (alert.type === AlertType.Metric) {
                metricName = (alert.alert as MetricAlert).filters.metricName
            }
            const request = {
                metricName: metricName,
                startTime: startTime,
                endTime: endTime,
                filters: filters,
                excludeFilters: excludeFilters,
                splits: splits,
                aggregation: typedAlert.filters.aggregation,
                isRate: false,
                functions: typedAlert.filters.functions,
                aggregateParams: typedAlert.monitorEvaluation.monitorEvalutionPayload!,
            };
            const awaitedMetrics = await GetAggregateMetricEvaluation(request);
            setMetric(awaitedMetrics.metric);
        } catch (e) {
            console.error(e);
        }
    }


    function formatWindowUnit() {
        const typedAlert = props.alert.alert as MetricAlert | TraceAlert
        if (typedAlert.monitorEvaluation.monitorEvalutionPayload === undefined) {
            return WindowUnit.Minutes
        } else {
            if (typedAlert.monitorEvaluation.monitorEvalutionPayload!.windowUnit === undefined) {
                return WindowUnit.Minutes
            }
            return typedAlert.monitorEvaluation.monitorEvalutionPayload!.windowUnit
        }
    }


    return <div className={"flex items-start gap-x-32"}>
        <div className={"flex flex-col justify-between"}>
            <div className={"flex justify-start gap-2"}>
                <div
                    className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                    {props.stepNumber}
                </div>
                <div className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                    Select Alarm Condition
                </div>
            </div>
            <div className={"pl-2 flex flex-col gap-4"}>
                <div className={"flex text-textdark font-semibold items-baseline"}>
                    <div className={"w-[230px]"}>
                        • Select Threshold Type:
                    </div>
                    <div className={"flex gap-2 items-center"}>
                        {
                            monitorEvents.map((event, index) => {
                                return <div key={index}
                                            className={cn("h-[32px] flex justify-center items-center text-textmedium border gap-2 p-2 rounded bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer px-4", (props.alert.alert as MetricAlert | TraceAlert).monitorEvaluation.monitorEvaluationType === event.monitorEvaluationType || monitorEvents.length === 1 ? "border bg-secondarytransparenter border-secondary" : "")}
                                            onClick={() => {
                                                let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                                typedalert.monitorEvaluation = event
                                                props.setAlert({...props.alert, alert: typedalert})
                                            }}>
                                    <Tooltip delayDuration={10}>
                                        <TooltipTrigger>
                                            {capitalizeFirstLetter(event.monitorEvaluationType)}
                                        </TooltipTrigger>
                                        <TooltipContent side={"right"}
                                                        className={"ml-4 bg-backgroundlight border text-textlight rounded"}>
                                            {event.description}
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                            })
                        }
                    </div>
                </div>
                {typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate &&
                    <div className={"flex text-textdark font-semibold items-baseline"}>
                        <div className={"w-[230px]"}>
                            • Select Evaluation Function:
                        </div>
                        <div className={"flex border rounded"}>
                            <div
                                className={"flex flex-col justify-center bg-backgrounddark items-center text-center text-textmedium border-r"}>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <div
                                            className={"h-[32px] flex justify-center items-center text-textmedium gap-2 pl-3 pr-2 bg-none"}>
                                            <div>{typedAlert.monitorEvaluation.monitorEvalutionPayload ? typedAlert.monitorEvaluation.monitorEvalutionPayload!.evaluationFunction : AggregationFunction.Sum}</div>
                                            <ChevronDownIcon className={"text-textmedium"}/>
                                        </div>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent side={"bottom"}
                                                         className="w-56 bg-background text-textmedium rounded">
                                        <DropdownMenuRadioGroup value={"aggregateFunctions"}
                                                                onValueChange={(value) => {
                                                                    let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                                                    typedalert.monitorEvaluation.monitorEvalutionPayload!.evaluationFunction = value
                                                                    props.setAlert({...props.alert, alert: typedalert})
                                                                }
                                                                }>
                                            {Object.entries(AggregationFunction).map(([key, value], index) => {
                                                return <DropdownMenuRadioItem className="hover:bg-backgroundlight"
                                                                              key={key}
                                                                              value={value}>{value}</DropdownMenuRadioItem>
                                            })}
                                        </DropdownMenuRadioGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </div>
                            {evaluateDropdown.length > 0 &&
                                <div className={"flex grow ml-2 bg-backgroundmedium"}>
                                    <Popover open={openGroupBy} modal={true}>
                                        <PopoverTrigger asChild className={"flex grow"}>
                                            <div
                                                className={"flex p-2 flex-wrap gap-4 bg-backgroundmedium hover:cursor-pointer"}
                                                onClick={() => setOpenGroupBy(!openGroupBy)}
                                                contentEditable={true} title={"of..."}
                                            >
                                                {
                                                    groupByPills
                                                }
                                            </div>
                                        </PopoverTrigger>
                                        <PopoverContent side={"bottom"} avoidCollisions={true}
                                                        onFocusOutside={() => setOpenGroupBy(false)}
                                                        onEscapeKeyDown={() => setOpenGroupBy(false)}
                                                        onInteractOutside={() => setOpenGroupBy(false)}
                                                        className="p-0 text-textlight bg-backgroundmedium rounded max-w-max"
                                        >
                                            <Command>
                                                <CommandInput id={"free_text_input2"} placeholder={"Group by..."}
                                                              className={cn("h-12 grow text-textlight ring-0 border-0 shadow-none focus-visible:border-0 focus-visible:ring-0 bg-backgroundmedium")}/>
                                                <CommandList className={"text-textlight"}>
                                                    <CommandEmpty>No attributes are selected for this metric. Please use
                                                        Group by functionality while selecting a metric
                                                        above.</CommandEmpty>
                                                    <CommandGroup>
                                                        {
                                                            evaluateDropdown.map((kv, index) => {
                                                                return <CommandItem
                                                                    className={"w-full ariahover:cursor-pointer aria-selected:bg-secondarytransparenter aria-selected:border aria-selected:border-secondary aria-selected:text-textlight hover:bg-primarytransparent hover:text-textlight"}
                                                                    key={index} onSelect={() => {
                                                                    setOpenGroupBy(false);
                                                                    props.setAlert((prev) => {
                                                                        const typedalert = prev.alert as MetricAlert | TraceAlert
                                                                        const newEvaluationSplits = typedalert.monitorEvaluation.monitorEvalutionPayload?.evaluationSplits ? [...typedalert.monitorEvaluation.monitorEvalutionPayload?.evaluationSplits] : [];
                                                                        if (!newEvaluationSplits.includes(kv)) {
                                                                            newEvaluationSplits.push(kv);
                                                                        }
                                                                        typedalert.monitorEvaluation.monitorEvalutionPayload!.evaluationSplits = newEvaluationSplits
                                                                        return {...prev, alert: typedalert}
                                                                    })
                                                                }
                                                                }>
                                                                    {kv}
                                                                </CommandItem>
                                                            })
                                                        }
                                                    </CommandGroup>
                                                </CommandList>
                                            </Command>
                                        </PopoverContent>
                                    </Popover>
                                </div>}
                        </div>
                        <div className={"mx-2"}>
                            over
                        </div>
                        <div className={"flex border rounded"}>
                            <div className={"flex items-baseline "}>
                                <Tooltip delayDuration={30}>
                                    <TooltipTrigger>
                                        <Input type={"number"} min={0}
                                               value={typedAlert.monitorEvaluation.monitorEvalutionPayload ? typedAlert.monitorEvaluation.monitorEvalutionPayload!.window : 1}
                                               onChange={(e) => {
                                                   let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                                   typedalert.monitorEvaluation.monitorEvalutionPayload!.window = parseInt(e.target.value)
                                                   props.setAlert({
                                                       ...props.alert,
                                                       alert: typedalert
                                                   })
                                               }}
                                               className={"h-[48px] w-[52px] bg-backgroundmedium text-textmedium"}
                                        />
                                    </TooltipTrigger>
                                    <TooltipContent side={"top"}
                                                    className={"bg-backgroundmedium border rounded px-2 py-1 font-normal text-textdark"}>Must
                                        be an integer</TooltipContent>
                                </Tooltip>
                            </div>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div
                                        className={"h-[48px] flex justify-center items-center text-textmedium border-l bg-backgroundmedium gap-2 pl-3 pr-2 bg-none border-buttonborder hover:text-textlight hover:border hover:border-secondary cursor-pointer"}>
                                        <div>{formatWindowUnit()}</div>
                                        <ChevronDownIcon className={"text-textmedium"}/>
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent side={"bottom"}
                                                     className="w-56 bg-background text-textmedium rounded">
                                    <DropdownMenuRadioGroup value={"aggregateFunctions"}
                                                            onValueChange={(value) => {
                                                                let typedalert = props.alert.alert as MetricAlert | TraceAlert
                                                                typedalert.monitorEvaluation.monitorEvalutionPayload!.windowUnit = value as WindowUnit
                                                                props.setAlert({
                                                                    ...props.alert,
                                                                    alert: typedalert
                                                                })
                                                            }}>
                                        {Object.entries(WindowUnit).map(([key, value], index) => {
                                            return <DropdownMenuRadioItem
                                                className="hover:bg-backgroundlight"
                                                key={key}
                                                value={value}>{value}</DropdownMenuRadioItem>
                                        })}
                                    </DropdownMenuRadioGroup>
                                </DropdownMenuContent>
                            </DropdownMenu>

                        </div>
                    </div>
                }
                <AlarmConditionSelector alert={props.alert}
                                        setAlert={props.setAlert}
                                        threshold={props.threshold}
                                        setThreshold={props.setThreshold}
                                        thresholdComparator={props.thresholdComparator}
                                        setThresholdComparator={props.setThresholdComparator}
                />
            </div>
        </div>
        {typedAlert.monitorEvaluation.monitorEvaluationType === EvalType.Aggregate &&
            metricAggregateEvaluation !== undefined &&
            <AggregateEvaluationTable metric={metricAggregateEvaluation}/>}
    </div>;
}

interface AggragateDataPoint {
    keys: Map<string, string>
    value: number
}

function AggregateEvaluationTable(props: {
    metric: Metric
}) {
    if (props.metric.timeSeries === undefined || props.metric.timeSeries.length === 0) {
        return
    }
    // @ts-ignore
    const headers: string[] = props.metric.timeSeries[0].attributes ? Array.from(new Map(Object.entries(props.metric.timeSeries[0].attributes)).keys()) : []
    // @ts-ignore
    const values: AggragateDataPoint[] = props.metric.timeSeries.map((timeSeries) => {
        return {
            keys: new Map(Object.entries(timeSeries.attributes)),
            value: timeSeries.data[0].value
        }
    })
    return <div className={"mt-[36px] min-w-0 bg-backgroundmedium border rounded min-h-0 flex flex-col max-h-[261px] overflow-y-auto max-w-[700px]"}>
        <div className={"flex justify-end"}>
            <div
                className={"w-full flex-none h-[32px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink"}>
                <div
                    className={"h-full w-[128px] flex-none justify-between text-textmedium font-semibold"}> Evaluation Value
                </div>
                {headers.map((header, index) => {
                    return <div
                        className={"w-[250px] h-full flex justify-start text-textmedium font-semibold"}> {header}</div>
                })}
            </div>
        </div>
        <div
            className={"h-full max-w-full min-w-0 min-h-0 px-4 overflow-y-auto scrollMedium border-t rounded-b flex flex-col grow shrink"}>
            {values.map((value, index) => {
                return <div
                    className={"mt-2 w-full h-[32px] justify-start items-center gap-4 flex overflow-x-clip"}>
                    <div
                        className={"h-full w-[128px] flex-none justify-start text-center text-textmedium"}> {value.value != null ? value.value.toFixed(2) : "no value"}
                    </div>
                    {headers.map((header, index) => {
                        return <div
                            className={"h-full w-[250px] flex text-center justify-start text-textmedium truncate"}> {value.keys.get(header)}</div>
                    })}
                </div>
            })}
        </div>
    </div>
}

function MetricSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    threshold: number,
    thresholdComparator: ThresholdComparator,
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps>(initialChartProps());

    function initialChartProps(): MetoroMetricsChartProps {
        return {
            startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
            endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
            metricName: (props.alert.alert as MetricAlert).filters.metricName != "" ? (props.alert.alert as MetricAlert).filters.metricName : "container_net_tcp_active_connections",
            excludeFilters: (props.alert.alert as MetricAlert).filters.excludeFilters,
            filters: (props.alert.alert as MetricAlert).filters.filters,
            splits: (props.alert.alert as MetricAlert).filters.splits,
            aggregation: (props.alert.alert as MetricAlert).filters.aggregation != "" ? (props.alert.alert as MetricAlert).filters.aggregation : "avg",
            type: ChartType.Line,
            metricType: MetricType.Metric,
            functions: (props.alert.alert as MetricAlert).filters.functions,
        }
    }

    useEffect(() => {
        setChartProps((prev) => {
            return {
                ...prev,
                startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
            }
        })
    }, [timeRange]);

    useEffect(() => {
        setChartProps((prev) => {
            return {
                ...prev,
                threshold: {
                    value: String(props.threshold),
                    comparator: props.thresholdComparator
                }
            }
        })
    }, [props.threshold, props.thresholdComparator]);

    useEffect(() => {
        props.setAlert((prev) => {
            let metricAlert = prev.alert as MetricAlert
            let metricFilter = {...metricAlert.filters}
            metricFilter.metricName = chartProps.metricName
            metricFilter.aggregation = chartProps.aggregation
            metricFilter.filters = chartProps.filters ? chartProps.filters : new Map()
            metricFilter.excludeFilters = chartProps.excludeFilters ? chartProps.excludeFilters : new Map()
            metricFilter.splits = chartProps.splits ? chartProps.splits : []
            metricFilter.functions = chartProps.functions ? chartProps.functions : []
            metricAlert.filters = metricFilter
            return {...prev, alert: metricAlert}
        })
    }, [chartProps]);


    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                2
            </div>
            <div
                className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Metric
            </div>
        </div>
        <EmbedMetricSelector chartProps={chartProps}
                             setChartProps={setChartProps}/>
    </div>
}

function TraceSelector(props: {
    alert: Alert,
    setAlert: Dispatch<SetStateAction<Alert>>,
    threshold: number,
    thresholdComparator: ThresholdComparator,
}) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps>(initialChartProps());

    function initialChartProps(): MetoroMetricsChartProps {
        return {
            startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
            endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
            metricName: "container_net_tcp_active_connections",
            excludeFilters: (props.alert.alert as TraceAlert).filters.excludeFilters,
            filters: (props.alert.alert as TraceAlert).filters.filters,
            splits: (props.alert.alert as TraceAlert).filters.splits,
            aggregation: (props.alert.alert as TraceAlert).filters.aggregation != "" ? (props.alert.alert as TraceAlert).filters.aggregation : "count",
            type: ChartType.Line,
            metricType: MetricType.Trace,
            functions: (props.alert.alert as TraceAlert).filters.functions,
        }
    }

    useEffect(() => {
        setChartProps((prev) => {
            return {
                ...prev,
                startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
                endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)
            }
        })
    }, [timeRange]);

    useEffect(() => {
        setChartProps((prev) => {
            return {
                ...prev,
                threshold: {
                    value: String(props.threshold),
                    comparator: props.thresholdComparator
                }
            }
        })
    }, [props.threshold, props.thresholdComparator]);

    useEffect(() => {
        props.setAlert((prev) => {
            let traceAlert = prev.alert as TraceAlert
            let traceFilter = {...traceAlert.filters}
            traceFilter.aggregation = chartProps.aggregation
            traceFilter.filters = chartProps.filters ? chartProps.filters : new Map()
            traceFilter.excludeFilters = chartProps.excludeFilters ? chartProps.excludeFilters : new Map()
            traceFilter.splits = chartProps.splits ? chartProps.splits : []
            traceFilter.functions = chartProps.functions ? chartProps.functions : []
            traceAlert.filters = traceFilter
            return {...prev, alert: traceAlert}
        })
    }, [chartProps]);


    return <div className={"flex flex-col gap-4"}>
        <div className={"flex justify-start gap-2"}>
            <div
                className={"h-[36px] w-[36px] border border-primary bg-primarytransparent text-textlight flex flex-col justify-center text-center font-semibold rounded"}>
                2
            </div>
            <div
                className={"text-lg flex flex-col justify-center text-center text-textlight"}>
                Select Trace Attributes
            </div>
        </div>
        <EmbedMetricSelector chartProps={chartProps}
                             setChartProps={setChartProps}/>
    </div>
}

function AlertInner() {
    const navigate = usePreserveQueryParamsNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alert, setAlert] = React.useState<Alert>({
        uuid: "",
        type: AlertType.Kubernetes,
        name: "",
        description: "",
        alert: {
            filters: {
                services: [],
                clusters: []
            },
            scope: {
                service: false,
                environment: false
            },
            monitorEvent: kubernetesEvents[0]
        },
        destination: {
            type: AlertDestinationType.Slack,
            alertDestination: {
                channel: "help"
            }
        }
    })

    useEffect(() => {
        // Load the alert from the url id if one is set
        let alertId = searchParams.get("alertId")
        if (alertId === null) {
            return
        }
        axios.get("/api/v1/alert?alertId=" + alertId).then((response) => {
            let alert = response.data
            let alertInner = {} as KubernetesAlert | LogAlert | MetricAlert | TraceAlert
            if (alert.kubernetesAlert !== null) {
                alertInner = {
                    filters: alert.kubernetesAlert.filters,
                    scope: alert.kubernetesAlert.scope,
                    monitorEvent: kubernetesEvents.find((event) => event.id === alert.kubernetesAlert.monitorEvent) || kubernetesEvents[0]
                } as KubernetesAlert
            }
            if (alert.logAlert !== null) {
                alertInner = {
                    filters: alert.logAlert.filters,
                    monitorEvent: logEvents.find((event) => event.id === alert.logAlert.monitorEvent) || logEvents[0],
                    eventPayload: alert.logAlert.logAlertRegexPayload
                } as LogAlert
            }

            if (alert.metricAlert !== null) {
                let filterMap = new Map<string, string[]>()
                plainToClassFromExist(filterMap, alert.metricAlert.filters.filters)
                let excludeFilterMap = new Map<string, string[]>()
                plainToClassFromExist(excludeFilterMap, alert.metricAlert.filters.excludeFilters || {})
                alertInner = {
                    filters: {
                        filters: filterMap,
                        metricName: alert.metricAlert.filters.metricName,
                        excludeFilters: excludeFilterMap,
                        splits: alert.metricAlert.filters.splits,
                        aggregation: alert.metricAlert.filters.aggregation,
                        functions: alert.metricAlert.filters.functions
                    },
                    monitorEvaluation: alert.metricAlert.monitorEvaluation,
                    eventPayload: alert.metricAlert.alarmCondition
                } as MetricAlert
            }
            if (alert.traceAlert !== null) {
                let filterMap = new Map<string, string[]>()
                plainToClassFromExist(filterMap, alert.traceAlert.filters.filters)
                let excludeFilterMap = new Map<string, string[]>()
                plainToClassFromExist(excludeFilterMap, alert.traceAlert.filters.excludeFilters || {})
                alertInner = {
                    filters: {
                        filters: filterMap,
                        excludeFilters: excludeFilterMap,
                        splits: alert.traceAlert.filters.splits,
                        aggregation: alert.traceAlert.filters.aggregation,
                        functions: alert.traceAlert.filters.functions
                    },
                    monitorEvaluation: alert.traceAlert.monitorEvaluation,
                    eventPayload: {
                        condition: alert.traceAlert.alarmCondition.condition,
                        threshold: Number(adjustThreshold(alert))
                    }
                } as TraceAlert
            }

            let alertDestination = {} as SlackAlertDestination | PagerDutyAlertDestination
            if (alert.destination.type === AlertDestinationType.Slack) {
                alertDestination = {
                    channel: alert.destination.slackDestination.channel
                }
            } else {
                alertDestination = {
                    serviceName: alert.destination.pagerDutyDestination.serviceName,
                    serviceId: alert.destination.pagerDutyDestination.serviceId
                }
            }

            setAlert({
                uuid: alert.uuid,
                type: alert.type,
                name: alert.name,
                description: alert.description,
                alert: alertInner,
                destination: {
                    type: alert.destination.type,
                    alertDestination: alertDestination
                }
            })
        }).catch((e) => {
            console.error(e)
        })
    }, [searchParams])

    if (searchParams.get("alertId") !== null && alert.uuid === "") {
        return <div className={"p-4 flex grow shrink"}>
            <div
                className={"flex flex-col grow shrink border bg-backgroundmedium p-4 gap-8 overflow-y-auto"}>
                <div
                    className={"flex justify-center text-textdark"}>
                    Loading...
                </div>
            </div>
        </div>
    }

    return <div className={"p-4 flex grow shrink"}>
        <div
            className={"flex flex-col grow shrink border bg-backgroundmedium p-4 gap-8 overflow-y-auto"}>
            <div className={"flex justify-between"}>
                <AlertTypeSelector alert={alert}
                                   setAlert={setAlert}/>
                <Button
                    className={"bg-primarytransparent border border-primary rounded p-4 text-textmedium hover:border-primaryhover"}
                    onClick={() => {
                        navigate(`/alerts`)
                    }}>Cancel</Button>
            </div>
            {
                alert.type === AlertType.Kubernetes &&
                <div className={"flex flex-col gap-8"}>
                    <AlertEventSelector alert={alert}
                                        setAlert={setAlert}/>
                    <AlertFilterSelector alert={alert}
                                         setAlert={setAlert}/>
                </div>
            }
            {
                alert.type === AlertType.Log &&
                <div className={"flex flex-col gap-8"}>
                    <LogEventSelector alert={alert}
                                      setAlert={setAlert}/>
                    <AlertFilterSelector alert={alert}
                                         setAlert={setAlert}/>
                </div>
            }
            {
                alert.type === AlertType.Metric &&
                <div className={"flex flex-col gap-8"}>
                    <MetricSelector alert={alert}
                                    setAlert={setAlert}
                                    threshold={(alert.alert as MetricAlert).eventPayload.threshold}
                                    thresholdComparator={(alert.alert as MetricAlert).eventPayload.condition as ThresholdComparator}/>
                    <MetricAndTraceEventSelector stepNumber={3}
                                                 alert={alert}
                                                 setAlert={setAlert}
                                                 threshold={(alert.alert as MetricAlert).eventPayload.threshold}
                                                 setThreshold={(value) => {
                                                     setAlert((prev) => {
                                                         return {
                                                             ...prev,
                                                             alert: {
                                                                 ...prev.alert as MetricAlert,
                                                                 eventPayload: {
                                                                     ...(prev.alert as MetricAlert).eventPayload,
                                                                     threshold: Number(value.toString())
                                                                 }
                                                             }
                                                         }
                                                     })
                                                 }}
                                                 thresholdComparator={(alert.alert as MetricAlert).eventPayload.condition as ThresholdComparator}
                                                 setThresholdComparator={(value) => {
                                                     setAlert((prev) => {
                                                         return {
                                                             ...prev,
                                                             alert: {
                                                                 ...prev.alert as MetricAlert,
                                                                 eventPayload: {
                                                                     ...(prev.alert as MetricAlert).eventPayload,
                                                                     condition: value.toString()
                                                                 }
                                                             }
                                                         }
                                                     })
                                                 }}
                    />
                </div>
            }
            {
                alert.type === AlertType.Trace &&
                <div className={"flex flex-col gap-8"}>
                    <TraceSelector alert={alert}
                                   setAlert={setAlert}
                                   threshold={(alert.alert as TraceAlert).eventPayload.threshold}
                                   thresholdComparator={(alert.alert as TraceAlert).eventPayload.condition as ThresholdComparator}/>
                    <MetricAndTraceEventSelector stepNumber={3}
                                                 alert={alert}
                                                 setAlert={setAlert}
                                                 threshold={(alert.alert as TraceAlert).eventPayload.threshold}
                                                 setThreshold={(value) => {
                                                     setAlert((prev) => {
                                                         return {
                                                             ...prev,
                                                             alert: {
                                                                 ...prev.alert as TraceAlert,
                                                                 eventPayload: {
                                                                     ...(prev.alert as TraceAlert).eventPayload,
                                                                     threshold: Number(value.toString())
                                                                 }
                                                             }
                                                         }
                                                     })
                                                 }}
                                                 thresholdComparator={(alert.alert as TraceAlert).eventPayload.condition as ThresholdComparator}
                                                 setThresholdComparator={(value) => {
                                                     setAlert((prev) => {
                                                         return {
                                                             ...prev,
                                                             alert: {
                                                                 ...prev.alert as TraceAlert,
                                                                 eventPayload: {
                                                                     ...(prev.alert as TraceAlert).eventPayload,
                                                                     condition: value.toString()
                                                                 }
                                                             }
                                                         }
                                                     })
                                                 }}
                    />
                </div>
            }
            <AlertDestinationsSelector alert={alert}
                                       setAlert={setAlert}
                                       stepNumber={4}/>
            <AlertNameSelector alert={alert} setAlert={setAlert}
                               stepNumber={5}/>
            <div className={"flex justify-center"}>
                <div
                    onClick={() => {
                        axios.post("/api/v1/alert", ConvertToApiServerAlert(alert)).then((response) => {
                            // Navigate to the alert page
                            let id = response.data.id;
                            navigate("/alert?alertId=" + id)
                        }).catch((e) => {
                            console.error(e)
                        })
                    }}
                    className={"bg-primarytransparent flex grow text-center items-center justify-center border-primary border text-textlight p-2 rounded hover:border-primaryhover hover:text-textlight cursor-pointer"}>
                    Save
                </div>
            </div>
        </div>
    </div>
}

function AlertCreation() {
    return <BaseView title={"Create an Alert"}
                     disableClusterSelector={true}>
        <AlertInner/>
    </BaseView>
}

export {
    AlertCreation, AlertInner
}

export type {
    ApiServerAlert,
    PagerDutyService
}