import {BaseView} from "./BaseView";
import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ChartType, GetAllTraceMetrics, GetAllTraceMetricsResponse, MetoroChart, MetricToChartData} from "./MetricsTest";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {TimeRange} from "../types/time";
import Traces from "./Traces";
import Logs from "./Logs";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/tabs";
import {KubernetesView} from "../components/K8sInfo/KubernetesView";
import {usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import axios from "../utility/customAxios";
import {ServiceSummaries} from "./ServiceCatalogue";

function TraceAndChartEmbeds(props: { serviceName: string }) {
    const navigate = usePreserveQueryParamsNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        setSearchParams(prev => {
            let existing = new URLSearchParams(window.location.search)
            let filter = new Map<string, string[]>();
            filter.set("server.service.name", [props.serviceName]);
            existing.set("filter", JSON.stringify(Object.fromEntries(filter)))
            return existing
        })
    }, [searchParams]);


    return <div className={"columns-2 flex max-h-full min-h-0 gap-4 min-w-0 max-w-full"}>
        <Traces service={props.serviceName} removeService={true} justTraceFeed={true}/>
        <Logs removeService={true} justLogsFeed={true} expandOnClick={
            () => {
                let existing = new URLSearchParams(window.location.search)
                const currentServiceName = existing.get("service")
                const filterBy = {"service.name": [currentServiceName]}
                navigate(`/logs?filter=${JSON.stringify(filterBy)}`)
            }
        }/>
    </div>
}

function MainPanel(props: { metrics?: GetAllTraceMetricsResponse, serviceName: string }) {
    return <div className={"grow shrink min-h-0 min-w-0"}>
        <div className={"flex h-1/2"}>
            <REDMetrics metrics={props.metrics} serviceName={props.serviceName}/>
        </div>
        <div className={"h-1/2"}>
            <TraceAndChartEmbeds serviceName={props.serviceName}/>
        </div>
    </div>
}

function REDMetrics(props: { metrics?: GetAllTraceMetricsResponse, serviceName: string }) {
    let numRequests = props.metrics && props.metrics.metrics.find((metric) => metric.name === "NumRequests");
    let twoHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "TwoHundreds");
    let threeHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "ThreeHundreds");
    let fourHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "FourHundreds");
    let fiveHundreds = props.metrics && props.metrics.metrics.find((metric) => metric.name === "FiveHundreds");
    let p50 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P50");
    let p90 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P90");
    let p95 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P95");
    let p99 = props.metrics && props.metrics.metrics.find((metric) => metric.name === "P99");

    return <div className={"grow shrink gap-4 min-h-0 min-w-0 grid grid-rows-1 grid-cols-2"}>
        <div
            className={"flex flex-col bg-backgroundmedium justify-between space-y-4 p-4 border rounded grow shrink min-w-0 min-h-0"}>
            <div className={"text-xl font-semibold text-textmedium h-8"}>
                Request Volume
            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <div className={"flex grow shrink min-w-0 min-h-0"}>
                    <MetoroChart
                        title={"Total Number of Requests"}
                        type={ChartType.Bar}
                        dataToUse={MetricToChartData(numRequests, false)}
                        hideLegend={true}/>
                </div>
                <div className={"flex grow shrink min-w-0 min-h-0"}>
                    <MetoroChart
                        title={"Number of 2XXs"}
                        type={ChartType.Bar}
                        dataToUse={MetricToChartData(twoHundreds, false)}
                        hideLegend={true}/>
                </div>
            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <MetoroChart
                    title={"Number of 4XXs"}
                    type={ChartType.Bar}
                    dataToUse={MetricToChartData(fourHundreds, false, "rgba(255, 206, 86, 0.2)", "rgb(255, 206, 86)")}
                    hideLegend={true}/>
                <MetoroChart
                    title={"Number of 5XXs"}
                    type={ChartType.Bar}
                    dataToUse={MetricToChartData(fiveHundreds, false, "rgba(255, 99, 132, 0.2)", "rgb(255, 99, 132)")}
                    hideLegend={true}/>
            </div>
        </div>
        <div
            className={"flex flex-col bg-backgroundmedium justify-between space-y-4 p-4 border rounded grow shrink min-w-0"}>
            <div className={"text-xl font-semibold text-textmedium h-8"}>
                Latency
            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <MetoroChart
                    isDuration={true}
                    title={"P50 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p50, true)}
                    hideLegend={true}/>
                <MetoroChart
                    isDuration={true}
                    title={"P90 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p90, true)}
                    hideLegend={true}/>

            </div>
            <div className={"grid grid-cols-2 grid-rows-1 grow shrink gap-4 min-w-0 min-h-0"}>
                <MetoroChart
                    isDuration={true}
                    title={"P95 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p95, true)}
                    hideLegend={true}/>
                <MetoroChart
                    isDuration={true}
                    title={"P99 Latency"}
                    type={ChartType.Line}
                    dataToUse={MetricToChartData(p99, true)}
                    hideLegend={true}/>
            </div>
        </div>
    </div>
}


function DeploymentTimeline() {
    return <div
        className={"grow max-w-[200px] shrink h-full min-h-0 border rounded bg-backgroundmedium flex flex-col justify-center p-4"}>
        <div className={"text-xl w-full text-center flex justify-center font-semibold text-textdark h-8"}>
            Deployment Timeline coming soon...
        </div>
    </div>;
}

function Service() {
    const [searchParams, setSearchParams] = useSearchParams();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    let serviceName = searchParams.get("service") || undefined;
    const [redMetrics, setRedMetrics] = React.useState<GetAllTraceMetricsResponse>();
    const [currentTab, setCurrentTab] = React.useState<string>(searchParams.get("tab") || "apm");
    const [environments, setEnvironments] = useState<string[]>([]);
    const [serviceSummariesData, setServiceSummariesData] = useState<ServiceSummaries>();

    useEffect(() => {
        let environments = searchParams.get("environment") || "";
        if (environments !== "") {
            setEnvironments([environments])
        }
    }, [searchParams])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const startEnd = timeRange.getStartEnd()
                const d = axios.post("/api/v1/serviceSummaries", {
                        "startTime": Math.floor(startEnd[0].getTime() / 1000),
                        "endTime": Math.floor(startEnd[1].getTime() / 1000),
                        "environments": environments
                    }
                );
                setServiceSummariesData((await d).data);
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [timeRange, environments]);

    useEffect(() => {
        let tab = searchParams.get("tab");
        if (currentTab !== tab) {
            let search = new URLSearchParams(searchParams);
            search.set("tab", currentTab);
            setSearchParams(search);
        }
    }, [currentTab]);

    async function fetchMetrics(serviceName: string | undefined, timeRange: TimeRange) {
        let startEnd = timeRange.getStartEnd();
        await GetAllTraceMetrics({
            serviceNames: serviceName ? [serviceName] : [],
            startTime: Math.floor(startEnd[0].getTime() / 1000),
            endTime: Math.floor(startEnd[1].getTime() / 1000),
            environments: environments,
        }).then((response) => {
            setRedMetrics(response)
        })
    }

    useEffect(() => {
        fetchMetrics(serviceName, timeRange)
    }, [serviceName, timeRange, environments]);

    if (!serviceName) {
        return <BaseView title={"Service"}>
            <div className={"flex justify-center items-center h-full w-full"}>
                <div className={"text-2xl font-semibold text-textmedium"}>
                    No service selected
                </div>
            </div>
        </BaseView>
    }

    let displayServiceName = serviceName
    if (displayServiceName.includes("/")) {
        let strings = displayServiceName.split("/");
        displayServiceName = strings[strings.length - 1]
    }

    displayServiceName = displayServiceName.charAt(0).toUpperCase() + displayServiceName.slice(1)

    function findService(serviceName: string, serviceSummaries: ServiceSummaries) {
        return serviceSummaries.serviceSummaries.find((service) => service.serviceName === serviceName)
    }

    if (serviceSummariesData != undefined && serviceSummariesData.serviceSummaries != undefined) {
        if (serviceSummariesData.serviceSummaries.length > 0 && findService(serviceName, serviceSummariesData)) {
            return <BaseView title={`${displayServiceName} Dashboard`}>
                <div className={"m-4 min-h-0 flex min-w-0 bg-backgrounddark flex-col grow shrink text-textmedium"}>
                    <Tabs value={currentTab} onValueChange={(val) => setCurrentTab(val)} defaultValue="apm"
                          className="flex flex-col min-h-0 min-w-0">
                        <div className={"flex flex-none justify-center min-h-0 min-w-0 mb-4"}>
                            <TabsList className="grid grid-cols-2 grid-rows-1 border">
                                <TabsTrigger value="apm" className={"grow shrink text-lg"}>APM</TabsTrigger>
                                <TabsTrigger value="k8s" className={"grow shrink text-lg"}>Kubernetes</TabsTrigger>
                            </TabsList>
                        </div>
                        <TabsContent value="apm" className={"flex grow shrink flex-col min-h-0 min-w-0"}>
                            <MainPanel metrics={redMetrics} serviceName={serviceName}/>
                        </TabsContent>
                        <TabsContent value="k8s" className={"flex grow shrink min-h-0 min-w-0"}>
                            <div className={"flex gap-4 min-w-0 min-h-0 grow shrink"}>
                                <KubernetesView serviceName={serviceName}/>
                            </div>
                        </TabsContent>
                    </Tabs>
                </div>
            </BaseView>
        } else {
            return <BaseView title={`${displayServiceName} Dashboard`}>
                <div className={"m-4 min-h-0 flex min-w-0 bg-backgrounddark flex-col grow shrink text-textmedium"}>
                    <div className={"flex justify-center items-center h-full w-full"}>
                        <div className={"text-xl font-semibold text-textdark"}>
                            Service not found in the selected cluster. Please select a different cluster.
                        </div>
                    </div>
                </div>
            </BaseView>
        }
    }
}

export {
    Service
}