import React from "react";
import {AiOutlineKubernetes} from "react-icons/ai";
import {K8sPodDetails} from "./model";
import yaml from "js-yaml";
import {V1StatefulSet, V1Deployment, V1ReplicaSet, V1DaemonSet, V1Pod} from "@kubernetes/client-node";
import hdate from "human-date";
import DeploymentYamlDialog from "./DeploymentYamlDialog";
import {Button} from "../ui/button";
import {environments} from "eslint-plugin-prettier";
import {FrownIcon} from "lucide-react";
import {LoadingSpinner} from "../ui/customSpinner";

function SummaryTable(props: {
    resourceYamls: Map<string, string>
    kind: string
    podDetails: K8sPodDetails[] | undefined
}) {

    if (props.resourceYamls === undefined || props.resourceYamls.size === 0) {
        return <div className={"bg-backgroundmedium rounded border border-border flex grow"}>
            <div className={"flex grow text-textmedium justify-center"}>
                <div className={"flex text-center items-center flex-col grow justify-center text-lg"}>
                    <LoadingSpinner className={`z-40"}`}/>
                </div>
            </div>
        </div>
    }

    let validResourceYaml  = Array.from(props.resourceYamls).filter(([env, resourceYaml]) => {
        return resourceYaml !== undefined && resourceYaml !== ""
    })


    if (validResourceYaml === undefined || validResourceYaml.length === 0) {
        return <div className={"bg-backgroundmedium rounded border border-border flex grow"}>
            <div className={"flex grow text-textmedium justify-center"}>
                <div className="flex flex-row justify-center my-8 space-x-2 items-center">
                    <div className={"text-textdark text-center"}>No yaml found</div>
                    <FrownIcon className={"text-textdark"}/>
                </div>
            </div>
        </div>
    }

    const isAggregate = validResourceYaml.length > 1


    let creationTimestamp: Date | undefined
    let expectedReplicas: number = 0
    let runningReplicas: number = 0
    // Cannot be a Pod as we are not showing Pod details in this component
    if (isAggregate) {
        validResourceYaml.forEach(([env, yamlString]) => {
            let parsedYaml = yaml.load(yamlString)
            let k8sObj;
            if (props.kind === "Deployment" || props.kind === "StatefulSet") {
                k8sObj = parsedYaml as V1Deployment;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.availableReplicas ? k8sObj?.status?.availableReplicas : 0)
            } else if (props.kind === "StatefulSet") {
                k8sObj = parsedYaml as V1StatefulSet;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0)
            } else if (props.kind === "ReplicaSet") {
                k8sObj = parsedYaml as V1ReplicaSet;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0)
            } else if (props.kind === "DaemonSet") {
                k8sObj = parsedYaml as V1DaemonSet;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.status?.desiredNumberScheduled ? k8sObj?.status?.desiredNumberScheduled : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.currentNumberScheduled ? k8sObj?.status?.currentNumberScheduled : 0)
            }
        })
    } else {
        let parsedYaml =  yaml.load(validResourceYaml[0][1])
        let k8sObj;
        if (props.kind === "Deployment" || props.kind === "StatefulSet") {
            k8sObj = parsedYaml as V1Deployment;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0
            runningReplicas = k8sObj?.status?.availableReplicas ? k8sObj?.status?.availableReplicas : 0
        } else if (props.kind === "StatefulSet") {
            k8sObj = parsedYaml as V1StatefulSet;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0
            runningReplicas = k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0
        } else if (props.kind === "ReplicaSet") {
            k8sObj = parsedYaml as V1ReplicaSet;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0
            runningReplicas = k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0
        } else if (props.kind === "DaemonSet") {
            k8sObj = parsedYaml as V1DaemonSet;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.status?.desiredNumberScheduled ? k8sObj?.status?.desiredNumberScheduled : 0
            runningReplicas = k8sObj?.status?.currentNumberScheduled ? k8sObj?.status?.currentNumberScheduled : 0
        }
    }

        function getRunningPodsString(runningPods: number | undefined, totalPods: number | undefined) {
            let runningString = ""
            if (runningPods !== undefined) {
                runningString = `${runningPods}`
            }
            if (totalPods !== undefined) {
                runningString += `/${totalPods}`
            }
            if (runningString !== "") {
                runningString += " running"
            } else {
                return ""
            }
            return runningString
        }

        let runningString = getRunningPodsString(runningReplicas, expectedReplicas);

        let restarts = 0
        let restartSet = false;
        if (props.podDetails && props.podDetails.length > 0) {
            props.podDetails.forEach((pod) => {
                let parsedYaml = yaml.load(pod.yaml)
                let k8sPod = parsedYaml as V1Pod
                if (k8sPod.status && k8sPod.status.containerStatuses) {
                    k8sPod.status.containerStatuses.forEach((containerStatus) => {
                        if (!restartSet) {
                            restartSet = true
                        }
                        restarts += containerStatus.restartCount
                    })
                }
            })
        }

        return (
            <div className={"bg-backgroundmedium rounded border border-border"}>
                <div className={"flex grow flex-col gap-8 m-4"}>
                    <div className={"flex justify-between"}>
                        <div className={"flex flex-row justify-between grow"}>
                            <div className={"text-lg font-semibold text-textmedium"}>
                                Summary
                            </div>
                            <DeploymentYamlDialog
                                kind={props.kind}
                                yamls={new Map<string, string>(validResourceYaml)}
                                dialogTrigger={
                                    <Button asChild
                                            className={"text-textmedium rounded bg-primarytransparent border-primary border hover:bg-primary hover:cursor-pointer"}>
                                        <div className="flex items-center space-x-2">
                                            <div className="text-textmedium hover:text-textlight">View Yaml</div>
                                        </div>
                                    </Button>
                                }
                            />
                        </div>
                    </div>
                    <div className={"grid grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-2 gap-y-4"}>
                        <div className={"flex flex-col gap-4"}>
                            <div className={"flex gap-16"}>
                                <div className={"text-textmedium font-bold w-[100px]"}>Pods</div>
                                <div className={"text-textmedium"}>{runningString}</div>
                            </div>
                            {restartSet && <div className={"flex gap-16"}>
                                <div className={"text-textmedium font-bold w-[100px]"}>Restarts</div>
                                <div className={"text-textmedium"}>{restarts}</div>
                            </div>
                            }
                        </div>
                        <div className={"flex flex-col gap-4"}>
                            <div className={"flex gap-16"}>
                                <div className={"text-textmedium font-bold w-[100px]"}>Type</div>
                                <DeploymentYamlDialog
                                    kind={props.kind}
                                    yamls={new Map<string, string>(validResourceYaml)}
                                    dialogTrigger={<div className={"text-textmedium"}>{
                                        <div
                                            className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white hover:cursor-pointer">
                                            <AiOutlineKubernetes className={"text-white h-4 w-4"}/>{props.kind}
                                        </div>
                                    }</div>}
                                />
                            </div>
                            {!isAggregate && <div className={"flex gap-16"}>
                                <div className={"text-textmedium font-bold w-[100px] truncate"}>Created at</div>
                                <div
                                    className={"text-textmedium"}>{hdate.prettyPrint(creationTimestamp, {showTime: true})}</div>
                            </div>}
                            {isAggregate && <div className={"flex gap-16"}>
                                <div className={"text-textmedium font-bold w-[100px] truncate"}>Deployed in</div>
                                <div
                                    className={"text-textmedium"}>All Clusters
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    export default SummaryTable
