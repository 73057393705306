import React, {ReactElement} from "react";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogTitle,
    DialogTrigger
} from "../ui/dialog";
import PrettyYamlViewer from "./YamlViewer";
import {ChevronDown, XIcon} from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem,
    DropdownMenuTrigger
} from "../ui/dropdown-menu";

function DeploymentYamlDialog(props: {
    yamls: Map<string, string>
    kind: string
    dialogTrigger: ReactElement
}) {
    const [currentEnvironment, setCurrentEnvironment] = React.useState<string>(props.yamls.keys().next().value)
    const [currentYaml, setCurrentYaml] = React.useState<string>(props.yamls.values().next().value)

    return (
        <Dialog>
            <DialogTrigger asChild>
                {props.dialogTrigger}
            </DialogTrigger>
            <DialogContent className={"w-1/2 h-5/6"}>
                <div className={"flex flex-row justify-between items-center grow"}>
                    <div className={"flex justify-end items-center gap-x-2"}>
                        <DialogTitle className={"text-textmedium"}>{props.kind} Yaml</DialogTitle>
                        { props.yamls.size > 1 &&
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div className={"flex items-center text-textmedium font-semibold border rounded"}>
                                        <div className={"px-2 py-2"}>Cluster</div>
                                        <div className={"flex justify-center items-center border-l bg-backgroundlight px-2 py-2"}>
                                            <div
                                                className={"text-textmedium pr-1"}>{currentEnvironment}
                                            </div>
                                            <ChevronDown className={"text-textmedium h-4 w-4"}/>
                                        </div>
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent className={"w-56 text-textmedium bg-background rounded"}>
                                    {Array.from(props.yamls).map(([env, yamls]) => {
                                        return (
                                            <DropdownMenuItem className="hover:bg-backgroundlight" onClick={() => {
                                                setCurrentEnvironment(env)
                                                setCurrentYaml(yamls)
                                            }}>
                                                {env}
                                            </DropdownMenuItem>
                                        )
                                    })}
                                </DropdownMenuContent>
                            </DropdownMenu>
                        }

                    </div>
                    <div className={"flex flex-col justify-center"}>
                        <DialogClose asChild>
                            <XIcon className={"text-textdark hover:cursor-pointer hover:text-primary"}/>
                        </DialogClose>
                    </div>
                </div>
                <PrettyYamlViewer yamlString={currentYaml}/>
            </DialogContent>
        </Dialog>
    )
}

export default DeploymentYamlDialog