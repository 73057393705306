import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import {useNavigate, useSearchParams} from "react-router-dom";
import type {NavigateOptions} from "react-router/dist/lib/context";
import {useEffect, useState} from "react";
import { debounce } from 'lodash';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export const usePreserveQueryParamsNavigate = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    // Get the current search string for the time range data
    let last = searchParams.get("last")

    return (path: string, opts?: NavigateOptions) => {
        let split = path.split("?")
        let basePath = split[0]
        let searchParams = null
        if (split.length > 1) {
            searchParams = new URLSearchParams(split[1])
        } else {
            searchParams = new URLSearchParams()
        }

        if (last) {
            searchParams.set("last", last)
        }

        let finalPath = basePath + "?" + searchParams.toString()

        navigate(finalPath, opts)
    };
};

export const useDebounce = <T>(inputValue: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, delay]);

    return debouncedValue;
};
